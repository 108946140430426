export const Billing = Object.freeze({
  ACCOUNT_SUSPENDED_PART1: `Su cuenta está suspendida. Debe`,
  ACCOUNT_SUSPENDED_PART2: `pagar su factura pendiente`,
  ACCOUNT_SUSPENDED_PART3: `para reactivar el servicio.`,
  ADD_SEATS: `Agregar asientos`,
  ADD_SEATS_DESCRIPTION_PART_1: `Agregar`,
  ADD_SEATS_DESCRIPTION_PART_2: `asiento(s) añadirá`,
  ADD_SEATS_DESCRIPTION_PART_3: `a su`,
  ADD_SEATS_DESCRIPTION_PART_4: `factura, comenzando`,
  ADD_SEATS_PRORATED_PART_1: `Por los días restantes`,
  ADD_SEATS_PRORATED_PART_2: `hasta su próximo pago, se cobrará a su tarjeta de crédito una cantidad prorrateada de`,
  ADD_SEATS_PRORATED_PART_3: `hoy.`,
  AMOUNT: `Monto`,
  ANNUAL_TITLE: `Anual`,
  ANNUALLY_TITLE: `anualmente`,
  BILL_IS_LATE_PART_1: `La factura está actualmente`,
  BILL_IS_LATE_PART_2_MULTIPLE: `días de retraso.`,
  BILL_IS_LATE_PART_2_SINGLE: `día de retraso.`,
  BILLING_ADDRESS: `Dirección de facturación`,
  BILLING_DETAILS_DESCRIPTION: `Administre su método de pago y dirección de facturación`,
  BILLING_DETAILS_TITLE: `Detalles de facturación`,
  BUY_LICENSES_MESSAGE: `Su factura será`,
  BY_INVOICE: `Por factura`,
  CANCEL_SUBSCRIPTION: `Cancelar suscripción`,
  CANCEL_SUBSCRIPTION_MESSAGE: `¿Está seguro de que desea cancelar la suscripción?`,
  CANCELLATION_TEXT: `Su suscripción está programada para cancelarse el`,
  CANCELLATION_DESCRIPTION: `¿Estás seguro de que deseas cancelar el cambio programado?`,
  CHANGE: `Cambiar`,
  CHANGE_SUBSCRIPTION: `Cambiar suscripción`,
  CARD: `Tarjeta de Crédito`,
  CHANGE_TERM: `Cambiar plazo`,
  CHECKOUT: `Pagar`,
  DAYS: `Días`,
  DATE_ISSUE: `Fecha de emisión`,
  DOWNLOAD: `Descargar`,
  EMAIL: `Correo electrónico`,
  EXPIRE_MESSAGE_PART_1: `La factura está`,
  EXPIRE_MESSAGE_PART_2: `días de retraso. Su organización estará inoperable en`,
  EXPIRE_MESSAGE_PART_3: `días.`,
  INVOICE: `Factura`,
  INVOICE_DESCRIPTION: `Ver y descargar`,
  INVOICE_TITLE: `Historial de facturas`,
  LICENSE_MESSAGE_PART_1: `Ya tiene`,
  LICENSE_MESSAGE_PART_2: `licencias asignadas a usuarios. Por favor, ingrese más`,
  LICENSE_MESSAGE_PART_3: `o desactive/elimine usuarios para liberar licencias`,
  LICENSES_PLACEHOLDER: `Ingrese un número`,
  LICENSES_TITLE: `licencias`,
  LOADING_LABEL_1: `Conectando…`,
  LOADING_LABEL_2: `Agregando licencias`,
  MONTH_TITLE: `mes`,
  MONTHLY_TITLE: `Mensual`,
  NEXT_INVOICE_DUE: `Próxima factura vence`,
  NOTIFICATION_DESCRIPTION: `Recibir notificaciones por correo electrónico para nuevas facturas`,
  NOTIFICATION_TITLE: `Notificaciones`,
  NUMBER_OF_LICENSES: `# Licencias`,
  ORDER_SUMMARY: `Resumen de pedido`,
  PAY_NOW_TITLE: `Pagar ahora`,
  PAYMENT_METHOD: `Método de pago`,
  PER_USER_MONTH: `por usuario / mes`,
  PLAN_SILVER: `Plata`,
  PLAN_TRIAL: `Prueba`,
  RECEIVE_NOTIFICATIONS: `Recibir notificaciones por correo electrónico para nuevas facturas`,
  REMOVE_SEATS: `Eliminar asientos`,
  REMOVE_SEATS_DESCRIPTION_PART_1: `Eliminar`,
  REMOVE_SEATS_DESCRIPTION_PART_2: `asiento(s) reducirá su`,
  REMOVE_SEATS_DESCRIPTION_PART_3: `factura por`,
  REMOVE_SEATS_DESCRIPTION_PART_4: `comenzando`,
  REMOVE_SEATS_NOTE_PART1: `Para eliminar más de`,
  REMOVE_SEATS_NOTE_PART2: `asiento(s), puede desactivar o eliminar usuarios para liberar licencias.`,
  REACTIVATE: `Reactivar`,
  SCHEDULED_CHANGES: `Cambios programados para`,
  SCHEDULED_COLUMN_CHANGE_BY: `Cambio por`,
  SCHEDULED_COLUMN_DATE: `Fecha de solicitud`,
  SCHEDULED_COLUMN_LABEL: `Cambio de orden`,
  SCHEDULED_SEATS_CHANGE_PART_1: `Reducir`,
  SCHEDULED_SEATS_CHANGE_PART_2: `licencias`,
  SCHEDULED_TERM_CHANGE: `Plazo cambiado a`,
  SEATS: `Asientos`,
  STATUS: `Estado`,
  STATUS_DUE: `Pendiente`,
  STATUS_PAID: `Pagado`,
  SUBSCRIPTION: `Suscripción`,
  TERM: `Plazo`,
  TERM_ANNUAL: `Anual`,
  TERM_MONTHLY: `Mensual`,
  TITLE: `Facturación`,
  TRIAL_LICENSE_EXPIRED: `Su licencia SUMO1 ha caducado. Para continuar usando SUMO1 sin interrupciones, por favor compre una nueva licencia.`,
  UNPAID_INVOICE_MESSAGE: `Tiene una factura impaga.`,
  USERS: `Usuarios`,
  USERS_OF: `de`,
  YOUR_PLAN: `Su plan`,
  TAX: `Impuesto`,
  HAVE_UNPAID_INVOICE: `Tiene una factura impaga.`,
  VIEW_BILLING: `Ver facturación`,
  PAY_INVOICE: `Pagar factura`,
  PAY_BY_INVOICE_MIN_VALUE_ORDER_TOOLTIP: `Para activar el pago por factura, su pedido debe ser de al menos`,
});

export const BillingToastsNotifications = Object.freeze({
  ADD_SEATS_ERROR_TOAST: `No se pudieron agregar los asientos`,
  ADD_SEATS_SUCCESS_TOAST: `Espere hasta 1 minuto antes de que recibamos su pago`,
  ADD_SEATS_SUCCESS_TOAST_TITLE: `Asientos añadidos.`,
  BUY_LICENSE_SUCCESS_TOAST: `Suscripción creada correctamente`,
  CHANGE_TERM_ERROR_TOAST: `No se pudo cambiar el término.`,
  CHANGE_TERM_SUCCESS_TOAST: `Término cambiado con éxito.`,
  CHARGEBEE_PORTAL_CLOSE_TOAST: `Espere hasta 1 minuto antes de que lo recibamos si ha realizado algún cambio, luego vuelva a abrir la página de facturación`,
  DELETE_CHANGE_ERROR_TOAST: `No se pudo eliminar el cambio programado.`,
  DELETE_CHANGE_SUCCESS_TOAST: `El cambio programado se eliminó correctamente.`,
  GET_BILLING_ERROR_TOAST: `Error de Conexión con ChargeBee`,
  GET_ITEM_TIERS_ERROR_TOAST: `No se pudieron obtener los precios de los artículos de las licencias`,
  PAY_LATER_ERROR_TOAST: `La programación del pago ha fallado.`,
  REMOVE_SEATS_ERROR_TOAST: `No se pudieron eliminar los asientos`,
  REMOVE_SEATS_SUCCESS_TOAST: `Cambios programados`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_1: `Reducir`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_2: `asientos`,
  CALCULATE_TAXES_ERROR_TOAST: `Error durante el cálculo del impuesto`,
  REACTIVATE_SUBSCRIPTION_SUCCESS_TOAST: `Suscripción reactivada correctamente`,
  REACTIVATE_SUBSCRIPTION_ERROR_TOAST: `Error al activar la suscripción. Por favor actualice su método de pago.`,
  PAYMENT_ERROR_TOAST: `Hubo un problema con la tarjeta durante el pago, por favor verifique la tarjeta.`,
  DELETE_PAYMENT_SOURCE_SUCCESS_TOAST: `La tarjeta se ha eliminado con éxito.`,
  DELETE_PAYMENT_SOURCE_ERROR_TOAST: `Error al eliminar la tarjeta.`,
  REPEAT_PAYMENT_SUCCESS_TOAST: `La factura se ha pagado con éxito.`,
  REPEAT_PAY_NO_PAYMENT_SOURCES_ERROR_TOAST: `No tiene tarjetas disponibles para el pago.`,
  REPEAT_PAY_INVOICE_NOT_COLLECTED_ERROR_TOAST: `No se pudo pagar una o más facturas.`,
  REPEAT_PAYMENT_ERROR_TOAST: `Ocurrió un error durante el intento de pago.`,
  UPDATE_PAYMENT_METHOD_SUCCESS_TOAST: `El método de pago se ha cambiado con éxito.`,
  UPDATE_PAYMENT_METHOD_ERROR_TOAST: `Error al actualizar el método de pago.`,
});
