import { ScheduledChangesInput } from '../../API';
import {BillingCalculateTaxesRequest} from "../../generated-sources/internal-api/models/BillingCalculateTaxesRequest";
import {
  BillingChangeSeatsOrTermRequest
} from "../../generated-sources/internal-api/models/BillingChangeSeatsOrTermRequest";

export type ChargeeBeeResponse = {
  subscription?: Subscription;
  customer?: Customer;
  contacts?: Contact[];
  paymentSources?: PaymentSource[];
  invoices?: SubscriptionInvoice[];
};

export type DownloadResponse = {
  download_url: string;
};

export type Subscription = {
  id: string;
  billing_period: number;
  billing_period_unit: string;
  status: string;
  current_term_start: number;
  current_term_end: number;
  next_billing_at: number;
  created_at: number;
  updated_at: number;
  has_scheduled_changes: boolean;
  currency_code: string;
  subscription_items: SubscriptionItem[];
  scheduledChanges?: SubscriptionItem[];
  sumoScheduledChanges?: ScheduledChangesInput[];
  cancelled_at?: number;
  due_invoices_count: number;
};

export type SubscriptionItem = {
  item_price_id: string;
  item_type: string;
  quantity: number;
  unit_price: number;
  amount: number;
};

export type InvoiceLineItem = {
  id: string;
  amount?: number;
  description?: string;
  date_from?: number;
  date_to?: number;
  tax_rate?: number;
  tax_amount?: number;
};

export type SubscriptionInvoice = {
  id: string;
  amount_due?: number;
  amount_paid?: number;
  total?: number;
  currency_code?: string;
  date?: number;
  due_date?: number;
  status?: string;
  created_at?: number;
  updated_at?: number;
  line_items?: InvoiceLineItem[];
};

export type AutoCollection = 'on' | 'off';

export type Customer = {
  first_name?: string;
  last_name?: string;
  email?: string;
  billing_address?: BillingAddress;
  auto_collection?: AutoCollection;
  primary_payment_source_id?: string
};

export type BillingAddress = {
  city?: string;
  country?: string;
  first_name?: string;
  last_name?: string;
  line1?: string;
  state?: string;
  validation_status?: string;
  zip?: string;
};

export type Contact = {
  email?: string;
  enabled?: boolean;
  first_name?: string;
  id?: string;
  label?: string;
  last_name?: string;
  send_account_email?: boolean;
  send_billing_email?: boolean;
};

export type PaymentSource = {
  card?: Card;
  created_at?: number;
  customer_id?: string;
  deleted?: boolean;
  id?: string;
  issuing_country?: string;
  status?: string;
  type?: string;
  updated_at?: number;
};

export type Card = {
  brand?: string;
  expiry_month?: number;
  expiry_year?: number;
  first_name?: string;
  funding_type?: string;
  iin?: string;
  last4?: string;
  last_name?: string;
  masked_number?: string;
};

export type SubscribeCustomerInfo = {
  email: string;
  first_name?: string;
  last_name?: string;
  locale?: string;
  company?: string;
  phone?: string;
};

export type updateSubscriptionRequest = {
  addSeats?: number;
  scheduledChange?: ScheduledChangesInput;
};

export type updateCustomerRequest = {
  emails?: string[];
  sendBillingEmail?: boolean;
  billingAddress?: BillingAddress;
  // primaryPaymentSourceId?: string;
};

export type ItemPrices = {
  id: string;
  name: string;
  currency: string;
  tiers: Tier[];
};

// Snake case bacause ChargeBee use it (item_price_id / itemPriceId)
export type Tier = {
  item_price_id: string;
  starting_unit: number;
  ending_unit?: number;
  price: number;
  object: string;
};

export type BillingGetItemPricesResponse = {
  itemPrices: ItemPrices[];
};

export type CalculationTaxes = {
  totalAmount: number;
  totalTax: number;
};

export type BillingCalculateTaxesRequestPayload = Exclude<BillingCalculateTaxesRequest, 'tenantId' | 'userContext' | 'encryptedUserId'>

export enum PaymentMethod {
  CARD = 'CARD',
  INVOICE = 'INVOICE',
}

export type BillingChangeSeatsOrTermRequestPayload = {
  addSeats?: BillingChangeSeatsOrTermRequest['addSeats'];
  scheduledChange?: BillingChangeSeatsOrTermRequest['scheduledChange'];
};