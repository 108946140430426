import { all, call, put, select, takeLatest, take } from 'redux-saga/effects';
import { OrgsActionTypes, orgsActions } from './actions';
import {
  GetOrgDetailsResponse,
  GetOrgsResponse,
  OPSConsoleLicense,
  OPSConsoleTenant,
  OrgsDataTableType,
  PayLaterDetails,
  PayLaterRequest,
} from './types';
import { notificationsActions } from '../../notifications';
import { convertToPayLater, getOrgDetailById, getOrgs, updateLicense, updateTenant, sendUIEmails } from './service';
import {
  EXTEND_LICENSE_FAIL_TOAST,
  EXTEND_LICENSE_SUCCESS_TOAST,
  GET_ORGS_FAIL_TOAST,
  GET_ORG_DETAILS_FAIL_TOAST,
  SAVE_ORG_DETAILS_FAIL_TOAST,
  SAVE_ORG_DETAILS_SUCCESS_TOAST,
  EXPIRE_TRIAL_FAIL_TOAST,
  EXPIRE_TRIAL_SUCCESS_TOAST,
  CONVERT_TO_TEST_SUCCESS_TOAST,
  CONVERT_TO_TEST_FAIL_TOAST,
  DELETE_ORG_SUCCESS_TOAST,
  DELETE_ORG_FAIL_TOAST,
  CONVERT_TO_PAY_LATER_FAIL_TOAST,
  CHARGEBEE_TERM_VALUES,
  GET_ORGS_LOGS_FAIL_TOAST,
  GET_ORG_LOGS_FAIL_TOAST,
} from './constants';
import { assignRoleToUser, convertToOrgsDataTableType } from './utils';
import { orgsSelectors } from './selectors';
import { Account, ActionType, CreateAccountInput, LicenseType, OrgType } from '../../../API';
import dayjs from 'dayjs';
import { accountsActions, accountsSelectors } from '../accounts';
import { upsertAccount } from '../accounts/service';
import { navigationService } from '../../../services/NavigationService';
import { Path } from '../../../routing';
import { deleteOrgModalActions, payLaterModalActions } from './modal';
import { authenticationSelectors } from '../../authentication';
import { OPSConsoleUsersActions } from '../users';
import { handleServiceError } from '../../utils/reduxUtils';
import { getDateByNumberOfDays } from '../../../services/DateService';
import { constructExpireTrialEmail, constructExtendTrialEmail } from '../../../services/EmailService';
import { BUY_LICENSE_SUCCESS_TOAST } from '../../users/constants';
import { GetActionsRequest, GetActionsResponse } from '../../global';
import { getActions, removeTenant } from '../../global/services';
import { createSelector } from 'reselect';

const getAccountUpdateRequest = (accountRecord: Account, tenantId: string) => {
  const updatedTenantIds = accountRecord.tenantIds?.includes(tenantId)
    ? accountRecord.tenantIds?.filter((id) => id !== tenantId)
    : accountRecord.tenantIds
    ? [...accountRecord.tenantIds, tenantId]
    : [tenantId];
  return {
    id: accountRecord.id,
    tenantIds: updatedTenantIds,
  } as CreateAccountInput;
};

const selectGetOrgsLogsRequest = createSelector(
  orgsSelectors.selectOrgsChartFilter,
  (filter) =>
    ({
      startDate: dayjs().subtract(+filter, 'month').valueOf().toString(),
      endDate: dayjs().valueOf().toString(),
      actionTypes: [ActionType.SUBSCRIPTIONS_DAILY_SUMMARY],
    } as GetActionsRequest)
);

const selectOrgLogsRequest = (tenantId: string) =>
  createSelector(
    orgsSelectors.selectOrgChartFilter,
    (filter) =>
      ({
        tenantId,
        startDate: dayjs().subtract(+filter, 'month').valueOf().toString(),
        endDate: dayjs().valueOf().toString(),
        actionTypes: [
          ActionType.SUBSCRIPTIONS_DAILY_SUMMARY,
          ActionType.MEETING_CREATED,
          ActionType.MEETING_RESCHEDULED,
          ActionType.MEETING_CANCELED,
          ActionType.REMINDER_SENT,
        ],
      } as GetActionsRequest)
  );

function* getOrgsSaga() {
  try {
    const getOrgsLogsRequest: GetActionsRequest = yield select(selectGetOrgsLogsRequest);
    const [response, orgsActionsLog]: [GetOrgsResponse, GetActionsResponse] = yield all([
      call(getOrgs),
      call(getActions, getOrgsLogsRequest),
    ]);
    if ((response && !response.tenants) || response.tenants.length === 0) {
      throw new Error('Orgs not found');
    }
    const convertedOrgs: OrgsDataTableType[] = convertToOrgsDataTableType(response.tenants, orgsActionsLog.logs);
    yield put(orgsActions.getOrgsLogsSuccess(orgsActionsLog.logs));
    yield put(orgsActions.getOrgsSuccess(convertedOrgs));
  } catch (error: unknown) {
    yield put(orgsActions.getOrgsFail(error?.toString()));
    yield call(handleServiceError, error, GET_ORGS_FAIL_TOAST, true);
  }
}

function* getOrgDetailsSaga(action: ReturnType<typeof orgsActions.getOrgDetailsRequest>) {
  try {
    if (action.type === OrgsActionTypes.GET_ORG_DETAILS_REQUEST) {
      const orgLogsRequest: GetActionsRequest = yield select(selectOrgLogsRequest(action.payload));
      const [orgDetails, orgActionsLog]: [GetOrgDetailsResponse, GetActionsResponse] = yield all([
        call(getOrgDetailById, action.payload),
        call(getActions, orgLogsRequest),
      ]);
      const usersWithRoles = assignRoleToUser(orgDetails.tenantRecord, orgDetails.userRecords, orgDetails.roleRecords);
      yield put(OPSConsoleUsersActions.getUsersSuccess(usersWithRoles));
      yield put(accountsActions.getAccountsSuccess(orgDetails.accountList));
      yield put(orgsActions.getOrgLogsSuccess(orgActionsLog.logs));
      yield put(
        orgsActions.getOrgDetailsSuccess({
          tenantRecord: orgDetails.tenantRecord,
          licenseRecords: orgDetails.licenseRecords,
          roleRecords: orgDetails.roleRecords,
          accountId:
            orgDetails.accountList.find((account) => account.tenantIds?.includes(orgDetails.tenantRecord.tenantId))
              ?.id || '',
          bookedMeetings: orgDetails.bookedMeetings || 0,
          percentOfWeekAdoption: null,
          percentOfMonthAdoption: null,
        })
      );
    }
  } catch (error: unknown) {
    yield put(orgsActions.getOrgDetailsFail(error?.toString()));
    yield call(handleServiceError, error, GET_ORG_DETAILS_FAIL_TOAST, true);
  }
}

function* saveOrgDetailsSaga() {
  try {
    const isMainAdminOrOperations: boolean = yield select(authenticationSelectors.selectIsMainAdminOrOperations);
    const tenant: OPSConsoleTenant = yield select(orgsSelectors.selectTenantDetails);
    const updateTenantRequest = call(updateTenant, tenant.tenantId, {
      status: tenant.status,
      note: tenant.note,
    });

    const requests = [updateTenantRequest];

    if (isMainAdminOrOperations) {
      const license: OPSConsoleLicense = yield select(orgsSelectors.selectLicense);
      const updateLicenseRequest = call(updateLicense, license.tenantId, license.id, {
        endDate: license.endDate,
        owned: license.owned,
      });
      requests.push(updateLicenseRequest);
    }

    const prevAccountData: Account | undefined = yield select(
      accountsSelectors.selectAccountByTenantId(tenant.tenantId)
    );
    const accountId: string = yield select(orgsSelectors.selectAccountId);
    if (prevAccountData?.id !== accountId) {
      const newAccountData: Account = yield select(accountsSelectors.selectAccountByAccountId(accountId));
      if (prevAccountData) {
        const accountRemoveFrom = getAccountUpdateRequest(prevAccountData, tenant.tenantId);
        requests.push(yield upsertAccount(accountRemoveFrom));
      }

      const accountAddTo = getAccountUpdateRequest(newAccountData, tenant.tenantId);
      requests.push(yield upsertAccount(accountAddTo));
    }

    yield all(requests);

    yield put(orgsActions.saveOrgDetailsSuccess());
    yield put(notificationsActions.showToast(SAVE_ORG_DETAILS_SUCCESS_TOAST));
    yield put(orgsActions.getOrgDetailsRequest(tenant.tenantId));
  } catch (error: unknown) {
    yield put(orgsActions.saveOrgDetailsFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_ORG_DETAILS_FAIL_TOAST);
  }
}

function* deleteOrgSaga() {
  try {
    const tenantId: string = yield select(orgsSelectors.selectTenantId);

    yield call(removeTenant, tenantId);

    yield put(orgsActions.deleteOrgSuccess());
    yield put(orgsActions.getOrgsRequest());
    yield put(notificationsActions.showToast(DELETE_ORG_SUCCESS_TOAST));
    yield put(deleteOrgModalActions.closeModal());

    yield call(navigationService.navigateTo, Path.OPSConsoleOrgs);
  } catch (error: unknown) {
    yield put(orgsActions.deleteOrgFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_ORG_FAIL_TOAST);
  }
}

function* extendLicenseSaga(action: ReturnType<typeof orgsActions.extendLicenseRequest>) {
  try {
    if (action.type === OrgsActionTypes.EXTEND_LICENSE_REQUEST) {
      const { expirationDate, extendDays, isFromDetails, superAdminsEmails } = action.payload;
      const extendedDate = getDateByNumberOfDays(expirationDate, 15);
      const { subject, body } = constructExtendTrialEmail(extendedDate);

      const licenseDetails: OPSConsoleLicense = yield select(orgsSelectors.selectLicense);
      // add additional days to the start date if the Org is Active, to today if the Org is Expired
      const today = dayjs();
      const endDate = dayjs(licenseDetails.endDate);
      const addToDate = endDate.isAfter(today.format('YYYY-MM-DD'), 'day') ? endDate : today;
      const newExpirationDate = addToDate.add(extendDays, 'day').format('YYYY-MM-DD');
      yield call(updateLicense, licenseDetails.tenantId, licenseDetails.id, { endDate: newExpirationDate });

      yield put(orgsActions.extendLicenseSuccess());
      yield put(notificationsActions.showToast(EXTEND_LICENSE_SUCCESS_TOAST));
      if (isFromDetails) {
        yield put(orgsActions.getOrgDetailsRequest(licenseDetails.tenantId));
      } else {
        yield put(orgsActions.getOrgsRequest());
      }

      yield call(sendUIEmails, superAdminsEmails, subject, body);
    }
  } catch (error: unknown) {
    yield put(orgsActions.extendLicenseFail(error?.toString()));
    yield call(handleServiceError, error, EXTEND_LICENSE_FAIL_TOAST);
  }
}

function* expireTrialSaga(action: ReturnType<typeof orgsActions.expireTrialRequest>) {
  try {
    if (action.type === OrgsActionTypes.EXPIRE_TRIAL_REQUEST) {
      const { subject, body } = constructExpireTrialEmail();
      const { isFromDetails, superAdminsEmails } = action.payload;

      const tenantId: string = yield select(orgsSelectors.selectTenantId);
      const licenseId: string = yield select(orgsSelectors.selectLicenseId);

      yield call(updateLicense, tenantId, licenseId, { endDate: dayjs().format('YYYY-MM-DD') });

      yield put(orgsActions.expireTrialSuccess());
      yield put(notificationsActions.showToast(EXPIRE_TRIAL_SUCCESS_TOAST));
      if (isFromDetails) {
        yield put(orgsActions.getOrgDetailsRequest(tenantId));
      } else {
        yield put(orgsActions.getOrgsRequest());
      }
      yield call(sendUIEmails, superAdminsEmails, subject, body);
    }
  } catch (error: unknown) {
    yield put(orgsActions.expireTrialFail(error?.toString()));
    yield call(handleServiceError, error, EXPIRE_TRIAL_FAIL_TOAST);
  }
}

function* convertToTestSaga() {
  try {
    const tenantId: string = yield select(orgsSelectors.selectTenantId);
    yield call(updateTenant, tenantId, { type: OrgType.TEST });

    yield put(orgsActions.convertToTestSuccess());
    yield put(notificationsActions.showToast(CONVERT_TO_TEST_SUCCESS_TOAST));

    yield put(orgsActions.getOrgDetailsRequest(tenantId));
  } catch (error: unknown) {
    yield put(orgsActions.expireTrialFail(error?.toString()));
    yield call(handleServiceError, error, CONVERT_TO_TEST_FAIL_TOAST);
  }
}

function* convertToPayLaterSaga() {
  try {
    const tenantId: string = yield select(orgsSelectors.selectTenantId);
    const payLaterDetails: PayLaterDetails = yield select(orgsSelectors.selectPayLaterDetails);

    const input: PayLaterRequest = {
      tenantId: tenantId,
      email: payLaterDetails.email,
      term: payLaterDetails.term,
      quantity: payLaterDetails.quantity,
      billing_address: payLaterDetails.billing_address,
    };

    yield call(convertToPayLater, input);
    yield call(navigationService.navigateTo, Path.OPSConsoleOrgs);

    yield put(orgsActions.convertToPayLaterSuccess());
    yield put(orgsActions.getOrgsRequest());
    // yield put(notificationsActions.showToast(CONVERT_TO_PAY_LATER_SUCCESS_TOAST));
    yield put(notificationsActions.showToast(BUY_LICENSE_SUCCESS_TOAST));
    yield put(payLaterModalActions.closeModal());

    yield take(OrgsActionTypes.GET_ORGS_SUCCESS);

    const orgTerm =
      payLaterDetails.term == CHARGEBEE_TERM_VALUES.SILVER_MONTHLY ? LicenseType.MONTHLY : LicenseType.ANNUAL;

    const orgs: OrgsDataTableType[] = yield select(orgsSelectors.selectOrgs);
    const orgsMap: OrgsDataTableType[] = orgs.map((org) =>
      org.tenantId == tenantId
        ? {
            ...org,
            type: OrgType.CUSTOMER,
            term: orgTerm,
            owned: payLaterDetails.quantity,
          }
        : org
    );

    yield put(orgsActions.getOrgsSuccess(orgsMap));
  } catch (error: unknown) {
    yield put(orgsActions.convertToPayLaterFail(error?.toString()));
    yield call(handleServiceError, error, CONVERT_TO_PAY_LATER_FAIL_TOAST);
  }
}

function* getOrgsLogsSaga() {
  try {
    const getOrgsLogsRequest: GetActionsRequest = yield select(selectGetOrgsLogsRequest);
    const orgsActionsLog: GetActionsResponse = yield call(getActions, getOrgsLogsRequest);
    yield put(orgsActions.getOrgsLogsSuccess(orgsActionsLog.logs));
  } catch (error: unknown) {
    yield put(orgsActions.getOrgsLogsFail(error?.toString()));
    yield call(handleServiceError, error, GET_ORGS_LOGS_FAIL_TOAST);
  }
}

function* getOrgLogsSaga() {
  try {
    const tenantId: string = yield select(orgsSelectors.selectTenantId);
    const orgLogsRequest: GetActionsRequest = yield select(selectOrgLogsRequest(tenantId));
    const orgActionsLog: GetActionsResponse = yield call(getActions, orgLogsRequest);
    yield put(orgsActions.getOrgLogsSuccess(orgActionsLog.logs));
  } catch (error: unknown) {
    yield put(orgsActions.getOrgLogsFail(error?.toString()));
    yield call(handleServiceError, error, GET_ORG_LOGS_FAIL_TOAST);
  }
}

export function* watchOPSConsoleOrgsSaga() {
  yield takeLatest(OrgsActionTypes.GET_ORGS_REQUEST, getOrgsSaga);
  yield takeLatest(OrgsActionTypes.GET_ORG_DETAILS_REQUEST, getOrgDetailsSaga);
  yield takeLatest(OrgsActionTypes.SAVE_ORG_DETAILS_REQUEST, saveOrgDetailsSaga);
  yield takeLatest(OrgsActionTypes.DELETE_ORG_REQUEST, deleteOrgSaga);
  yield takeLatest(OrgsActionTypes.EXTEND_LICENSE_REQUEST, extendLicenseSaga);
  yield takeLatest(OrgsActionTypes.EXPIRE_TRIAL_REQUEST, expireTrialSaga);
  yield takeLatest(OrgsActionTypes.CONVERT_TO_TEST_REQUEST, convertToTestSaga);
  yield takeLatest(OrgsActionTypes.CONVERT_TO_PAY_LATER_REQUEST, convertToPayLaterSaga);
  yield takeLatest(OrgsActionTypes.GET_ORGS_LOGS_REQUEST, getOrgsLogsSaga);
  yield takeLatest(OrgsActionTypes.GET_ORG_LOGS_REQUEST, getOrgLogsSaga);
}
