export const Locations = Object.freeze({
  TITLE: `Locais`,
  CLONE_TITLE: `Insira o nome do local clonado`,
  ADD_LABEL: `Novo Local`,
  DEACTIVATE_TITLE: `Desativar Local`,
  DEACTIVATE_DESCRIPTION: `Tem certeza de que deseja desativar`,
  DELETE_TITLE: `Excluir Local`,
  DELETE_DESCRIPTION: `Tem certeza de que deseja excluir`,
  IMPORT_CSV: `Importar CSV`,
  IMPORT_LABEL: `Importar`,
  STATUS: `Status`,
  ACTIVE: `Ativo`,
  INACTIVE: `Inativo`,
  UPLOAD_DESCRIPTION_PART1: `Selecione o arquivo CSV no seu computador`,
  UPLOAD_DESCRIPTION_PART2: `ou transfira para esta área de upload`,
  IMPORT_DESCRIPTION_PART1: `Aqui você pode encontrar um `,
  IMPORT_DESCRIPTION_PART2: `arquivo de exemplo`,
  IMPORT_DESCRIPTION_PART3: ``,
  EMPTY_LIST_DESCRIPTION: `Crie um local ou ajuste sua busca ou filtros.`,
  SELECTED_LOCATIONS: `locais selecionados`,
  SYNC_ERROR: `Erro de sincronização`,
  SYNC_ERROR_DESC: `Erro de sincronização: endereço não encontrado no Google Maps.`,
  NO_MAPS_INTEGRATION_PART1: `Para habilitar a exibição de localização no Google Maps para a página de reserva pública, configure a conta da Plataforma Google Maps e a chave da API na seção `,
  NO_MAPS_INTEGRATION_PART2: `Integrações`,
  NO_MAPS_INTEGRATION_PART3: `.`,
  LOCATION: `Local`,
  SELECT_LOCATION: `Aplica-se a todos os locais`,
  SINGLE_LOCATION: `este "{{name}}"`,
  USER_IMPACT: `afeta a disponibilidade do usuário`,
  REMOVE_FROM: `remova-o dos seguintes`,
  BOOKING_PAGE_AND_TEMPLATE: `páginas de reserva e modelos`,
  WARNING_MESSAGE: `Aviso: Excluir {{locationLabel}} irá {{userImpact}}{{bookingImpact}} {{bookingDetails}}`,
});

export const EditLocation = Object.freeze({
  ADD_LOCATION: `Adicionar localização`,
  NAME_PLACEHOLDER: `Insira o nome do local`,
  ADDRESS: `Endereço`,
  ENTERED_ADDRESS: `Endereço inserido`,
});

export const LocationsToastsNotifications = Object.freeze({
  GET_LOCATIONS_ERROR_MESSAGE: `Falha na solicitação de locais`,
  DEACTIVATE_LOCATION_SUCCESS_MESSAGE: `Local desativado com sucesso`,
  ACTIVATE_LOCATION_SUCCESS_MESSAGE: `Local ativado com sucesso`,
  DEACTIVATE_LOCATION_ERROR_MESSAGE: `Falha na solicitação de ativação/desativação do local`,
  CLONE_LOCATION_SUCCESS_MESSAGE: `Local clonado com sucesso`,
  CLONE_LOCATION_ERROR_MESSAGE: `Falha na solicitação de clonagem do local`,
  DELETE_LOCATION_SUCCESS_MESSAGE: `Local excluído com sucesso`,
  DELETE_LOCATION_ERROR_MESSAGE: `Falha na solicitação de exclusão do local`,
  VALIDATE_LOCATIONS_ERROR_MESSAGE: `Um ou mais registros têm campos vazios. Todos os campos são obrigatórios, exceto o campo de estado, que deve ser preenchido se o país for EUA, Canadá ou Austrália.`,
  VALIDATE_LOCATIONS_INVALID_COUNTRY_LENGTH_ERROR_MESSAGE: `Formato de país incorreto. O nome do país deve ter 2 caracteres.`,
  IMPORT_LOCATIONS_SUCCESS_MESSAGE: `Locais importados com sucesso`,
  IMPORT_LOCATIONS_ERROR_MESSAGE: `Falha na solicitação de importação de locais`,
  IMPORT_LOCATIONS_LIMIT_ERROR_MESSAGE: `Falha na importação: Limite de 100 locais excedido. Por favor, divida seu arquivo.`,
  GET_LOCATION_ERROR_MESSAGE: `Falha ao obter localização`,
  SAVE_LOCATION_ERROR_MESSAGE: `Falha ao salvar localização`,
  CREATE_LOCATION_SUCCESS_MESSAGE: `Localização criada com sucesso`,
  UPDATE_LOCATION_SUCCESS_MESSAGE: `Localização atualizada com sucesso`,
});
