import { CreateAdminDataInput } from '../../API';
import { BookingTemplate } from '../../generated-sources/internal-api/models/BookingTemplate';
import { AppThunk } from '../rootStore';
import { teamsSelectors } from '../teams/selectors';
import { UserAdminDataFullRecord, UserFilterType, UserResources } from './types';

export enum UsersActionTypes {
  GET_USERS_REQUEST = 'users/GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'users/GET_USERS_SUCCESS',
  GET_USERS_FAIL = 'users/GET_USERS_FAIL',
  GET_USERS_PAGE_DATA_REQUEST = 'users/GET_USERS_PAGE_DATA_REQUEST',
  INVITE_USERS_REQUEST = 'users/INVITE_USERS_REQUEST',
  INVITE_USERS_SUCCESS = 'users/INVITE_USERS_SUCCESS',
  INVITE_USERS_FAIL = 'users/INVITE_USERS_FAIL',
  EDIT_USER_RECORD_REQUEST = 'users/EDIT_USER_RECORD_REQUEST',
  EDIT_USER_RECORD_SUCCESS = 'users/EDIT_USER_RECORD_SUCCESS',
  EDIT_USER_RECORD_FAIL = 'users/EDIT_USER_RECORD_FAIL',
  DISABLE_USER_RECORD_REQUEST = 'users/DISABLE_USER_RECORD_REQUEST',
  DISABLE_USER_RECORD_SUCCESS = 'users/DISABLE_USER_RECORD_SUCCESS',
  DISABLE_USER_RECORD_FAIL = 'users/DISABLE_USER_RECORD_FAIL',
  DELETE_USER_RECORD_REQUEST = 'users/DELETE_USER_RECORD_REQUEST',
  DELETE_USER_RECORD_SUCCESS = 'users/DELETE_USER_RECORD_SUCCESS',
  DELETE_USER_RECORD_FAIL = 'users/DELETE_USER_RECORD_FAIL',
  SET_USER_RECORD = 'users/SET_USER_RECORD',
  UPDATE_USER_RECORD = 'users/UPDATE_USER_RECORD',
  SET_ACTIVE_SUPER_ADMINS = 'users/SET_ACTIVE_SUPER_ADMINS',
  SET_FILTER = 'users/SET_FILTER',
  SELECT_USERS = 'users/SELECT_USERS',
  SET_USER_RESOURCES = 'users/SET_USER_RESOURCES',
  UPDATE_USER_RESOURCES = 'users/UPDATE_USER_RESOURCES',
}

export type UsersAction =
  | { type: UsersActionTypes.GET_USERS_REQUEST }
  | { type: UsersActionTypes.GET_USERS_SUCCESS; payload: UserAdminDataFullRecord[] }
  | { type: UsersActionTypes.GET_USERS_FAIL; error: unknown }
  | { type: UsersActionTypes.GET_USERS_PAGE_DATA_REQUEST }
  | {
      type: UsersActionTypes.INVITE_USERS_REQUEST;
      payload: { data: CreateAdminDataInput[]; expirationInviteDays: number };
    }
  | { type: UsersActionTypes.INVITE_USERS_SUCCESS }
  | { type: UsersActionTypes.INVITE_USERS_FAIL; error: unknown }
  | { type: UsersActionTypes.EDIT_USER_RECORD_REQUEST; roleChanged: boolean }
  | { type: UsersActionTypes.EDIT_USER_RECORD_SUCCESS }
  | { type: UsersActionTypes.EDIT_USER_RECORD_FAIL; error: unknown }
  | { type: UsersActionTypes.DISABLE_USER_RECORD_REQUEST }
  | { type: UsersActionTypes.DISABLE_USER_RECORD_SUCCESS }
  | { type: UsersActionTypes.DISABLE_USER_RECORD_FAIL; error: unknown }
  | { type: UsersActionTypes.DELETE_USER_RECORD_REQUEST }
  | { type: UsersActionTypes.DELETE_USER_RECORD_SUCCESS }
  | { type: UsersActionTypes.DELETE_USER_RECORD_FAIL; error: unknown }
  | { type: UsersActionTypes.SET_USER_RECORD; payload: UserAdminDataFullRecord }
  | { type: UsersActionTypes.UPDATE_USER_RECORD; payload: Partial<UserAdminDataFullRecord> }
  | { type: UsersActionTypes.SET_ACTIVE_SUPER_ADMINS; payload: UserAdminDataFullRecord[] }
  | { type: UsersActionTypes.SET_FILTER; payload: Partial<UserFilterType> }
  | { type: UsersActionTypes.SELECT_USERS; payload: string[] }
  | { type: UsersActionTypes.SET_USER_RESOURCES; payload: UserResources }
  | { type: UsersActionTypes.UPDATE_USER_RESOURCES; payload: Partial<UserResources> };

const getUsersRequest = (): UsersAction => ({ type: UsersActionTypes.GET_USERS_REQUEST });
const getUsersSuccess = (payload: UserAdminDataFullRecord[]): UsersAction => ({
  type: UsersActionTypes.GET_USERS_SUCCESS,
  payload,
});
const getUsersFail = (error: unknown): UsersAction => ({ type: UsersActionTypes.GET_USERS_FAIL, error });
const getUsersPageDataRequest = (): UsersAction => ({ type: UsersActionTypes.GET_USERS_PAGE_DATA_REQUEST });

const inviteUsersRequest = (data: CreateAdminDataInput[], expirationInviteDays: number): UsersAction => ({
  type: UsersActionTypes.INVITE_USERS_REQUEST,
  payload: { data, expirationInviteDays },
});
const inviteUsersSuccess = (): UsersAction => ({
  type: UsersActionTypes.INVITE_USERS_SUCCESS,
});
const inviteUsersFail = (error: unknown): UsersAction => ({ type: UsersActionTypes.INVITE_USERS_FAIL, error });

const editUserRecordRequest = (roleChanged: boolean): UsersAction => ({
  type: UsersActionTypes.EDIT_USER_RECORD_REQUEST,
  roleChanged,
});
const editUserRecordSuccess = (): UsersAction => ({
  type: UsersActionTypes.EDIT_USER_RECORD_SUCCESS,
});
const editUserRecordFail = (error: unknown): UsersAction => ({ type: UsersActionTypes.EDIT_USER_RECORD_FAIL, error });

const disableUserRecordRequest = (): UsersAction => ({
  type: UsersActionTypes.DISABLE_USER_RECORD_REQUEST,
});
const disableUserRecordSuccess = (): UsersAction => ({
  type: UsersActionTypes.DISABLE_USER_RECORD_SUCCESS,
});
const disableUserRecordFail = (error: unknown): UsersAction => ({
  type: UsersActionTypes.DISABLE_USER_RECORD_FAIL,
  error,
});

const deleteUserRecordRequest = (): UsersAction => ({
  type: UsersActionTypes.DELETE_USER_RECORD_REQUEST,
});
const deleteUserRecordSuccess = (): UsersAction => ({
  type: UsersActionTypes.DELETE_USER_RECORD_SUCCESS,
});
const deleteUserRecordFail = (error: unknown): UsersAction => ({
  type: UsersActionTypes.DELETE_USER_RECORD_FAIL,
  error,
});

const setUserRecord = (payload: UserAdminDataFullRecord): UsersAction => ({
  type: UsersActionTypes.SET_USER_RECORD,
  payload,
});

const updateUserRecord = (payload: Partial<UserAdminDataFullRecord>): UsersAction => ({
  type: UsersActionTypes.UPDATE_USER_RECORD,
  payload,
});

const setActiveSuperAdmins = (payload: UserAdminDataFullRecord[]): UsersAction => ({
  type: UsersActionTypes.SET_ACTIVE_SUPER_ADMINS,
  payload,
});

const setFilter = (payload: Partial<UserFilterType>): UsersAction => ({
  type: UsersActionTypes.SET_FILTER,
  payload,
});

const selectUsers = (payload: string[]): UsersAction => ({
  type: UsersActionTypes.SELECT_USERS,
  payload,
});

const setUserResources = (payload: UserResources): UsersAction => ({
  type: UsersActionTypes.SET_USER_RESOURCES,
  payload,
});
const updateUserResources = (payload: Partial<UserResources>): UsersAction => ({
  type: UsersActionTypes.UPDATE_USER_RESOURCES,
  payload,
});

const setUserRecordThunk =
  (user: UserAdminDataFullRecord, templatesForTenant: BookingTemplate[]): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const teamsForTenant = teamsSelectors.selectTeamsForTenant(state);
    const assignedTeams = teamsForTenant
      .filter((team) => team.id && team.members?.some((member) => member.userId === user.userId))
      .map((team) => team.id ?? '');

    const assignedTemplates = templatesForTenant
      .filter((template) => template.id && template.potentialHosts?.includes(user.userId ?? ''))
      .map((template) => template.id ?? '');

    dispatch(setUserRecord(user));
    dispatch(setUserResources({ team: assignedTeams, bookingTemplateIds: assignedTemplates }));
  };

export const usersActions = {
  getUsersRequest,
  getUsersSuccess,
  getUsersFail,
  getUsersPageDataRequest,
  inviteUsersRequest,
  inviteUsersSuccess,
  inviteUsersFail,
  editUserRecordRequest,
  editUserRecordSuccess,
  editUserRecordFail,
  disableUserRecordRequest,
  disableUserRecordSuccess,
  disableUserRecordFail,
  deleteUserRecordRequest,
  deleteUserRecordSuccess,
  deleteUserRecordFail,
  setUserRecord,
  updateUserRecord,
  setActiveSuperAdmins,
  setFilter,
  selectUsers,

  updateUserResources,

  setUserRecordThunk,
};
