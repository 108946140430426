import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { State } from '../rootStore';
import {
  CHARGEBEE_MONTH_UNIT,
  CHARGEBEE_STATUS_ACTIVE,
  CHARGEBEE_STATUS_CANCELLED,
  CHARGEBEE_STATUS_NON_RENEWING,
  billIsLatePart1Label,
  billIsLatePart2MultipleLabel,
  billIsLatePart2SingleLabel,
  expireMessage1Label,
  // expireMessage2Label,
  expireMessage3Label,
  teamsAnnualLabel,
  teamsMonthlyLabel,
} from './constants';
import { EXPIRATION_PERIOD_DAYS, authenticationSelectors } from '../authentication';
import { calculateTieredPrice, getCurrencyLabelByCode } from './utils';
import { CalculationTaxes, PaymentMethod } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

const billingState = (state: State) => state.billing;

const selectError = createSelector(billingState, (state) => state.error);
const selectIsFetching = createSelector(billingState, (state) => state.isFetching);
const selectIsRefreshLicencesFetching = createSelector(billingState, (state) => state.isRefreshLicencesFetching);
const selectIsRefreshTenantFetching = createSelector(billingState, (state) => state.isRefreshTenantFetching);
const selectSubscription = createSelector(billingState, (state) => state.subscription);
const selectCustomer = createSelector(billingState, (state) => state.customer);
const selectContacts = createSelector(billingState, (state) => state.contacts);
const selectPaymentSources = createSelector(billingState, (state) => state.paymentSources);
const selectInvoices = createSelector(billingState, (state) => state.invoices);
const selectBillingAddress = createSelector(billingState, (state) => state.billingAddress);
const selectSendBillingEmail = createSelector(billingState, (state) => state.sendBillingEmail);
const selectContactEmails = createSelector(billingState, (state) => state.contactEmails);
const selectTerm = createSelector(billingState, (state) => state.term);
const selectItemPrices = createSelector(billingState, (state) => state.itemPrices);
const selectChangeSeats = createSelector(billingState, (state) => state.changeSeats);
const selectIsPresentUnpaidInvoices = createSelector(
  selectInvoices,
  (invoices) => !!invoices.find((invoice) => invoice.status !== 'paid')
);
const selectCountCards = createSelector(selectPaymentSources, (source) => source.filter((item) => item.card).length);
const selectPrimaryPaymentSourceId = createSelector(selectCustomer, (state) => state.primary_payment_source_id);
const selectIsAutoCollection = createSelector(selectCustomer, (state) => state.auto_collection == 'on');
const selectAllPaymentSources = createSelector(
  selectPaymentSources,
  selectIsAutoCollection,
  selectPrimaryPaymentSourceId,
  (paymentSources, isAutoCollection, primaryPaymentSourceId) => {
    const response = [...paymentSources];

    response.sort((a, b) => {
      if (a?.id === primaryPaymentSourceId) return -1;
      if (b?.id === primaryPaymentSourceId) return 1;
      return 0;
    });

    if (!isAutoCollection) {
      response.unshift({
        id: PaymentMethod.INVOICE,
        type: PaymentMethod.INVOICE,
      });
    }

    return response;
  }
);

const selectIsEmailsUpdated = createSelector(
  selectContacts,
  selectContactEmails,
  (contacts, emails) =>
    contacts.length !== emails.length || contacts.some((contact, index) => contact.email !== emails[index])
);

const selectIsSendBillingEmailUpdated = createSelector(
  selectContacts,
  selectSendBillingEmail,
  (contacts, sendBillingEmail) =>
    (sendBillingEmail && contacts.some((contact) => !contact.send_billing_email)) ||
    (!sendBillingEmail && contacts.every((contact) => Boolean(contact.send_billing_email)))
);

const selectCustomerEmail = createSelector(selectCustomer, (state) => state.email);
const selectCustomerBillingAddress = createSelector(selectCustomer, (state) => state.billing_address);
const selectCustomerBillingAddressText = createSelector(
  selectCustomerBillingAddress,
  (state) =>
    `${state?.first_name} ${state?.last_name}
${state?.line1}, ${state?.city}
${state?.zip}, ${state?.state}, ${state?.country}`
);

const selectIsBillingAddressUpdated = createSelector(
  selectBillingAddress,
  selectCustomerBillingAddress,
  (billingAddress, customerBillingAddress) =>
    billingAddress.first_name !== customerBillingAddress?.first_name ||
    billingAddress.last_name !== customerBillingAddress?.last_name ||
    billingAddress.line1 !== customerBillingAddress?.line1 ||
    billingAddress.city !== customerBillingAddress?.city ||
    billingAddress.state !== customerBillingAddress?.state ||
    billingAddress.zip !== customerBillingAddress?.zip ||
    billingAddress.country !== customerBillingAddress?.country
);

const selectSubscriptionStatus = createSelector(selectSubscription, (state) => state.status);
const selectBillingPeriodUnit = createSelector(selectSubscription, (state) => state.billing_period_unit);
const selectCurrentTermStart = createSelector(selectSubscription, (state) => state.current_term_start * 1000);
const selectCurrentTermEnd = createSelector(selectSubscription, (state) => state.current_term_end * 1000);
const selectNextBillingAt = createSelector(selectSubscription, (state) => state.next_billing_at * 1000);
const selectUpdatedAt = createSelector(selectSubscription, (state) => state.updated_at * 1000);
const selectCurrencyCode = createSelector(selectSubscription, (state) => state.currency_code);
const selectSubscriptionItem = createSelector(selectSubscription, (state) => state.subscription_items[0]);
const selectSubscriptionQuantity = createSelector(selectSubscriptionItem, (state) => state.quantity);
const selectSubscriptionTerm = createSelector(selectSubscriptionItem, (state) => state.item_price_id);
const selectSubscriptionCurrentAmount = createSelector(selectSubscriptionItem, (state) => state.amount / 100);
const selectCancelledAt = createSelector(
  selectSubscription,
  (state) => state.cancelled_at && state.cancelled_at * 1000
);
const selectSumoScheduledChanges = createSelector(selectSubscription, (state) => state.sumoScheduledChanges);

const selectIsStatusActive = createSelector(selectSubscriptionStatus, (status) => status === CHARGEBEE_STATUS_ACTIVE);
const selectIsStatusNonRenewing = createSelector(
  selectSubscriptionStatus,
  (status) => status === CHARGEBEE_STATUS_NON_RENEWING
);
const selectIsStatusCancelled = createSelector(
  selectSubscriptionStatus,
  (status) => status === CHARGEBEE_STATUS_CANCELLED
);
const selectIsSubscriptionActive = createSelector(
  selectIsStatusActive,
  selectIsStatusNonRenewing,
  (active, nonRenewing) => active || nonRenewing
);

const selectScheduledLicenses = createSelector(
  selectSubscriptionQuantity,
  selectSumoScheduledChanges,
  (quantity, changes) => {
    const removeLicenses = changes?.reduce((result, change) => result + (change.seats || 0), 0) || 0;
    return quantity - removeLicenses;
  }
);

const selectRemoveSeatsMax = createSelector(
  selectIsStatusActive,
  selectScheduledLicenses,
  authenticationSelectors.selectAssigned,
  (isStatusActive, scheduledLicenses, assigned) => (isStatusActive ? scheduledLicenses - assigned : 0) // cannot delete more than assigned
);

const selectHasScheduledChangeTheTerm = createSelector(selectSumoScheduledChanges, (scheduledChanges) =>
  Boolean(scheduledChanges?.some((change) => change.plan))
);

const selectNextTerm = createSelector(
  selectSumoScheduledChanges,
  selectSubscriptionTerm,
  (scheduledChanges, currentTerm) => scheduledChanges?.find((change) => change.plan)?.plan || currentTerm
);

const selectIsNextBillingReceived = createSelector(selectNextBillingAt, (time) => Boolean(time));

const selectIsMonthly = createSelector(selectBillingPeriodUnit, (unit) => unit === CHARGEBEE_MONTH_UNIT);
const selectBillingLateDays = createSelector(selectCurrentTermEnd, (time) =>
  dayjs().isAfter(dayjs(time)) ? dayjs().diff(time, 'day') : 0
);
const selectNextBillingDate = createSelector(selectNextBillingAt, (time) => dayjs(time).format('MMMM D, YYYY'));
const selectLastUpdatedDate = createSelector(selectUpdatedAt, (time) => dayjs(time).format('MMMM D, YYYY'));


const selectTaxes = createSelector(
  selectInvoices,
  (invoices) => {
    const sortedInvoices = [...invoices].sort((a, b) => (b.created_at || 0) - (a.created_at || 0));
 
    const mostRecentInvoice = sortedInvoices[0];
    const taxRate = mostRecentInvoice?.line_items?.[0]?.tax_rate || 0;

    return taxRate;
  }
);

const selectNextBillAmount = createSelector(
  selectScheduledLicenses,
  selectItemPrices,
  selectNextTerm,
  selectTaxes, 
  (quantity, itemPrices, term, taxes) => calculateTieredPrice(quantity, itemPrices, term, taxes)
);
const selectIsCancelScheduled = createSelector(selectCancelledAt, (time) => Boolean(time));
const selectCancelDate = createSelector(selectCancelledAt, (time) => dayjs(time).format('MMMM D, YYYY'));

const selectIsChangeTermValid = createSelector(
  selectSubscriptionTerm,
  selectTerm,
  (term, changeTerm) => term !== changeTerm
);

const selectProratedSeatsTermPrice = createSelector(
  // a prorated price to pay in current period
  selectSubscriptionQuantity,
  selectItemPrices,
  selectChangeSeats,
  selectSubscriptionTerm,
  selectTaxes, 
  (quantity, itemPrices, seats, term, taxes) => calculateTieredPrice(quantity + seats, itemPrices, term, taxes)
);

const selectChangeSeatsTermPrice = createSelector(
  // a price which will be after the user will request new number of seats
  selectScheduledLicenses,
  selectItemPrices,
  selectChangeSeats,
  selectNextTerm,
  selectTaxes, 
  (quantity, itemPrices, seats, nextTerm, taxes) => calculateTieredPrice(quantity + seats, itemPrices, nextTerm, taxes)
);

const selectChangeSeatsPrice = createSelector(
  // price of new seats which wants the user in the popup
  selectChangeSeatsTermPrice,
  selectNextBillAmount,
  (termPrice, nextAmount) => Math.abs(termPrice - nextAmount)
);

const selectTermsDaysTotal = createSelector(
  // days of current term
  selectCurrentTermStart,
  selectCurrentTermEnd,
  (termStart, termEnd) => {
    return dayjs(termEnd).diff(dayjs(termStart), 'day');
  }
);

const selectTermsDaysLeft = createSelector(
  // left days of current term
  selectCurrentTermEnd,
  (termEnd) => {
    return dayjs(termEnd).diff(dayjs(), 'day');
  }
);

const selectAddSeatsProratedPrice = createSelector(
  // if the user adding the seats
  selectProratedSeatsTermPrice,
  selectSubscriptionCurrentAmount,
  selectTermsDaysLeft,
  selectTermsDaysTotal,
  (termPrice, currrentAmount, termDaysLeft, termDaysTotal) =>
    ((termPrice - currrentAmount) * termDaysLeft) / termDaysTotal
);

const selectIsChangeSeatsValid = createSelector(selectChangeSeats, (seats) => Boolean(seats));

const selectCurrencyLabel = createSelector(selectCurrencyCode, (code) => getCurrencyLabelByCode(code));

const selectExpireMessage = createSelector(selectBillingLateDays, (billingLateDays) => {
  const messageDaysLate = billingLateDays < 1 ? '' : `${expireMessage1Label} ${billingLateDays}`;
  const messageDaysLeft = `${EXPIRATION_PERIOD_DAYS - billingLateDays} ${expireMessage3Label}`;

  return `${messageDaysLate} ${messageDaysLeft}`;
});

const selectTermLabel = createSelector(selectBillingPeriodUnit, (unit) =>
  unit === CHARGEBEE_MONTH_UNIT ? teamsMonthlyLabel : teamsAnnualLabel
);

const selectNextBillAmountLabel = createSelector(
  selectCurrencyLabel,
  selectNextBillAmount,
  (currencyLabel, nextBillAmount) => currencyLabel + nextBillAmount
);

const selectNextBillLateLabel = createSelector(selectBillingLateDays, (billingLateDays) =>
  billingLateDays
    ? `${billIsLatePart1Label} ${billingLateDays} ${
        billingLateDays === 1 ? billIsLatePart2SingleLabel : billIsLatePart2MultipleLabel
      }`
    : ''
);

const selectCalculationTaxes = createSelector(billingState, (state) => state.calculationTaxes);

const selectTotalAmount = createSelector(
  selectCalculationTaxes,
  (calculationTaxes: CalculationTaxes) => calculationTaxes.totalAmount
);
const selectTotalTax = createSelector(
  selectCalculationTaxes,
  (calculationTaxes: CalculationTaxes) => calculationTaxes.totalTax
);

const selectIsDeletingPaymentMethodFetching = createSelector(
  billingState,
  (billing) => billing.isDeletingPaymentMethodFetching
);

const selectLastUnpaidInvoiceAmount = createSelector(selectInvoices, selectCurrencyLabel, (invoices, currencyLabel) => {
  const amountDue = invoices.find((invoice) => invoice.status !== 'paid')?.amount_due;
  return amountDue ? `${currencyLabel}${amountDue / 100}` : '';
});

export const billingSelectors = {
  selectError,

  selectIsFetching,
  selectIsRefreshLicencesFetching,
  selectIsRefreshTenantFetching,
  selectTerm,

  selectChangeSeats,
  selectSubscriptionTerm,
  selectSubscriptionQuantity,
  selectSubscriptionCurrentAmount,
  selectSumoScheduledChanges,
  selectScheduledLicenses,
  selectRemoveSeatsMax,

  selectInvoices,
  selectBillingAddress,
  selectSendBillingEmail,
  selectContactEmails,
  selectContacts,
  selectPaymentSources,
  selectCustomerEmail,
  selectIsAutoCollection,
  selectAllPaymentSources,
  selectCustomerBillingAddress,
  selectCustomerBillingAddressText,
  selectIsBillingAddressUpdated,
  selectIsEmailsUpdated,
  selectIsSendBillingEmailUpdated,
  selectIsPresentUnpaidInvoices,
  selectCountCards,
  selectPrimaryPaymentSourceId,

  selectIsStatusActive,
  selectIsStatusNonRenewing,
  selectIsStatusCancelled,
  selectIsSubscriptionActive,

  selectHasScheduledChangeTheTerm,

  selectNextTerm,
  selectIsNextBillingReceived,
  selectNextBillingDate,
  selectNextBillAmount,
  selectNextBillAmountLabel,
  selectIsCancelScheduled,
  selectCancelDate,

  selectIsMonthly,

  selectLastUpdatedDate,
  selectCurrencyLabel,
  selectBillingLateDays,

  selectIsChangeTermValid,
  selectChangeSeatsPrice,
  selectAddSeatsProratedPrice,
  selectIsChangeSeatsValid,
  selectExpireMessage,
  selectTermLabel,

  selectNextBillLateLabel,
  selectTermsDaysLeft,
  selectItemPrices,

  selectCalculationTaxes,
  selectTotalAmount,
  selectTotalTax,

  selectIsDeletingPaymentMethodFetching,

  selectLastUnpaidInvoiceAmount,
};
