import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SmartAlert } from '../../../../API';
import {
  bookingPagesActions,
  bookingPageSelectors,
  confirmOverrideSmartAlertModalActions,
} from '../../../../store/bookingPages';
import {
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  smartAlertModalActions,
  smartAlertModalSelectors,
} from '../../../../store/bookingTemplates';
import { ModalName } from '../../../../store/modals';
import {
  DEFAULT_SMART_ALERT,
  smartAlertsActions,
  smartAlertsSelectors,
  SmartTypes,
  UpsertSmartTypeFrom,
} from '../../../../store/smartAlerts';
import { userSettingsSelectors } from '../../../../store/userSettings';
import { SmartAlertCard, SmartAlertEdit } from '../../../smartAlerts';
import { Modal } from '../../modal/Modal';
import labels from './labels';

type SmartAlertModalProps = {
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  isReadOnly?: boolean;
};
export const SmartAlertModal = ({ actions, selectors, isReadOnly }: SmartAlertModalProps) => {
  const dispatch = useDispatch();
  const props = useSelector(smartAlertModalSelectors.selectModalRequest);
  const bookingPageId = useSelector(bookingPageSelectors.selectBookingPageId);
  const bookingTemplateId = useSelector(bookingTemplatesSelectors.selectId);
  const isBookingPage = useSelector(selectors.selectIsBookingPageSelector);
  const smartAlerts = useSelector(smartAlertsSelectors.selectSmartAlertsToUse);
  const smartAlertsInUse = useSelector(selectors.selectSmartAlerts);
  const isCreate = useSelector(userSettingsSelectors.selectSmartAlertsCreate);
  const [isEditAlert, setIsEditAlert] = useState(!!props?.isEdit);
  const alertId = useSelector(smartAlertsSelectors.selectSmartAlertId);
  const showWarning = useSelector(smartAlertsSelectors.selectShowWarningModal(alertId, isBookingPage));
  const isAlertReadOnly = useSelector(smartAlertsSelectors.selectIsAlertReadOnly(alertId, SmartTypes.ALERT));

  useEffect(() => {
    setIsEditAlert(!!props?.isEdit);
  }, [props]);

  const handleClose = () => {
    dispatch(smartAlertModalActions.closeModal());
    setIsEditAlert(false);
  };

  const handleUseAlert = (alert: SmartAlert) => {
    dispatch(actions.updateSmartAlerts(alert.id));
    handleClose();
  };

  const handleCreateNew = () => {
    dispatch(smartAlertsActions.setSmartType(SmartTypes.ALERT));
    dispatch(smartAlertsActions.setSmartAlert(DEFAULT_SMART_ALERT));
    setIsEditAlert(true);
  };

  const handleSave = () => {
    const callFrom = isBookingPage ? UpsertSmartTypeFrom.BOOKING_PAGE : UpsertSmartTypeFrom.BOOKING_TEMPLATE;
    const upsertType = props?.isEdit ? UpsertSmartTypeFrom.NONE : callFrom;
    if (showWarning) {
      dispatch(
        confirmOverrideSmartAlertModalActions.openModal({
          upsertType: callFrom,
        })
      );
    } else {
      dispatch(smartAlertsActions.setBookingPageIds(bookingPageId && isBookingPage ? [bookingPageId] : []));
      dispatch(smartAlertsActions.setBookingTemplateIds(bookingTemplateId && !isBookingPage ? [bookingTemplateId] : []));
      dispatch(smartAlertsActions.upsertSmartTypeRequest(upsertType));
    }
    handleClose();
  };

  return (
    <Modal.Container
      name={ModalName.SMART_ALERT_MODAL_NAME}
      closable={true}
      onClose={handleClose}
      className="w-11 h-full"
      contentClassName="bg-heavy-1 py-24px px-32px"
    >
      {isEditAlert ? (
        <SmartAlertEdit
          hideTypeChange={true}
          hideApplyTo={true}
          handleCancel={handleClose}
          handleSave={handleSave}
          isReadOnly={isReadOnly || isAlertReadOnly}
          isNew={!props?.isEdit}
        />
      ) : (
        <div className="flex flex-column gap-24px p-24px">
          <div className="flex-1 text-title-xl-med">
            <div>{labels.title}</div>
            <div className="text-body-lg-reg text-heavy-60 pt-8px">{labels.description}</div>
          </div>
          <div className="grid -mb-2">
            {isCreate && (
              <div className="col-12 md:col-6 lg:col-4 " style={{ minHeight: '170px' }}>
                <div className="h-full sumo-card sumo-create-card action-card flex-center" onClick={handleCreateNew}>
                  {labels.createCustomTitle}
                </div>
              </div>
            )}

            {smartAlerts
              .filter((alert) => !smartAlertsInUse.includes(alert.id))
              .map((alert) => (
                <div className="col-12 md:col-6 lg:col-4" key={alert.id}>
                  <SmartAlertCard alert={alert} useAlert={() => handleUseAlert(alert)} />
                </div>
              ))}
          </div>
        </div>
      )}
    </Modal.Container>
  );
};
