import { InputText } from 'primereact/inputtext';
import labels from './labels';
import { NoGoogleMapsWarning } from '../noGoogleMapsWarning/NoGoogleMapsWarning';
import { MAX_LENGTH_ADDRESS_LINE, MAX_LENGTH_NAME, MAX_LENGTH_ZIP_CODE } from '../../../types/constants';
import { PhysicalLocation } from '../../../API';
import { useDispatch } from 'react-redux';
import { isLocationStateValid, locationsActions } from '../../../store/locations';
import { CountryDropdown } from '../../common';

type EditLocationNoMapProps = {
  location: PhysicalLocation;
  isReadOnly: boolean;
  isValid: boolean;
};

export const EditLocationNoMap = ({ location, isReadOnly, isValid }: EditLocationNoMapProps) => {
  const dispatch = useDispatch();

  const handleAddressChange = (name: string) => {
    dispatch(locationsActions.updateAddress({ name }));
  };

  const handleCityChange = (city: string) => {
    dispatch(locationsActions.updateAddress({ city }));
  };

  const handleStateChange = (state: string) => {
    dispatch(locationsActions.updateAddress({ state }));
  };

  const handleZIPChange = (zip: string) => {
    dispatch(locationsActions.updateAddress({ zip }));
  };

  const handleCountryChange = (country: string) => {
    dispatch(locationsActions.updateAddress({ country }));
  };

  return (
    <>
      <div className="w-full flex flex-column gap-8px mt-20px mb-12px">
        <div className="text-title-xs-med">{labels.address}</div>
        <InputText
          value={location.address?.name || ''}
          onChange={(e) => handleAddressChange(e.target.value)}
          maxLength={MAX_LENGTH_ADDRESS_LINE}
          className={`${!isValid && !location.address?.name?.trim() ? 'p-invalid' : ''}`}
          disabled={isReadOnly}
        />
      </div>

      <div className="grid -mb-2">
        <div className="col-6 lg:col-3 flex flex-column gap-8px">
          <div className="text-title-xs-med">{labels.city}</div>
          <InputText
            value={location.address?.city || ''}
            onChange={(e) => handleCityChange(e.target.value)}
            maxLength={MAX_LENGTH_NAME}
            className={`${!isValid && !location.address?.city?.trim() ? 'p-invalid' : ''}`}
            disabled={isReadOnly}
          />
        </div>
        <div className="col-6 lg:col-3 flex flex-column gap-8px">
          <div className="text-title-xs-med">{labels.state}</div>
          <InputText
            value={location.address?.state || ''}
            onChange={(e) => handleStateChange(e.target.value)}
            maxLength={MAX_LENGTH_NAME}
            className={`${
              !isValid && !isLocationStateValid(location.address?.country, location.address?.state) ? 'p-invalid' : ''
            }`}
            disabled={isReadOnly}
          />
        </div>
        <div className="col-6 lg:col-3 flex flex-column gap-8px">
          <div className="text-title-xs-med">{labels.zip}</div>
          <InputText
            value={location.address?.zip || ''}
            onChange={(e) => handleZIPChange(e.target.value)}
            maxLength={MAX_LENGTH_ZIP_CODE}
            className={`${!isValid && !location.address?.zip?.trim() ? 'p-invalid' : ''}`}
            disabled={isReadOnly}
          />
        </div>
        <div className="col-6 lg:col-3 flex flex-column gap-8px">
          <div className="text-title-xs-med">{labels.country}</div>
          <CountryDropdown
            value={location.address?.country || ''}
            onChange={(value) => handleCountryChange(value)}
            className={`${!isValid && !location.address?.country?.trim() ? 'p-invalid' : ''}`}
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="w-full h-440px no-maps-connected mt-20px">
        <div className="w-full h-full flex-center no-maps-connected-warning">
          <NoGoogleMapsWarning className="m-20px" />
        </div>
      </div>
    </>
  );
};
