import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdminDataStatus } from '../../../API';
import { usePrevious } from '../../../hooks/usePrevious';
import { authenticationSelectors } from '../../../store/authentication';
import { ModalName, modalsActions } from '../../../store/modals';
import { rolesSelectors } from '../../../store/roles';
import { teamsSelectors } from '../../../store/teams';
import { usersActions, usersSelectors } from '../../../store/users';
import { confirmUsersModalActions, editUsersModalActions, editUsersModalSelectors } from '../../../store/users/modal';
import { userSettingsSelectors } from '../../../store/userSettings';
import { WorkspaceOption } from '../../../store/workspaces';
import { MAX_LENGTH_EMAIL, MAX_LENGTH_NAME } from '../../../types/constants';
import { MultiSelectWithChips, PhoneNumber, SumoTooltip } from '../../common';
import labels from './labels';
import { Team } from '../../../generated-sources/internal-api/models/Team';
import { bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import { BookingTemplate } from '../../../generated-sources/internal-api/models/BookingTemplate';
import { UserResourceOption } from '../../../store/users/types';

export const EditUser = () => {
  const dispatch = useDispatch();
  const open = useSelector(editUsersModalSelectors.selectIsModalOpen);
  const tenantWorkspacesOptions = useSelector(authenticationSelectors.selectTenantWorkspacesOptions);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces) || [];
  const userRecord = useSelector(usersSelectors.selectSelectedUser);
  const roles = useSelector(rolesSelectors.selectRoles);
  const supportedRoles = useSelector(userSettingsSelectors.selectSupportedRoles);
  const remainingLicenses = useSelector(authenticationSelectors.selectRemainingLicenses);
  const supportedRolesId = useSelector(userSettingsSelectors.selectSupportedRolesId);
  const isPhoneInvalid = useSelector(usersSelectors.selectIsUserPhoneInvalid);
  const superSuperAdminIds = useSelector(rolesSelectors.selectSuperAdminIds);
  const currentUserRoleId = useSelector(userSettingsSelectors.selectUserRoleId);
  const superAdmins = useSelector(usersSelectors.selectActiveSuperAdmins) || [];
  const userId = useSelector(authenticationSelectors.selectUserId);
  const isInvitedUser = useSelector(usersSelectors.selectIsUserInvited);

  const assignedTeams = useSelector(usersSelectors.selectAssignedTeams);
  const assignedTemplates = useSelector(usersSelectors.selectAssignedTemplates);
  const templatesByWorkspaces = useSelector(bookingTemplatesSelectors.selectTemplateOptionsByWorkspaces);
  const templatesByWorkspacesOptions = templatesByWorkspaces?.filter((record) =>
    userRecord.workspaceIds?.includes(record.workspaceId)
  );
  const teamsByWorkspaces = useSelector(teamsSelectors.selectTeamsOptionsByWorkspaces);
  const teamsByWorkspacesOptions = teamsByWorkspaces?.filter((record) =>
    userRecord.workspaceIds?.includes(record.workspaceId)
  );

  const [oldUserData, setOldUserData] = useState(userRecord);
  const [isEditable, setIsEditable] = useState(supportedRolesId.includes(userRecord.roleId));

  useEffect(() => {
    dispatch(modalsActions.initializeModal({ name: ModalName.EDIT_USERS_MODAL_NAME, options: {} }));
    return () => {
      dispatch(modalsActions.deleteModal(ModalName.EDIT_USERS_MODAL_NAME));
    };
  }, []);

  useEffect(() => {
    setOldUserData(userRecord);
  }, [userRecord.email]);

  useEffect(() => {
    setIsEditable(supportedRolesId.includes(userRecord.roleId));
  }, [supportedRolesId, userRecord.email]);

  const handleClose = () => {
    dispatch(editUsersModalActions.closeModal());
  };

  const previousRoleId = usePrevious(userRecord.roleId);

  useEffect(() => {
    // checking all Workspaces if roleId is superSuperAdminId
    if (superSuperAdminIds.includes(userRecord.roleId)) {
      dispatch(usersActions.updateUserRecord({ workspaceIds: tenantWorkspacesOptions.map((option) => option.id) }));
    }
    // back to initial reslut if user back from roleId is superSuperAdminId
    else if (previousRoleId && superSuperAdminIds.includes(previousRoleId)) {
      dispatch(usersActions.updateUserRecord({ workspaceIds: oldUserData.workspaceIds }));
    }
  }, [
    userRecord.roleId,
    superSuperAdminIds,
    tenantWorkspacesOptions,
    previousRoleId,
    dispatch,
    oldUserData.workspaceIds,
  ]);

  const handleSave = () => {
    if (oldUserData.status === AdminDataStatus.ACTIVE && userRecord.status === AdminDataStatus.INACTIVE) {
      dispatch(confirmUsersModalActions.openModal());
    } else {
      const myRoleIsChanged = oldUserData.roleId !== userRecord.roleId && userRecord.userId === userId; // need to request Authentication
      dispatch(usersActions.editUserRecordRequest(myRoleIsChanged));
    }
  };

  const confirmFooter = () => {
    return (
      <>
        <Button
          label={labels.save}
          className="min-w-120px"
          onClick={handleSave}
          disabled={!userRecord.workspaceIds?.length || !isEditable || (!isInvitedUser && isPhoneInvalid)}
        />
        <Button label={labels.cancel} text onClick={handleClose} />
      </>
    );
  };

  const updateUserResource = (key: 'team' | 'bookingTemplateIds', value: (string | null)[]) => {
    if (isInvitedUser) {
      dispatch(usersActions.updateUserRecord({ [key]: value }));
    } else {
      dispatch(usersActions.updateUserResources({ [key]: value }));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | DropdownChangeEvent) => {
    const { name, value } = event.target;
    dispatch(usersActions.updateUserRecord({ [name]: value }));
  };

  const handleTeamsListChange = (event: DropdownChangeEvent) => {
    updateUserResource('team', event.target.value || []);
  };

  const handleTemplatesListChange = (event: DropdownChangeEvent) => {
    updateUserResource('bookingTemplateIds', event.target.value || []);
  };

  const handlePhoneChange = (phoneNumber: string) => {
    dispatch(usersActions.updateUserRecord({ phoneNumber }));
  };

  const handleCodeChange = (countryCode: string) => {
    dispatch(usersActions.updateUserRecord({ countryCode }));
  };

  const handleStatusChange = (checked: boolean | undefined) => {
    dispatch(usersActions.updateUserRecord({ status: checked ? AdminDataStatus.ACTIVE : AdminDataStatus.INACTIVE }));
  };

  const handleTemplatesChange = (selectedWorkspaceIds: Array<string | null>) => {
    // filter all selected templates according to new list of workspaces
    const listTemplates: BookingTemplate[] = [];
    selectedWorkspaceIds.forEach((workspaceId) => {
      const templatesForWorkspace = templatesByWorkspaces.find((record) => record.workspaceId === workspaceId);
      if (templatesForWorkspace) {
        listTemplates.push(...templatesForWorkspace.resource);
      }
    });
    const actualTemplates = assignedTemplates?.filter((id) =>
      listTemplates.some((template) => template.id === id)
    );
    updateUserResource('bookingTemplateIds', actualTemplates || []);
  };

  const handleTeamsChange = (selectedWorkspaceIds: Array<string | null>) => {
    // filter all selected teams according to new list of workspaces
    const teams: Team[] = [];
    selectedWorkspaceIds.forEach((workspaceId) => {
      const teamsForWorkspace = teamsByWorkspaces.find((record) => record.workspaceId === workspaceId);
      if (teamsForWorkspace) {
        teams.push(...teamsForWorkspace.resource);
      }
    });
    const actualTeams = assignedTeams?.filter((id) => teams.some((team) => team.id === id));
    updateUserResource('team', actualTeams || []);
  };

  const handleWorkspaceChange = (e: DropdownChangeEvent) => {
    if (!superSuperAdminIds.includes(userRecord.roleId)) {
      handleTemplatesChange(e.target.value);
      handleTeamsChange(e.target.value);
      handleChange(e);
    } else {
      // Prevent unchecking if roleId is superSuperAdminId
      const currentValues = userRecord.workspaceIds ?? [];
      const newValue = e.value;
      if (newValue.length < currentValues.length) {
        e.preventDefault();
      } else {
        handleTemplatesChange(e.target.value);
        handleTeamsChange(e.target.value);
        handleChange(e);
      }
    }
  };

  const disableDropdownRole = () => {
    return (
      !isEditable ||
      (superAdmins.length < 2 &&
        !!currentUserRoleId &&
        superSuperAdminIds.includes(currentUserRoleId) &&
        userRecord.userId === userId)
    );
  };

  const disableInputSwitchStatus = () => {
    return (
      isInvitedUser ||
      (oldUserData.status === AdminDataStatus.INACTIVE && remainingLicenses < 1) ||
      userRecord.userId === userId ||
      !isEditable
    );
  };

  const handleWorkspaceChipRemove = (id: string) => {
    const workspaceIds = userRecord.workspaceIds?.filter((option) => option !== id) || [];
    handleTemplatesChange(workspaceIds);
    handleTeamsChange(workspaceIds);
    dispatch(usersActions.updateUserRecord({ workspaceIds }));
  };

  const isWorkspaceOptionDisabled = (id: string) =>
    superSuperAdminIds.includes(userRecord.roleId) || !userWorkspaces.includes(id);

  const selectedItemContent = (value: string) => {
    return <>{tenantWorkspacesOptions.find((option) => option.id === value)?.name || ''}</>;
  };

  const isResourceOptionDisabled = (option: UserResourceOption) => {
    return !!option?.workspaceId && !userWorkspaces?.includes(option.workspaceId);
  };

  return (
    <Dialog
      header={`${labels.title} ${userRecord.fullName ? '(' + userRecord.fullName + ')' : ''}`}
      footer={confirmFooter}
      visible={open}
      onHide={handleClose}
      className="w-840px"
      focusOnShow={false}
      draggable={false}
    >
      <div className="flex flex-column gap-24px">
        {!isEditable && (
          <div className="flex-left-center gap-8px py-12px px-16px w-full border-1 border-blue-light border-radius-8px bg-blue-soft">
            <InformationCircleIcon className="icon-20px text-blue-main flex-none" />
            <div className="flex-1 text-label-xs-reg">
              {labels.higherRoleDesc} {labels.readonlyDesc}
            </div>
          </div>
        )}

        <div className="flex flex-wrap -mx-20px -my-12px">
          <div className="w-12 md:w-6 px-20px py-12px flex flex-column gap-10px">
            <div className="text-title-xs-med">{labels.fullName}</div>
            <InputText
              name="fullName"
              disabled={isInvitedUser || !isEditable}
              value={userRecord.fullName ? userRecord.fullName : ''}
              onChange={handleChange}
              maxLength={MAX_LENGTH_NAME}
            />
          </div>

          <div className="w-12 md:w-6 px-20px py-12px flex flex-column gap-10px">
            <div className="text-title-xs-med">{labels.role}</div>
            <div className="flex-left-center gap-16px">
              <Dropdown
                name="roleId"
                className="flex-1"
                options={isEditable ? supportedRoles : roles}
                optionLabel="name"
                optionValue="id"
                value={userRecord.roleId}
                onChange={handleChange}
                disabled={disableDropdownRole()}
              />

              {isInvitedUser ? (
                <div className="flex-none flex-left-center gap-4px">
                  <div className="text-label-lg-reg text-heavy-80">{labels.inviteSent}</div>
                  <SumoTooltip text={labels.inviteSentTooltip} />
                </div>
              ) : (
                <div className="flex-none flex-left-center gap-4px">
                  <InputSwitch
                    checked={userRecord.status !== AdminDataStatus.INACTIVE}
                    disabled={disableInputSwitchStatus()}
                    onChange={(e) => handleStatusChange(!!e.value)}
                  />
                  <div className="text-label-lg-reg text-heavy-80 ml-6px">{labels.active}</div>
                  {userRecord.roleId === currentUserRoleId ? (
                    <SumoTooltip text={labels.deactivateHimself} />
                  ) : (
                    <SumoTooltip text={labels.activeTooltip} />
                  )}
                </div>
              )}
            </div>
            {superAdmins.length < 2 &&
              currentUserRoleId &&
              superSuperAdminIds.includes(currentUserRoleId) &&
              userRecord.userId === userId && (
                <div className="text-body-s-reg text-heavy-60">{labels.onlyOneSuperAdmin}</div>
              )}
          </div>

          <div className="w-12 md:w-6 px-20px py-12px flex flex-column gap-10px">
            <div className="text-title-xs-med">{labels.phoneNumber}</div>
            <PhoneNumber
              countryCode={userRecord.countryCode || ''}
              handleChangeCode={handleCodeChange}
              phoneNumber={userRecord.phoneNumber || ''}
              handleChangePhone={handlePhoneChange}
              disabled={isInvitedUser || !isEditable}
              inputClassName={`w-full ${!isInvitedUser && isEditable && isPhoneInvalid ? 'p-invalid' : ''}`}
            />
          </div>

          <div className="w-12 md:w-6 px-20px py-12px flex flex-column gap-10px">
            <div className="text-title-xs-med">{labels.email}</div>
            <InputText id="email" value={userRecord.email || ''} maxLength={MAX_LENGTH_EMAIL} disabled />
          </div>

          <div className="w-12 px-20px py-12px flex flex-column gap-10px">
            <div className="text-title-xs-med">{labels.workspaceMess}</div>
            <MultiSelectWithChips<WorkspaceOption>
              name="workspaceIds"
              value={userRecord.workspaceIds || []}
              options={tenantWorkspacesOptions}
              optionLabel="name"
              optionValue="id"
              filter
              onChange={handleWorkspaceChange}
              onRemove={handleWorkspaceChipRemove}
              isRemovable={(value: string) => !isWorkspaceOptionDisabled(value)}
              disabled={!isEditable}
              className={`${!userRecord.workspaceIds?.length && 'p-invalid'}`}
              optionDisabled={(option: WorkspaceOption) => isWorkspaceOptionDisabled(option.id)}
              selectedItemContent={selectedItemContent}
              showSelectAll={tenantWorkspacesOptions.every((option) => !isWorkspaceOptionDisabled(option.id))}
            />
            {superSuperAdminIds.includes(userRecord.roleId) && (
              <div className="text-body-s-reg text-heavy-60">{labels.superAdminAccessDescription}</div>
            )}
          </div>

          <div className="w-12 px-20px py-12px flex flex-column gap-10px">
            <div className="text-title-xs-med">{labels.teams}</div>
            <MultiSelect
              name="team"
              value={assignedTeams || []}
              options={teamsByWorkspacesOptions}
              optionLabel="name"
              optionValue="id"
              optionGroupLabel="label"
              optionGroupChildren="resource"
              onChange={handleTeamsListChange}
              placeholder={labels.teamsPlaceholder}
              optionDisabled={isResourceOptionDisabled}
              display="chip"
              filter
              disabled={!isEditable || !userRecord.workspaceIds?.length || !teamsByWorkspacesOptions.length}
            />
          </div>

          <div className="w-12 px-20px py-12px flex flex-column gap-10px">
            <div className="text-title-xs-med">{labels.bookingTemplates}</div>
            <MultiSelect
              name="bookingTemplateIds"
              value={assignedTemplates || []}
              options={templatesByWorkspacesOptions}
              optionLabel="name"
              optionValue="id"
              optionGroupLabel="label"
              optionGroupChildren="resource"
              onChange={handleTemplatesListChange}
              optionDisabled={isResourceOptionDisabled}
              display="chip"
              filter
              disabled={!isEditable || !userRecord.workspaceIds?.length || !templatesByWorkspacesOptions.length}
              placeholder={labels.assignBookingTemplatesPlaceholder}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
