import { AppType, IntegrationType, VideoConferenceType, WorkspaceIntegrationType } from '../../API';
import { getUrlParams } from '../../services/URLService';
import { GetIntegrationURLParamsType } from './types';

export const generateVideoConference = (type: IntegrationType | WorkspaceIntegrationType | AppType) => {
  switch (type) {
    case IntegrationType.GOOGLE_MEET:
      return VideoConferenceType.GOOGLE_MEET;
    case IntegrationType.MICROSOFT_TEAMS:
      return VideoConferenceType.MICROSOFT_TEAMS;
    case IntegrationType.ZOOM:
      return VideoConferenceType.ZOOM;
    default:
      return null;
  }
};

export const getIntegrationURLParams = (): GetIntegrationURLParamsType => {
  const params = getUrlParams();
  const code = params['code'] || null;
  const state = params['state'] ? JSON.parse(params['state']) : null;
  const type = state?.type || null;

  const error = params['error'] || null;
  const errorDescription = params['error_description'] || null;
  if (error || errorDescription) {
    console.error('error', `${error}: ${errorDescription}`);
  }

  const workspaceId = state?.workspaceId || null;
  const customParameters = state?.customParameters || null;
  return { code, type, workspaceId, customParameters, error, errorDescription };
};
