import { combineReducers } from 'redux';
import { generateWhereLocationTypeToggle } from './utils';
import { BookingTemplatesActionTypes, BookingTemplatesAction } from './actions';
import { DEFAULT_ACCORDION_INDEXES, DEFAULT_BOOKING_TEMPLATE_DATA, EMPTY_ACCORDION_INDEXES } from './constants';
import { CustomFieldInput, UpdateBookingTemplateInput } from '../../API';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { AccordionIndexes, FilterType } from './types';
import { BookingTemplate } from '../../generated-sources/internal-api/models/BookingTemplate';

const customFields = (state: (CustomFieldInput | null)[] = [], action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.SET_BOOKING_TEMPLATE_FIELDS:
      return action.payload;
    case BookingTemplatesActionTypes.ADD_BOOKING_TEMPLATE_CUSTOM_FIELD:
      return [...state, action.payload];
    case BookingTemplatesActionTypes.REMOVE_BOOKING_TEMPLATE_CUSTOM_FIELD:
      return state.filter((customField) => customField?.id !== action.payload);
    case BookingTemplatesActionTypes.UPDATE_BOOKING_TEMPLATE_CUSTOM_FIELD:
      return state.map((customField) => (customField?.id === action.payload.id ? action.payload : customField));
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const bookingTemplate = (
  state: UpdateBookingTemplateInput = DEFAULT_BOOKING_TEMPLATE_DATA,
  action: BookingTemplatesAction | GlobalAction
) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_SUCCESS:
    case BookingTemplatesActionTypes.SET_BOOKING_TEMPLATE:
      return action.payload;
    case BookingTemplatesActionTypes.CLEAR_BOOKING_TEMPLATE:
      return DEFAULT_BOOKING_TEMPLATE_DATA;
    case BookingTemplatesActionTypes.ADD_BOOKING_TEMPLATE_CUSTOM_FIELD:
    case BookingTemplatesActionTypes.SET_BOOKING_TEMPLATE_FIELDS:
    case BookingTemplatesActionTypes.REMOVE_BOOKING_TEMPLATE_CUSTOM_FIELD:
    case BookingTemplatesActionTypes.UPDATE_BOOKING_TEMPLATE_CUSTOM_FIELD:
      return {
        ...state,
        inputFields: customFields(state.inputFields as CustomFieldInput[], action),
      } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_RECORD:
      return { ...state, ...action.payload } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_WHAT_STEP:
      return { ...state, what: { ...state.what, ...action.payload } } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_POTENTIAL_HOSTS:
      return { ...state, potentialHosts: action.payload } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_POTENTIAL_TEAMS:
      return { ...state, potentialTeams: action.payload } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_WHERE_STEP:
      return { ...state, where: { ...state.where, ...action.payload } } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.TOGGLE_WHERE_LOCATION_TYPE:
      return {
        ...state,
        where: generateWhereLocationTypeToggle(state.where || {}, action.payload),
      } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_WHEN_STEP:
      return { ...state, when: { ...state.when, ...action.payload } } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_WHEN_DURATION:
      return {
        ...state,
        when: { ...state.when, duration: { ...state.when?.duration, ...action.payload } },
      } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_WHEN_DATE_RANGE:
      return {
        ...state,
        when: { ...state.when, dateRange: { ...state.when?.dateRange, ...action.payload } },
      } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_WHEN_SCHEDULE_BUFFER:
      return {
        ...state,
        when: { ...state.when, scheduleBuffer: { ...state.when?.scheduleBuffer, ...action.payload } },
      } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_HOW_STEP:
      return { ...state, labels: { ...state.labels, ...action.payload } } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_CALENDAR_STEP:
      return { ...state, calendar: { ...state.calendar, ...action.payload } } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_STYLE_STEP:
      return { ...state, style: { ...state.style, ...action.payload } } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_NOTIFICATIONS_STEP:
      return { ...state, notifications: { ...state.notifications, ...action.payload } } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_CONFIRMATION_STEP:
      return {
        ...state,
        confirmationsPage: { ...state.confirmationsPage, ...action.payload },
      } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.SELECT_TIME_ZONES:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          selectedTimeZone: action.payload,
          defaultTimeZone: action.payload.includes(state.calendar?.defaultTimeZone || '')
            ? state.calendar?.defaultTimeZone
            : action.payload[0],
        },
      } as UpdateBookingTemplateInput;
    case BookingTemplatesActionTypes.UPDATE_SMART_ALERTS:
      return state.smartAlertIds?.includes(action.payload)
        ? ({
            ...state,
            smartAlertIds: state.smartAlertIds.filter((id) => id !== action.payload),
          } as UpdateBookingTemplateInput)
        : ({
            ...state,
            smartAlertIds: state.smartAlertIds ? [...state.smartAlertIds, action.payload] : [action.payload],
          } as UpdateBookingTemplateInput);
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_BOOKING_TEMPLATE_DATA;
    default:
      return state;
  }
};

const potentialTeams = (state: Array<string | null> = [], action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_SUCCESS:
      return action.payload.potentialTeams || [];
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const bookingTemplates = (state: UpdateBookingTemplateInput[] = [], action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const selectedBookingTemplates = (state: string[] = [], action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.SELECT_BOOKING_TEMPLATE:
      return [...state, action.payload];
    case BookingTemplatesActionTypes.UNSELECT_BOOKING_TEMPLATE:
      return state.filter((BookingTemplate) => BookingTemplate !== action.payload);
    case BookingTemplatesActionTypes.UNSELECT_ALL_BOOKING_TEMPLATE:
    case BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_SUCCESS:
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_PAGE_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const filterType = (state: FilterType = FilterType.MY, action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.SET_FILTER:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return FilterType.MY;
    default:
      return state;
  }
};

const cloneName = (state = '', action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.SET_CLONE_NAME:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const isEdited = (state = false, action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.ADD_BOOKING_TEMPLATE_CUSTOM_FIELD:
    case BookingTemplatesActionTypes.SET_BOOKING_TEMPLATE_FIELDS:
    case BookingTemplatesActionTypes.REMOVE_BOOKING_TEMPLATE_CUSTOM_FIELD:
    case BookingTemplatesActionTypes.UPDATE_BOOKING_TEMPLATE_CUSTOM_FIELD:
    case BookingTemplatesActionTypes.UPDATE_WHAT_STEP:
    case BookingTemplatesActionTypes.UPDATE_POTENTIAL_HOSTS:
    case BookingTemplatesActionTypes.UPDATE_POTENTIAL_TEAMS:
    case BookingTemplatesActionTypes.TOGGLE_WHERE_LOCATION_TYPE:
    case BookingTemplatesActionTypes.UPDATE_WHEN_STEP:
    case BookingTemplatesActionTypes.UPDATE_WHEN_DURATION:
    case BookingTemplatesActionTypes.UPDATE_WHEN_DATE_RANGE:
    case BookingTemplatesActionTypes.UPDATE_WHEN_SCHEDULE_BUFFER:
    case BookingTemplatesActionTypes.UPDATE_NOTIFICATIONS_STEP:
    case BookingTemplatesActionTypes.UPDATE_CONFIRMATION_STEP:
      return true;
    case BookingTemplatesActionTypes.UPDATE_WHERE_STEP: // need to skip isEdit for default countryCode set
      return !action.payload.customCountryCode;
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_SUCCESS:
    case BookingTemplatesActionTypes.SET_BOOKING_TEMPLATE:
    case BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_SUCCESS:
    case BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const accordionIndexes = (
  state: AccordionIndexes = DEFAULT_ACCORDION_INDEXES,
  action: BookingTemplatesAction | GlobalAction
) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.UPDATE_ACCORDION_INDEXES:
      return { ...EMPTY_ACCORDION_INDEXES, ...action.payload };
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_PAGE_REQUEST:
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_ACCORDION_INDEXES;
    default:
      return state;
  }
};

const lastLoadTime = (state = 0, action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_SUCCESS:
      return new Date().getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const isFetching = (state = false, action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_REQUEST:
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_PAGE_REQUEST:
      case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_BY_TENANT_REQUEST:
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST:
    case BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_REQUEST:
    case BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_REQUEST:
    case BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_REQUEST:
    case BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_REQUEST:
    case BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_REQUEST:
      return true;
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_SUCCESS:
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_FAIL:
      case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_BY_TENANT_SUCCESS:
      case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_BY_TENANT_FAIL:
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_SUCCESS:
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_FAIL:
    case BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_SUCCESS:
    case BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_FAIL:
    case BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_SUCCESS:
    case BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_FAIL:
    case BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_SUCCESS:
    case BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_FAIL:
    case BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_SUCCESS:
    case BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_FAIL:
    case BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_SUCCESS:
    case BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_REQUEST:
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_PAGE_REQUEST:
    case BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_REQUEST:
    case BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_REQUEST:
    case BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_REQUEST:
    case BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_REQUEST:
      return null;
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_FAIL:
    case BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_FAIL:
    case BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_FAIL:
    case BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_FAIL:
    case BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const bookingTemplatesForTenant = (state: BookingTemplate[] = [], action: BookingTemplatesAction | GlobalAction) => {
  switch (action.type) {
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_BY_TENANT_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  bookingTemplate,
  potentialTeams,
  bookingTemplates,
  selectedBookingTemplates,
  filterType,
  cloneName,
  isEdited,
  accordionIndexes,
  lastLoadTime,
  isFetching,
  error,
  bookingTemplatesForTenant,
});
