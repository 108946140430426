export const BookingPages = Object.freeze({
  NEW_BOOKING_PAGE: `Neue Buchungsseite`,
  DELETE_BOOKING_PAGE_DIALOG_TEXT: `Sind Sie sicher, dass Sie diese Buchungsseite löschen möchten?`,
  DELETE_BOOKING_PAGES_DIALOG_TEXT: `Sind Sie sicher, dass Sie diese Buchungsseiten löschen möchten?`,
  TITLE: `Buchungsseiten`,
  ADD_TO_SITE: `Zur Website hinzufügen`,
  CLONE_MODAL_TITLE: `Buchungsseite klonen`,
  DEFAULT_FILTER: `Meine Buchungsseiten`,
  SHARED_LABEL: `Geteilt`,
  CLONE_NOTIFICATION: `Bitte beachten Sie, dass die Eigenschaft "Nur Admin" standardmäßig für einen geklonten Datensatz deaktiviert ist.`,
});

export const BookingPagesToastsNotifications = Object.freeze({
  CLONE_BOOKING_PAGES_ERROR_MESSAGE: `Klonanfrage für Buchungsseite fehlgeschlagen`,
  CLONE_BOOKING_PAGES_SUCCESS_MESSAGE: `Buchungsseite erfolgreich geklont`,
  DELETE_BOOKING_PAGES_ERROR_MESSAGE: `Löschanfrage für Buchungsseiten fehlgeschlagen`,
  DELETE_BOOKING_PAGES_SUCCESS_MESSAGE: `Buchungsseite erfolgreich gelöscht`,
  GET_BOOKING_PAGE_ERROR_MESSAGE: `Buchungsseite konnte nicht abgerufen werden, sie könnte gelöscht sein`,
  GET_BOOKING_PAGES_ERROR_MESSAGE: `Anfrage für Buchungsseiten fehlgeschlagen`,
  SAVE_BOOKING_PAGE_ERROR_MESSAGE: `Speicheranfrage für Buchungsseite fehlgeschlagen`,
  SAVE_BOOKING_PAGE_SUCCESS_MESSAGE: `Buchungsseite erfolgreich gespeichert`,
});

export const DefaultBookingPage = Object.freeze({
  NAME: `30-Minuten-Meeting`,
  BOOK_A_MEETING: `Meeting buchen`,
  MEETING_DETAILS: `Meeting-Details`,
  GUESTS_INFO: `Einladungsinformationen`,
  BOOK_MEETING_BUTTON: `Bestätigen`,
  BOOK_ANOTHER_MEETING_BUTTON: `Weiteres Meeting buchen`,
  CANCEL: `Abbrechen`,
  CANCEL_MEETING_BUTTON: `Meeting absagen`,
  CONFIRM_CANCEL_BUTTON: `Bestätigen`,
  SAVE_MEETING_BUTTON: `Umschreiben`,
  MEETING_BOOKED_TITLE: `Sie sind gebucht`,
  MEETING_BOOKED_DESCRIPTION: `Eine Bestätigungs-E-Mail wurde an Ihren Posteingang gesendet.`,
  MEETING_RESCHEDULED_TITLE: `Sie sind neu geplant`,
  MEETING_RESCHEDULED_DESCRIPTION: `Eine Bestätigungs-E-Mail wurde an Ihren Posteingang gesendet.`,
  MEETING_CANCELED_TITLE: `Sie sind abgesagt`,
  MEETING_CANCELED_DESCRIPTION: `Eine Bestätigungs-E-Mail wurde an Ihren Posteingang gesendet.`,
});

export const EditBookingPage = Object.freeze({
  WHAT_TITLE: `Was`,
  WHAT_DESCRIPTION: `Buchungsvorlagen und Details`,
  WHO_TITLE: `Wer`,
  WHO_DESCRIPTION: `Meeting-Gastgeber`,
  WHERE_TITLE: `Wo`,
  WHERE_DESCRIPTION: `Eventdetails und Standorte`,
  WHEN_TITLE: `Wann`,
  WHEN_DESCRIPTION: `Dauer und Verfügbarkeit`,
  HOW_TITLE: `Wie soll es aussehen?`,
  HOW_DESCRIPTION: `Branding-Stile und Anzeigen`,
  ALERTS_TITLE: `Warnungen`,
  ALERTS_DESCRIPTION: `E-Mails und Erinnerungen`,
  INVITEE_TITLE: `Einladungseinstellungen`,
  INVITEE_DESCRIPTION: `Buchungsformular und Berechtigung des Eingeladenen`,
  AFTER_TITLE: `Nach der Buchung`,
  AFTER_DESCRIPTION: `Bestätigungsseite und Stornierungsrichtlinie`,
  ADDITIONAL_CONFIGURATIONS: `Zusätzliche Konfigurationen`,
  VIEW_BOOKING_PAGE: `Buchungsseite anzeigen`,
  COPY_LINK: `Link kopieren`,
  ADD_TO_WEBSITE: `Zur Website hinzufügen`,
  ACTIVE: `Aktiv`,
  BOOKING_TEMPLATE_LINK_TOOLTIP: `Datensatz gesperrt. Diese Einstellungen stammen aus der Buchungsvorlage mit dem Titel: `,
  BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE: `Klicken Sie hier, um diese Einstellungen zu überschreiben`,
  LOCKED_ADMIN_ONLY: `Nur ein Admin darf diese Einstellungen ändern. Zuletzt geändert von:`,
  LOCKED_NO_PERMISSIONS: `Sie haben keine Berechtigung, diese Buchungsseite zu bearbeiten. Zuletzt geändert von:`,
  DELETED_USER: `Gelöschter Benutzer`,
});

export const BookingPageWhatStep = Object.freeze({
  NAME: `Name`,
  NAME_PLACEHOLDER: `Benutzerdefinierter Name für diese Buchungsseite`,
  NAME_TOOLTIP: `Dieser Text wird den Teilnehmern im Kalender und in E-Mails angezeigt. Zum Beispiel "30 Min. Meeting" oder "Zahnuntersuchung".`,
  COLOR: `Farbe`,
  COLOR_TOOLTIP: `Wählen Sie eine Farbe für diese Buchungsseite und das erstellte Meeting.`,
  BOOKING_TEMPLATE: `Buchungsvorlage`,
  BOOKING_TEMPLATE_PLACEHOLDER: `Buchungsvorlage auswählen`,
  BOOKING_TEMPLATE_TOOLTIP: `Wählen Sie eine Buchungsvorlage, um alle Einstellungen für die aktuelle Buchungsseite automatisch anzuwenden.`,
  ADMIN_ONLY: `Nur Administrator`,
  ADMIN_ONLY_TOOLTIP: `Nur ein Administrator darf diese Einstellungen ändern`,
  MEETING_INSTRUCTIONS: `Besprechungsanweisungen`,
  MEETING_INSTRUCTIONS_PLACEHOLDER: `Besprechungsanweisungen eingeben`,
  MEETING_INSTRUCTIONS_TOOLTIP: `Schreiben Sie eine Zusammenfassung und alle Details, die Ihr Eingeladener über das Ereignis wissen sollte.`,
});

export const EditBookingPageWhoStep = Object.freeze({
  HOSTS_AND_TEAMS: `Gastgeber und Teams`,
  HOSTS_TOOLTIP: `Wählen Sie eine Person, mehrere Personen oder ein Team aus (bei dem ein Mitglied im Rotationsprinzip ausgewählt wird), um an diesem Meeting teilzunehmen.`,
  SEARCH_PLACEHOLDER: `Gastgeber und Teams suchen`,
  UNASSIGNED: `Unzugewiesen`,
  WRONG_HOST: `Sie müssen der Hauptgastgeber sein, um die Buchungsseite zu speichern`,
});

export const EditBookingPageDurationStep = Object.freeze({
  DURATION: `Dauer`,
  DURATION_DESCRIPTION: `Legen Sie fest, wie lange Ihr Meeting dauern soll. Nicht länger als 12 Stunden.`,
  BUFFER_TIME: `Pufferzeit`,
  BUFFER_TIME_TOOLTIP: `Pufferzeiten vor und nach dem Event festlegen`,
  AFTER_CHECKBOX_LABEL: `nach dem Event`,
  BEFORE_CHECKBOX_LABEL: `vor dem Event`,
});

export const EditBookingPageDateRangeStep = Object.freeze({
  SCHEDULE_AVAILABILITY: `Verfügbarkeitsplan`,
  MINIMUM_NOTICE_PERIOD: `Mindestankündigungsfrist`,
  MINIMUM_NOTICE_PERIOD_TOOLTIP: `Diese Einstellung bestimmt die minimale Zeit, die erforderlich ist, bevor ein Meeting geplant werden kann. Sie hilft sicherzustellen, dass Sie genügend Vorlaufzeit haben, um sich auf bevorstehende Meetings vorzubereiten.`,
  OPTION_DAYS_INTO_THE_FUTURE: `Tage in die Zukunft`,
  OPTION_DATE_RANGE: `Innerhalb eines Datumsbereichs`,
  OPTION_INDEFINITELY: `Unbegrenzt in die Zukunft`,
});

export const EditBookingPageInviteeStep = Object.freeze({
  PERSONALIZED_TITLE: `Personalisierte Buchungsseite`,
  PERSONALIZED_DESCRIPTION: `Sie können Informationen über den Eingeladenen vorausfüllen, um die Seite für eine bestimmte Person zu personalisieren`,
  PERSONAL_INFO: `persönliche Informationen des Eingeladenen`,
  QUESTIONS: `Fragen im Buchungsformular`,
  QUESTIONS_DESCRIPTION: `Verbessern Sie das Buchungsformular mit Einladungsfragen`,
  NEW_QUESTION: `Neue Frage`,
  EDIT_QUESTION: `Frage bearbeiten`,
  PERMISSIONS: `Einladungsberechtigungen`,
  PERMISSIONS_DESCRIPTION: `Definieren Sie die Aktionen, die Ihren Eingeladenen zur Verfügung stehen`,
  OPTION_INVITE_OTHERS: `Eingeladene können andere einladen`,
  OPTION_INVITE_OTHERS_TOOLTIP: `Wenn Sie diese Funktion aktivieren, wird auf der Buchungsseite ein Feld "Andere einladen" angezeigt, in dem der Eingeladene andere Personen zur Teilnehmerliste hinzufügen kann.`,
  OPTION_ENTER_NOTE: `Eingeladene können eine Notiz für alle Eingeladenen eingeben`,
  OPTION_ENTER_NOTE_TOOLTIP: `Wenn Sie diese Funktion aktivieren, wird ein Feld "Notiz für Teilnehmer" angezeigt, in dem der Eingeladene eine Notiz eingeben kann, die im gebuchten Kalendertermin für alle Teilnehmer angezeigt wird.`,
});

export const EditBookingPageAfterStep = Object.freeze({
  CONFIRMATION_PAGE: `Bestätigungsseite`,
  OPTION_DISAPLY_CONFIRMATION_PAGE: `SUMO-Bestätigungsseite anzeigen`,
  OPTION_REDIRECT_TO_EXTERNAL_PAGE: `Auf eine bestimmte externe Seite weiterleiten`,
  EXTERNAL_LINK_PLACEHOLDER: `Externe Weiterleitungs-URL`,
  AVAILABLE_ACTIONS: `Verfügbare Aktionen`,
  AVAILABLE_ACTIONS_DESCRIPTION: `Zugängliche Aktionen für Eingeladene von dieser Bestätigungsseite aus.`,
  OPTION_RESCHEDULE: `Verschieben`,
  OPTION_CANCEL: `Meeting absagen`,
  OPTION_BOOK_ANOTHER: `Ein weiteres Meeting buchen`,
  CUSTOM_LINKS: `Zusätzliche benutzerdefinierte Links`,
  CUSTOM_LINKS_DESCRIPTION: `Fügen Sie benutzerdefinierte Links auf der Bestätigungsseite hinzu, z. B. eine Umfrage oder zusätzliche Ressourcen.`,
  ADD_CUSTOM_LINK: `Benutzerdefinierten Link hinzufügen`,
  CHANGE_CUSTOM_LINK: `Benutzerdefinierten Link ändern`,
  LINK_URL: `Link-URL`,
  CUSTOM_LINK_LABEL: `Beschriftung des benutzerdefinierten Links`,
  CUSTOM_LINK_LABEL_PLACEHOLDER: `Meetingsvereinbarung`,
  CANCELATION_POLICY: `Stornierungsrichtlinie`,
  CANCELATION_POLICY_DESCRIPTION: `Geben Sie Details zu Ihrer Stornierungsrichtlinie an, um die Folgen von Änderungen zu klären.`,
  CANCELATION_POLICY_PLACEHOLDER: `Text der Stornierungsrichtlinie`,
});

export const BookingPageHowStep = Object.freeze({
  BRANDING_TITLE: `Anpassung der Buchungsseite`,
  BRANDING_DESCRIPTION_BOOKING_PAGE: `Passen Sie das Erscheinungsbild, die Stile und die Beschriftungen dieser Buchungsseite an.`,
  BRANDING_DESCRIPTION_BOOKING_TEMPLATE: `Passen Sie das Erscheinungsbild, die Stile und die Beschriftungen dieser Buchungsvorlage an.`,
  CALENDAR_TITLE: `Kalendereinstellungen`,
  CALENDAR_DESCRIPTION: `Passen Sie das Anzeigeformat für Kalender, Datum und Zeitzone an.`,
});

export const BookingPageBrandingModal = Object.freeze({
  TITLE: `Anpassung der Buchungsseite`,
  DESCRIPTION_BOOKING_PAGE: `Diese Buchungsseite verwendet die Standardeinstellungen des Arbeitsbereichs, aber Sie können sie unten anpassen.`,
  DESCRIPTION_BOOKING_TEMPLATE: `Diese Buchungsvorlage verwendet die Standardeinstellungen des Arbeitsbereichs, aber Sie können sie unten anpassen.`,
  LOGO: `Logo`,
  BACKGROUND: `Hintergrund`,
  BACKGROUND_OPTION_BLANK: `Leer`,
  BACKGROUND_OPTION_WALLPAPER: `Tapete`,
  BACKGROUND_OPTION_Color: `Farbe`,
  BACKGROUND_TYPE: `Hintergrundtyp`,
  BACKGROUND_TYPE_TOP: `Banner`,
  BACKGROUND_TYPE_FULL: `Vollständige Seite`,
  BACKGROUND_TYPE_LEFT: `Linke Seite`,
  BACKGROUND_TYPE_RIGHT: `Rechte Seite`,
  BACKGROUND_COLOR: `Hintergrundfarbe`,
  MAIN_COLOR: `Hauptfarbe`,
  FONT_COLOR: `Schriftfarbe`,
  CUSTOM_CSS: `Benutzerdefiniertes CSS`,
  CUSTOM_CSS_PLACEHOLDER: `Sie können benutzerdefinierten CSS-Code hinzufügen`,
  FOOTER_HTML: `Fußzeile HTML`,
  FOOTER_HTML_PLACEHOLDER: `Sie können HTML oder einfachen Text in der Fußzeile hinzufügen`,
  RESET_DEFAULT_STYLES: `Auf Standardstile zurücksetzen`,
  STEP_JOURNEY: `Reise`,
  STEP_BOOKING_PAGE: `Buchungsseite`,
  STEP_BOOKING_FORM: `Buchungsformular`,
  STEP_BOOKED: `Gebucht`,
  STEP_RESCHEDULED: `Verschoben`,
  STEP_CANCELED: `Abgesagt`,
  STEP_CANCEL: `Stornieren`,
});

export const BookingPageCalendarModal = Object.freeze({
  TITLE: `Kalendereinstellungen`,
  TIME_FORMAT: `Zeitformat`,
  HOUR_12: `12 Stunden`,
  HOUR_12_EXAMPLE: `1:00 pm`,
  HOUR_24: `24 Stunden`,
  HOUR_24_EXAMPLE: `13:00`,
  START_TIME_INTERVALS: `Zeitintervalle`,
  START_TIME_INTERVALS_DESCRIPTION: `Wählen Sie die Intervalllänge, die Sie bei der Terminplanung anzeigen möchten.`,
  START_TIME_INTERVALS_TOOLTIP: `Wählen Sie die Dauer der Intervalle, die Sie bei der Terminplanung anzeigen möchten. Standardmäßig sind 15 Minuten ausgewählt, um die Slot-Verfügbarkeit zu maximieren. Diese Option zeigt verfügbare Slots in diesem Intervall an.`,
  INTERVALS_15_MIN: `15 min`,
  INTERVALS_20_MIN: `20 min`,
  INTERVALS_30_MIN: `30 min`,
  INTERVALS_60_MIN: `60 min`,
  TOP_OF_THE_INTERVAL: `Am Anfang des Intervalls starten`,
  TOP_OF_THE_INTERVAL_DESCRIPTION: `Aktivieren Sie dies, um alle Termine am Anfang jedes Intervalls zu starten (z. B. 8 Uhr, 9 Uhr).`,
  TOP_OF_THE_INTERVAL_TOOLTIP: `Aktivieren Sie "Am Anfang des Intervalls", wenn alle Termine immer am Anfang des Intervalls beginnen sollen, unabhängig von der Dauer der Buchungsvorlage. Beispielsweise wären Slot-Dauern um 8:00 Uhr, 9:00 Uhr, 10:00 Uhr usw. verfügbar. HINWEIS: Wenn Sie diese Funktion aktivieren, kann der Benutzer die Verfügbarkeit nicht maximieren.`,
  TIME_ZONES: `Zeitzonen`,
  TIME_ZONES_TOOLTIP: `Die folgenden Zeitzonen werden auf der kundenseitigen Buchungsseite verfügbar sein. Wenn Sie nur eine Zeitzone auswählen, wird sie festgelegt, sodass das Zeitzonenfeld ausgegraut wird und der Benutzer sie nicht ändern kann.`,
  TIME_ZONE_DETECT: `Erkennen Sie automatisch und zeigen Sie Zeitfenster in der Zeitzone des Eingeladenen an.`,
  TIME_ZONE_DISPLAY: `Zeigen Sie nur die folgenden Zeitzonen an (ideal für persönliche Veranstaltungen)`,
  DEFAULT_TIME_ZONE: `Standard-Zeitzone`,
});

export const BookingPageAddToWebsite = Object.freeze({
  BOTTOM_LEFT: `Unten links`,
  BOTTOM_RIGHT: `Unten rechts`,
  BUTTON_POPUP_TITLE: `Schaltfläche`,
  BUTTON_POPUP_LABEL: `Ein Meeting planen`,
  COPY_CODE_EMBED_DESCRIPTION: `Betten Sie Ihr SUMO-Widget in Ihre Website ein, indem Sie den HTML-Code an der gewünschten Stelle einfügen.`,
  COPY_CODE_LINK_DESCRIPTION: `Zeigen Sie eine schwebende Buchungsschaltfläche auf den gewünschten Seiten an, indem Sie diesen Code in Ihrem HTML platzieren.`,
  COPY_CODE_POPUP_DESCRIPTION: `Zeigen Sie Ihren Buchungslink überall an, indem Sie diesen HTML-Code in Ihre Seiten, E-Mail-Signaturen und mehr einfügen.`,
  COPY_CODE_TITLE: `Code kopieren`,
  CUSTOMIZE_DESCRIPTION: `Passen Sie das Erscheinungsbild an, um sich nahtlos in Ihre Website einzufügen.`,
  CUSTOMIZE_TITLE: `Anpassen`,
  EMBED_DESCRIPTION: `Zeigen Sie Ihre SUMO1-Buchungsseite in einem iFrame auf Ihrer Website an.`,
  EMBED_TITLE: `Einbetten`,
  FLOATING_BUTTON_DESCRIPTION: `Schwebende Schaltfläche`,
  HIDE_PAGE_DETAILS: `Seiteninformationen ausblenden`,
  HIDE_COOKIE_BANNER: `Cookie-Banner ausblenden`,
  LABEL: `Beschriftung`,
  LINK_DESCRIPTION: `Fügen Sie Ihrer Website einen Textlink hinzu, der Ihre SUMO1-Buchungsseite in einem Popup öffnet.`,
  LINK_TITLE: `Textlink`,
  POSITION: `Position`,
  POPUP_DESCRIPTION: `Zeigen Sie Ihre SUMO1-Buchungsseite in einem Popup an, wenn eine Schaltfläche geklickt wird.`,
  POPUP_TITLE: `Popup`,
  TITLE: `Wie fügen Sie SUMO zu Ihrer Website hinzu?`,
  TOP_LEFT: `Oben links`,
  TOP_RIGHT: `Oben rechts`,
});

export const BookingPagesOverrideModal = Object.freeze({
  TITLE: `Überschreibung bestätigen`,
  DESCRIPTION: `Wenn Sie die Einstellungen einer Buchungsvorlage überschreiben, verwenden Sie diese Buchungsvorlage technisch gesehen nicht mehr und erstellen stattdessen eine benutzerdefinierte Buchungsseite, die einen Namen erfordert.`,
});
