import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import objectSupport from 'dayjs/plugin/objectSupport';
import { TimeFromToInput, UserSettingsInput } from '../API';
import { DateFormat, TimeFormat } from '../store/userSettings';
import { DateTimeFromToPartial } from '../types/types';
import i18n from '../i18n/i18n';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);

require('dayjs/locale/es');
require('dayjs/locale/de');
require('dayjs/locale/fr');
require('dayjs/locale/pt');
require('dayjs/locale/es');

export const formatDateUTC = (dateString: DateTimeFromToPartial, format: Partial<UserSettingsInput['dateFormat']>) => {
  // Example: 2023-10-05T14:48:00.000Z -> 10/05/2023
  if (dateString && format && Object.values(DateFormat).includes(format as DateFormat)) {
    return dayjs(dateString).utc().format(format);
  } else {
    return dayjs(dateString).utc().format(DateFormat.default);
  }
};

export const isValidDate = (d: DateTimeFromToPartial): d is string => {
  // Example: '2023-10-05' -> true
  return d !== null && d !== undefined && !isNaN(Date.parse(d));
};

export const formatEventDateTime = (start: string, end: string, timezone: string, hour12 = false) => {
  // Example: start: '2023-10-05T14:48:00.000Z', end: '2023-10-05T16:48:00.000Z', timezone: 'America/New_York', hour12: true
  // -> '10:48 AM - 12:48 PM, Thursday, October 5, 2023'
  const formattedStartTime = dayjs(start)
    .tz(timezone)
    .format(hour12 ? 'hh:mm A' : 'HH:mm');
  const formattedEndTime = dayjs(end)
    .tz(timezone)
    .format(hour12 ? 'hh:mm A' : 'HH:mm');
  const formattedDate = dayjs(start).tz(timezone).format('dddd, MMMM D, YYYY');
  return `${formattedStartTime} - ${formattedEndTime}, ${formattedDate}`;
};

export const formatEventDate = (start: string | Date, timezone: string) => {
  // Example: start: '2023-10-05T14:48:00.000Z', timezone: 'America/New_York', hour12: true
  // -> '10:48 AM - 12:48 PM, Thursday, October 5, 2023'
  return dayjs(start).tz(timezone).format('ddd, MMMM D, YYYY');
};

export const formatEventTime = (start: string | Date, end: string | Date, timezone: string, hour12 = false) => {
  // Example: start: '2023-10-05T14:48:00.000Z', end: '2023-10-05T16:48:00.000Z', timezone: 'America/New_York', hour12: true
  // -> '10:48 AM - 12:48 PM'
  const formattedStartTime = dayjs(start)
    .tz(timezone)
    .format(hour12 ? 'hh:mm A' : 'HH:mm');
  const formattedEndTime = dayjs(end)
    .tz(timezone)
    .format(hour12 ? 'hh:mm A' : 'HH:mm');
  return `${formattedStartTime} - ${formattedEndTime}`;
};

export const formatDateHHMM = (date: Date | string | number, timeFormat: string): string => {
  // Example: date: '2023-10-05T14:48:00.000Z', timeFormat: '12h' -> '02:48 PM'
  const format = timeFormat === TimeFormat.default ? 'hh:mm A' : 'HH:mm';
  return dayjs(date).format(format);
};

export const formatTimeHHMM = (time: string, timeFormat: string): string => {
  // Example: time: '14:48', timeFormat: '12h' -> '02:48 PM'
  const format = timeFormat === TimeFormat.default ? 'hh:mm A' : 'HH:mm';
  const formattedTime = `1970-01-01T${time}`;
  return dayjs(formattedTime).format(format);
};

export const calcFromToTimeError = (timeFrom: string | undefined | null, timeTo: string | undefined | null) => {
  // Example: timeFrom: '14:48', timeTo: '13:00' -> true
  return (timeFrom || '00:00') >= (timeTo || '00:00');
};

export const checkTimeWrongOrder = (timesList: (TimeFromToInput | null)[]) =>
  // Example: timesList: [{from: '14:48', to: '13:00'}] -> true
  Boolean(timesList.find((time) => calcFromToTimeError(time?.from, time?.to)));

export const checkTimeOverlapping = (timesList: (TimeFromToInput | null)[]) => {
  // Example: timesList: [{from: '14:00', to: '15:00'}, {from: '14:30', to: '15:30'}] -> true
  let timeOverlapping = false;
  for (let i = 0; i < timesList.length - 1 && !timeOverlapping; i++) {
    const firstTime = timesList[i];
    if (!firstTime?.from || !firstTime?.to) {
      continue;
    }
    for (let j = i + 1; j < timesList.length; j++) {
      const secondTime = timesList[j];
      if (!secondTime?.from || !secondTime?.to) {
        continue;
      }
      if (
        (firstTime.from >= secondTime.from && firstTime.from < secondTime.to) ||
        (firstTime.to > secondTime.from && firstTime.to <= secondTime.to) ||
        (firstTime.from < secondTime.from && firstTime.to > secondTime.to)
      ) {
        timeOverlapping = true;
        break;
      }
    }
  }
  return timeOverlapping;
};

export const formatDateByThreeOptions = (date: Date, format: string) => {
  // Example: date: new Date('2023-10-05'), format: 'YYYY/MM/DD' -> '2023/10/05'
  switch (format) {
    case DateFormat.YYYYMMDD:
      return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
        .getDate()
        .toString()
        .padStart(2, '0')}`;
    case DateFormat.DDMMYYYY:
    case DateFormat.default:
    default:
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
        .getDate()
        .toString()
        .padStart(2, '0')}/${date.getFullYear()}`;
  }
};

export const formatDateTimeThroughATSymbol = (
  startTime: string,
  endTime: string,
  dateFormat: string,
  timeFormat: string
) => {
  // Example: startTime: '2023-10-05T14:48:00.000Z', endTime: '2023-10-05T16:48:00.000Z', dateFormat: 'MM/DD/YYYY', timeFormat: '12h'
  // -> '10/05/2023 @ 02:48pm - 04:48pm'
  const parseAndFormatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return formatDateByThreeOptions(date, dateFormat);
  };

  const parseAndFormatTime = (dateStr: string) => {
    const date = new Date(dateStr);
    let hour: number | string = date.getHours();
    const minute = date.getMinutes().toString().padStart(2, '0');
    let period = '';
    if (timeFormat === TimeFormat.military) {
      hour = hour.toString().padStart(2, '0');
    } else {
      period = hour >= 12 ? 'pm' : 'am';
      hour = hour % 12;
      hour = hour ? hour : 12; // the hour '0' should be '12'
    }
    return `${hour}:${minute}${period}`;
  };

  return `${parseAndFormatDate(startTime)} @ ${parseAndFormatTime(startTime)} - ${parseAndFormatTime(endTime)}`;
};

export const formatDateTimeThroughGap = (dateTime: string | Date | number, dateFormat?: string, timeFormat?: string) => {
  // Example: dateTime: '2023-10-05T14:48:00.000Z', dateFormat: 'MM/DD/YYYY', timeFormat: '12h' -> '10/05/2023 02:48 PM'
  return (
    dayjs(dateTime).format(dateFormat || DateFormat.default) +
    ' ' +
    formatDateHHMM(dateTime, timeFormat || TimeFormat.default)
  );
};

export const converLocalDateTimeObjectToDate = (dateTime: string) => {
  // Example: dateTime: '2023-10-05T14:48:00.000Z' -> '2023-10-05T10:48:00.000-04:00' (assuming local timezone is EDT)
  return dayjs(dateTime).local();
};

// Converts a date string in the format 'YYYY-MM-DD' to a Date object in the current timezone at 00:00:00.
export const converDateStringToCurrentTimezoneDate = (dateString: string) => {
  // Example: dateString: '2023-10-05' -> Date object representing '2023-10-05T00:00:00' in the current timezone
  const dateValues = dateString.split('-');
  return new Date(Number(dateValues[0]), Number(dateValues[1]) - 1, Number(dateValues[2]));
};

export const getDateByNumberOfDays = (expirationDate: Date, days: number) => {
  // Example: expirationDate: new Date('2023-10-05'), days: 5 -> Date object representing '2023-10-10'
  return dayjs(expirationDate).add(days, 'day');
};

export const convertUnixToFormattedDate = (unixTimestamp: number, dateFormat = 'MM/DD/YYYY', timeFormat = '12h') => {
  // Example: unixTimestamp: 1696512480 -> '10/05/2023 02:48 PM'
  const date = new Date(unixTimestamp.toString().length > 10 ? unixTimestamp : unixTimestamp * 1000);
  const formattedDate = dayjs(date).format(dateFormat);
  const formattedTime = dayjs(date).format(timeFormat === '12h' ? 'hh:mm A' : 'HH:mm');
  return `${formattedDate} ${formattedTime}`;
};

export const formatDateAvailabilityOverrides = (dateString: Partial<DateTimeFromToPartial | number>) => {
  // Example: 2023-10-05T14:48:00.000Z -> May 10, 2023
  return dayjs(dateString).utc().locale(i18n.language).format('MMM D, YYYY');
};

export const formatDateJourneyLastDate = (dateString: DateTimeFromToPartial) => {
  // Example: 2023-10-05T14:48:00.000Z -> 10 May 2023
  return dayjs(dateString).locale(i18n.language).format('D MMMM YYYY');
};

export const formatDateTimeJourney = (dateString: DateTimeFromToPartial, timeFormat = '12h') => {
  // Example: 2023-10-06T14:48:00.000Z -> June 10 2023, 2:48pm
  return dayjs(dateString)
    .locale(i18n.language)
    .format('MMMM D YYYY, ' + (timeFormat === '12h' ? 'hh:mma' : 'HH:mm'));
};

export const formatBookedMeetingDate = (dateString: DateTimeFromToPartial) => {
  // Example: 2023-10-06 -> Saturday June 10, 2023
  return dayjs(dateString).locale(i18n.language).format('dddd MMMM D, YYYY');
};

export const isToday = (dateString: DateTimeFromToPartial) =>
  dayjs().format('DD MM YYYY') === dayjs(dateString).format('DD MM YYYY');

export const formatScheduledChanges = (dateString: DateTimeFromToPartial) => {
  // Example: 2023-10-06 -> June 10, 2023
  return dayjs(dateString).locale(i18n.language).format('MMMM D, YYYY');
};
