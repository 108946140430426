export const Billing = Object.freeze({
  ACCOUNT_SUSPENDED_PART1: `Your account is suspended. You must`,
  ACCOUNT_SUSPENDED_PART2: `pay your outstanding invoice`,
  ACCOUNT_SUSPENDED_PART3: `to re-enable service.`,
  ADD_SEATS: `Add Seats`,
  ADD_SEATS_DESCRIPTION_PART_1: `Adding`,
  ADD_SEATS_DESCRIPTION_PART_2: `seat(s) will add`,
  ADD_SEATS_DESCRIPTION_PART_3: `to your`,
  ADD_SEATS_DESCRIPTION_PART_4: `bill, starting`,
  ADD_SEATS_PRORATED_PART_1: `For the remaining `,
  ADD_SEATS_PRORATED_PART_2: ` days until your next payment, your credit card will be charged a prorated amount of`,
  ADD_SEATS_PRORATED_PART_3: `today.`,
  AMOUNT: `Amount`,
  ANNUAL_TITLE: `Annual`,
  ANNUALLY_TITLE: `annually`,
  BILL_IS_LATE_PART_1: `Bill is currently`,
  BILL_IS_LATE_PART_2_MULTIPLE: `days late.`,
  BILL_IS_LATE_PART_2_SINGLE: `day late.`,
  BILLING_ADDRESS: `Billing Address`,
  BILLING_DETAILS_DESCRIPTION: `Manage your payment method and billing address`,
  BILLING_DETAILS_TITLE: `Billing Details`,
  BUY_LICENSES_MESSAGE: `Your bill will be`,
  BY_INVOICE: `By invoice`,
  CANCEL_SUBSCRIPTION: `Cancel subscription`,
  CANCEL_SUBSCRIPTION_MESSAGE: `Are you sure you would like to cancel subscription?`,
  CANCELLATION_TEXT: `Your subscription scheduled to be canceled on `,
  CANCELLATION_DESCRIPTION: `Are you sure you would like to cancel scheduled change?`,
  CHANGE: `Change`,
  CHANGE_SUBSCRIPTION: `Change Subscription`,
  CARD: `Credit Card`,
  CHANGE_TERM: `Change Term`,
  CHECKOUT: `Checkout`,
  DAYS: `Days`,
  DATE_ISSUE: `Date Issue`,
  DOWNLOAD: `Download`,
  EMAIL: `Email`,
  EXPIRE_MESSAGE_PART_1: `Bill is`,
  EXPIRE_MESSAGE_PART_2: `days late. Your org will be inoperable in`,
  EXPIRE_MESSAGE_PART_3: `days.`,
  INVOICE: `Invoice`,
  INVOICE_DESCRIPTION: `View and download`,
  INVOICE_TITLE: `Invoice History`,
  LICENSE_MESSAGE_PART_1: `You already have`,
  LICENSE_MESSAGE_PART_2: `licenses assigned to users. Please enter more`,
  LICENSE_MESSAGE_PART_3: ` or deactivate/delete users to free up licenses`,
  LICENSES_PLACEHOLDER: `Enter a number`,
  LICENSES_TITLE: `licenses`,
  LOADING_LABEL_1: `Connecting…`,
  LOADING_LABEL_2: `Adding Licenses`,
  MONTH_TITLE: `month`,
  MONTHLY_TITLE: `Monthly`,
  NEXT_INVOICE_DUE: `Next Invoice due`,
  NOTIFICATION_DESCRIPTION: `Receive email notifications for new invoices`,
  NOTIFICATION_TITLE: `Notifications`,
  NUMBER_OF_LICENSES: `# Licenses`,
  ORDER_SUMMARY: `Order Summary`,
  PAY_NOW_TITLE: `Pay Now`,
  PAYMENT_METHOD: `Payment Method`,
  PER_USER_MONTH: `per user / month`,
  PLAN_SILVER: `Silver`,
  PLAN_TRIAL: `Trial`,
  RECEIVE_NOTIFICATIONS: `Receive email notifications for new invoices`,
  REMOVE_SEATS: `Remove Seats`,
  REMOVE_SEATS_DESCRIPTION_PART_1: `Removing`,
  REMOVE_SEATS_DESCRIPTION_PART_2: `seat(s) will reduce your`,
  REMOVE_SEATS_DESCRIPTION_PART_3: `bill by`,
  REMOVE_SEATS_DESCRIPTION_PART_4: `starting`,
  REMOVE_SEATS_NOTE_PART1: `To remove more than`,
  REMOVE_SEATS_NOTE_PART2: `seat(s) you can deactivate or delete users to free up licenses.`,
  REACTIVATE: `Reactivate`,
  SCHEDULED_CHANGES: `Changes Scheduled for`,
  SCHEDULED_COLUMN_CHANGE_BY: `Change by`,
  SCHEDULED_COLUMN_DATE: `Request Date`,
  SCHEDULED_COLUMN_LABEL: `Order Change`,
  SCHEDULED_SEATS_CHANGE_PART_1: `Reduce`,
  SCHEDULED_SEATS_CHANGE_PART_2: `licenses`,
  SCHEDULED_TERM_CHANGE: `Term changed to`,
  SEATS: `Seats`,
  STATUS: `Status`,
  STATUS_DUE: `Due`,
  STATUS_PAID: `Paid`,
  SUBSCRIPTION: `Subscription`,
  TERM: `Term`,
  TERM_ANNUAL: `Annual`,
  TERM_MONTHLY: `Monthly`,
  TITLE: `Billing`,
  TRIAL_LICENSE_EXPIRED: `Your SUMO1 license has expired. To continue using SUMO1 without any interruptions, please purchase a new license.`,
  UNPAID_INVOICE_MESSAGE: `You have an unpaid invoice.`,
  USERS: `Users`,
  USERS_OF: `of`,
  YOUR_PLAN: `Your plan`,
  TAX: `Tax`,
  HAVE_UNPAID_INVOICE: `You have an unpaid invoice.`,
  VIEW_BILLING: `View Billing`,
  PAY_INVOICE: `Pay invoice`,
  PAY_BY_INVOICE_MIN_VALUE_ORDER_TOOLTIP: `To activate payment by invoice, your order must be at least`,
});

export const BillingToastsNotifications = Object.freeze({
  ADD_SEATS_ERROR_TOAST: `Could not add seats`,
  ADD_SEATS_SUCCESS_TOAST: `Please wait up to 1 minute before we receive your payment`,
  ADD_SEATS_SUCCESS_TOAST_TITLE: `Seats added.`,
  BUY_LICENSE_SUCCESS_TOAST: `Subscription created successfully`,
  CHANGE_TERM_ERROR_TOAST: `Could not change the term.`,
  CHANGE_TERM_SUCCESS_TOAST: `Term changed successfully.`,
  CHARGEBEE_PORTAL_CLOSE_TOAST: `Please wait up to 1 minute before we receive it if you have made any changes, then reopen the billing page again`,
  DELETE_CHANGE_ERROR_TOAST: `Could not delete scheduled change.`,
  DELETE_CHANGE_SUCCESS_TOAST: `Scheduled change deleted successfully.`,
  GET_BILLING_ERROR_TOAST: `ChargeBee Connection Error`,
  GET_ITEM_TIERS_ERROR_TOAST: `Could no get the item prices of licenses`,
  PAY_LATER_ERROR_TOAST: `Payment scheduled failed.`,
  REMOVE_SEATS_ERROR_TOAST: `Could not remove seats`,
  REMOVE_SEATS_SUCCESS_TOAST: `Changes scheduled`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_1: `Reduce`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_2: `seats`,
  CALCULATE_TAXES_ERROR_TOAST: `Error during tax calculation`,
  REACTIVATE_SUBSCRIPTION_SUCCESS_TOAST: `Subscription reactivated successfully`,
  REACTIVATE_SUBSCRIPTION_ERROR_TOAST: `Failed to activate subscription. Please update your payment method.`,
  PAYMENT_ERROR_TOAST: `There was a problem with the card during payment, please check the card.`,
  DELETE_PAYMENT_SOURCE_SUCCESS_TOAST: `Card has been successfully deleted.`,
  DELETE_PAYMENT_SOURCE_ERROR_TOAST: `Error when deleting a card.`,
  REPEAT_PAYMENT_SUCCESS_TOAST: `Invoice has been successfully paid.`,
  REPEAT_PAY_NO_PAYMENT_SOURCES_ERROR_TOAST: `You have no cards available for payment.`,
  REPEAT_PAY_INVOICE_NOT_COLLECTED_ERROR_TOAST: `Failed to pay one or more invoices.`,
  REPEAT_PAYMENT_ERROR_TOAST: `An error occurred during the payment attempt.`,
  UPDATE_PAYMENT_METHOD_SUCCESS_TOAST: `Payment method successfully changed.`,
  UPDATE_PAYMENT_METHOD_ERROR_TOAST: `Fail updated payment method.`,
});