import { useDispatch, useSelector } from 'react-redux';
import { bookingPagesActions, bookingPageSelectors } from '../../../../store/bookingPages';
import {
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  DEFAULT_INPUT_SMS_FIELD,
  NotificationTypes,
  smartAlertModalActions,
} from '../../../../store/bookingTemplates';
import labels from './labels';
import { NotificationItem } from '../notificationItem/NotificationItem';
import { Button } from 'primereact/button';
import { SmartAlertModal } from '../smartAlertModal/SmartAlertModal';
import { SmartAlertItem } from '../smartAlertItem/SmartAlertItem';
import { SmartAlertWarningModal } from '../smartAlertWarningModal/SmartAlertWarningModal';
import { useEffect, useState } from 'react';
import { smartAlertsSelectors } from '../../../../store/smartAlerts';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Personalize } from '../../../smartAlerts';
import {getNotificationFieldByNotificationType} from "../../../../store/bookingPages/utils";
import {MessageTemplateInput, NotificationsInput} from "../../../../API";

type NotificationStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
};

export const NotificationStep = ({ selectors, actions, isReadOnly }: NotificationStepProps) => {
  const dispatch = useDispatch();

  const notifications = useSelector(selectors.selectNotifications);
  const smartAlertInUse = useSelector(selectors.selectSmartAlerts);
  const phoneInputField = useSelector(selectors.selectPhoneInputFields);
  const IsPromptInviteCallType = useSelector(selectors.selectIsPromptInviteCallType);
  const smsReminderOptionEnabled = useSelector(selectors.selectSmsReminderOptionEnabled);
  const isFetchingSmartAlerts = useSelector(smartAlertsSelectors.selectIsFetching);
  const isSMSToInvite = useSelector(smartAlertsSelectors.selectIsThereSmartAlertSMSInvitee(smartAlertInUse));
  const isBookingPage = useSelector(selectors.selectIsBookingPageSelector);

  const [openedNotificationType, setOpenedNotificationType] = useState<NotificationTypes | null>(null);

  const handleAddSmartAlert = () => {
    dispatch(smartAlertModalActions.openModal({ isEdit: false }));
  };

  useEffect(() => {
    // if there is SMS remainder let's add required custom filed "Phone number"
    if ((isSMSToInvite || smsReminderOptionEnabled) && !phoneInputField) {
      dispatch(actions.addCustomField(DEFAULT_INPUT_SMS_FIELD));
    } else if (
      !isSMSToInvite &&
      !smsReminderOptionEnabled &&
      phoneInputField &&
      phoneInputField.id &&
      !IsPromptInviteCallType
    ) {
      dispatch(actions.removeCustomField(phoneInputField.id));
    }
  }, [isSMSToInvite, smsReminderOptionEnabled, phoneInputField]);

  const getTitle = () => {
    switch (openedNotificationType) {
      case NotificationTypes.CONFIRMATION:
        return labels.confirmationTitle;
      case NotificationTypes.RESCHEDULE:
        return labels.rescheduleTitle;
      case NotificationTypes.REMINDER:
        return labels.reminderTitle;
      case NotificationTypes.CANCELATION:
        return labels.cancelationTitle;
      case NotificationTypes.FOLLOW_UP:
        return labels.followUpTitle;
      case NotificationTypes.SMS:
        return labels.smsTitle;
    }
  };

  const getNotificationFieldName = () => {
    return getNotificationFieldByNotificationType(openedNotificationType) as keyof NotificationsInput;
  }

  const getNotificationValue = (type?: NotificationTypes): MessageTemplateInput => {
    let fieldName;

    if (type) {
      fieldName = getNotificationFieldByNotificationType(type) as keyof NotificationsInput;
    } else {
      fieldName = getNotificationFieldName();
    }

    if (!notifications) {
      return {};
    }
    return notifications[fieldName] as MessageTemplateInput;
  }

  const handleSave = (body: string, subject?: string, timing?: Array< number | null > | null) => {
    dispatch(actions.updateNotificationsStep({
      [getNotificationFieldName()]: {
        ...getNotificationValue(),
        body, subject,
        timing: timing || []
      }
    }));
    setOpenedNotificationType(null);
  }

  const handleEnabledChange = (type: NotificationTypes, enabled: boolean) => {
    dispatch(actions.updateNotificationsStep({
      [getNotificationFieldByNotificationType(type) as keyof NotificationsInput]: {
        ...getNotificationValue(type),
        enabled,
      }
    }));
  }

  const getNotificationItemElement = (type: NotificationTypes) => {
    return (
      <NotificationItem
          type={type}
          onOpen={() => {setOpenedNotificationType(type)}}
          isEnabled={getNotificationValue(type)?.enabled || false}
          disabled={isReadOnly}
          handleEnabledChange={(value) => handleEnabledChange(type, value)}
        />
    );
  };

  return (
    <div className="flex flex-column gap-28px">
      {getNotificationItemElement(NotificationTypes.CONFIRMATION)}
      {getNotificationItemElement(NotificationTypes.RESCHEDULE)}
      {getNotificationItemElement(NotificationTypes.REMINDER)}
      {getNotificationItemElement(NotificationTypes.CANCELATION)}
      {getNotificationItemElement(NotificationTypes.FOLLOW_UP)}

      <div className="flex flex-column gap-16px">
        <div className="border-top-1 border-heavy-20 mt-4px" />

        <div className="flex-left-center gap-6px bg-blue-soft border-radius-6px p-6px text-blue-main">
          <InformationCircleIcon className="icon-18px" />
          <div className="text-label-xs-reg">
            {labels.smsLabelPart1} - {labels.smsLabelPart2}
          </div>
        </div>

        {getNotificationItemElement(NotificationTypes.SMS)}
      </div>

      <div className="border-top-1 border-heavy-20 mt-4px" />

      <div className="flex-left-center gap-12px">
        <div className="text-label-s-med text-heavy-60">{labels.smartAlerts}</div>
        {isFetchingSmartAlerts && <ProgressSpinner className="w-32px h-32px mx-0 -my-10px" strokeWidth="5" />}
      </div>

      {smartAlertInUse.map((id) => (
        <div key={id}>
          <SmartAlertItem smartAlertId={id || ''} actions={actions} selectors={selectors} isReadOnly={isReadOnly} />
        </div>
      ))}
      <Button
        className="button-blue button-text-line w-fit"
        label={labels.addSmartAlert}
        text
        onClick={handleAddSmartAlert}
        disabled={isReadOnly}
      />

      <SmartAlertModal actions={actions} selectors={selectors} isReadOnly={isReadOnly} />
      <SmartAlertWarningModal isFromBookingPage={isBookingPage} />

      <Personalize
        visible={!!openedNotificationType}
        close={() => setOpenedNotificationType(null)}
        handleCancel={() => setOpenedNotificationType(null)}
        timing={getNotificationValue()?.timing}
        subject={getNotificationValue()?.subject || ''}
        body={getNotificationValue()?.body || ''}
        title={getTitle() || ''}
        handleSave={handleSave}
        isSMS={!!openedNotificationType && openedNotificationType === NotificationTypes.SMS}
        isReadOnly={isReadOnly}
        hasTiming={openedNotificationType ? [NotificationTypes.REMINDER, NotificationTypes.FOLLOW_UP, NotificationTypes.SMS].includes(openedNotificationType) : false}
        hasAddTimingButton={openedNotificationType !== NotificationTypes.FOLLOW_UP}
        isAfterEvent={openedNotificationType === NotificationTypes.FOLLOW_UP}
      />
    </div>
  );
};
