import { AppType, IntegrationType, WorkspaceIntegrationType } from '../../API';
import zoomLogo from '../../assets/images/ZoomLogo.svg';
import googleMeetLogo from '../../assets/images/GoogleMeetLogo.svg';
import microsoftTeamsLogo from '../../assets/images/MicrosoftTeamsLogo.svg';
import googleCalendarLogo from '../../assets/images/GoogleCalendarLogo.svg';
import microsoft365Logo from '../../assets/images/Microsoft365Logo.svg';
import hubspotLogo from '../../assets/images/HubspotLogo.svg';
import salesforceLogo from '../../assets/images/SalesforceLogo.svg';
import inboxLogo from '../../assets/images/sumoIcon.svg';
import googleAnalyticsLogo from '../../assets/images/GoogleAnalyticsLogo.svg';
import googleMapsLogo from '../../assets/images/GoogleMapsLogo.svg';

import { IntegrationFilter } from './types';
import { t } from '../../i18n/i18n';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';
import { ToastNotificationOptions } from '../notifications';

export const ZOOM_AUTH_URL = 'https://zoom.us/oauth/authorize';
export const HUBSPOT_AUTH_URL = 'https://app-eu1.hubspot.com/oauth/authorize';
export const SALESFORCE_AUTH_URL = 'https://login.salesforce.com/services/oauth2/authorize';

export const integrationLabels = {
  [IntegrationType.GOOGLE_CALENDAR]: t('IntegrationPage:GOOGLE_CALENDAR_TITLE'),
  [IntegrationType.GOOGLE_MEET]: t('IntegrationPage:GOOGLE_MEET_TITLE'),
  [IntegrationType.MICROSOFT_CALENDAR]: t('IntegrationPage:MICROSOFT_CALENDAR_TITLE'),
  [IntegrationType.MICROSOFT_TEAMS]: t('IntegrationPage:MICROSOFT_TEAMS_TITLE'),
  [IntegrationType.ZOOM]: t('IntegrationPage:ZOOM_TITLE'),
  [WorkspaceIntegrationType.HUBSPOT]: t('IntegrationPage:HUBSPOT_TITLE'),
  [WorkspaceIntegrationType.SALESFORCE]: t('IntegrationPage:SALESFORCE_TITLE'),
  [AppType.INBOX]: t('IntegrationPage:INBOX_TITLE'),
  [WorkspaceIntegrationType.GOOGLE_ANALYTICS]: t('IntegrationPage:GOOGLE_ANALYTICS_TITLE'),
  [WorkspaceIntegrationType.GOOGLE_MAPS]: t('IntegrationPage:GOOGLE_MAPS_TITLE'),
};

export const DisconnectDescriptions = {
  [IntegrationType.GOOGLE_CALENDAR]: t('ConnectIntegrationPage:DISCONNECT_GOOGLE_CALENDAR_DESCRIPTION'),
  [IntegrationType.GOOGLE_MEET]: t('ConnectIntegrationPage:DISCONNECT_GOOGLE_MEET_DESCRIPTION'),
  [IntegrationType.MICROSOFT_CALENDAR]: t('ConnectIntegrationPage:DISCONNECT_MICROSOFT_CALENDAR_DESCRIPTION'),
  [IntegrationType.MICROSOFT_TEAMS]: t('ConnectIntegrationPage:DISCONNECT_TEAMS_DESCRIPTION'),
  [IntegrationType.ZOOM]: t('ConnectIntegrationPage:DISCONNECT_ZOOM_DESCRIPTION'),
  [WorkspaceIntegrationType.HUBSPOT]: t('ConnectIntegrationPage:DISCONNECT_HUBSPOT_DESCRIPTION'),
  [WorkspaceIntegrationType.SALESFORCE]: t('ConnectIntegrationPage:DISCONNECT_SALESFORCE_DESCRIPTION'),
  [WorkspaceIntegrationType.GOOGLE_ANALYTICS]: t('ConnectIntegrationPage:DISCONNECT_GOOGLE_ANALYTICS_DESCRIPTION'),
  [WorkspaceIntegrationType.GOOGLE_MAPS]: t('ConnectIntegrationPage:DISCONNECT_GOOGLE_MAPS_DESCRIPTION'),
};

const integrationDescription = {
  [IntegrationType.GOOGLE_CALENDAR]: t('IntegrationPage:GOOGLE_CALENDAR_DESC'),
  [IntegrationType.GOOGLE_MEET]: t('IntegrationPage:GOOGLE_MEET_DESC'),
  [IntegrationType.MICROSOFT_CALENDAR]: t('IntegrationPage:MICROSOFT_CALENDAR_DESC'),
  [IntegrationType.MICROSOFT_TEAMS]: t('IntegrationPage:MICROSOFT_TEAMS_DESC'),
  [IntegrationType.ZOOM]: t('IntegrationPage:ZOOM_DESC'),
  [WorkspaceIntegrationType.HUBSPOT]: t('IntegrationPage:HUBSPOT_DESC'),
  [WorkspaceIntegrationType.SALESFORCE]: t('IntegrationPage:SALESFORCE_DESC'),
  [AppType.INBOX]: t('IntegrationPage:INBOX_DESC'),
  [WorkspaceIntegrationType.GOOGLE_ANALYTICS]: t('IntegrationPage:GOOGLE_ANALYTICS_DESC'),
  [WorkspaceIntegrationType.GOOGLE_MAPS]: t('IntegrationPage:GOOGLE_MAPS_DESC'),
};

export const integrationIcon = {
  [IntegrationType.GOOGLE_CALENDAR]: googleCalendarLogo,
  [IntegrationType.GOOGLE_MEET]: googleMeetLogo,
  [IntegrationType.MICROSOFT_CALENDAR]: microsoft365Logo,
  [IntegrationType.MICROSOFT_TEAMS]: microsoftTeamsLogo,
  [IntegrationType.ZOOM]: zoomLogo,
  [WorkspaceIntegrationType.HUBSPOT]: hubspotLogo,
  [WorkspaceIntegrationType.SALESFORCE]: salesforceLogo,
  [AppType.INBOX]: inboxLogo,
  [WorkspaceIntegrationType.GOOGLE_ANALYTICS]: googleAnalyticsLogo,
  [WorkspaceIntegrationType.GOOGLE_MAPS]: googleMapsLogo,
};

// Filter our list of Integrations from
// * SUMO1, because we don't need integrate SUMO1 to SUMO1 :),
// * Google and Microsoft contacts, because they should only be for SUMO Meeting Booker
// P.S. SUMO1 type was created to separate SUMO1 Events/Members from everyone else
export const PERSONAL_INTEGRATION_EXCLUSIONS: (IntegrationType | WorkspaceIntegrationType | AppType)[] = [
  IntegrationType.SUMO1,
  IntegrationType.GOOGLE_CONTACTS,
  IntegrationType.MICROSOFT_CONTACTS,
];

export const DEFAULT_INTEGRATION_STATE = [
  ...Object.values(IntegrationType),
  ...Object.values(WorkspaceIntegrationType),
  AppType.INBOX,
]
  .filter((integration) => !PERSONAL_INTEGRATION_EXCLUSIONS.includes(integration))
  .map((integration) => ({
    type: integration,
    label: integrationLabels[integration as keyof typeof integrationLabels],
    icon: integrationIcon[integration as keyof typeof integrationIcon],
    description: integrationDescription[integration as keyof typeof integrationDescription],
  }));

export const VIDEO_CONFERENCES = [IntegrationType.GOOGLE_MEET, IntegrationType.MICROSOFT_TEAMS, IntegrationType.ZOOM];

export const WORKSPACE_INTEGRATIONS = [
  WorkspaceIntegrationType.HUBSPOT,
  WorkspaceIntegrationType.SALESFORCE,
  WorkspaceIntegrationType.GOOGLE_ANALYTICS,
  WorkspaceIntegrationType.GOOGLE_MAPS,
];

export const INTEGRATION_CATEGORIES = {
  [IntegrationFilter.ALL]: '*',
  [IntegrationFilter.CALENDARS]: [IntegrationType.GOOGLE_CALENDAR, IntegrationType.MICROSOFT_CALENDAR],
  [IntegrationFilter.VIDEO_CONFERENCE]: VIDEO_CONFERENCES,
  [IntegrationFilter.WORKSPACE_INTEGRATIONS]: WORKSPACE_INTEGRATIONS,
  [IntegrationFilter.EXTENSIONS]: [], // [IntegrationFilter.EXTENSIONS]: [AppType.INBOX],
};

export const INTEGRATION_FILTER_OPTIONS = [
  { value: IntegrationFilter.ALL, label: t('IntegrationPage:FILTER_ALL') },
  { value: IntegrationFilter.CALENDARS, label: t('IntegrationPage:FILTER_CALENDARS') },
  { value: IntegrationFilter.VIDEO_CONFERENCE, label: t('IntegrationPage:FILTER_VIDEO_CONFERENCE') },
  { value: IntegrationFilter.WORKSPACE_INTEGRATIONS, label: t('IntegrationPage:FILTER_WORKSPACE_INTEGRATIONS') },
  { value: IntegrationFilter.EXTENSIONS, label: t('IntegrationPage:FILTER_EXTENSIONS') },
];

export const CONNECT_WORKSPACE_INTEGRATION_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('IntegrationToastsNotifications:CONNECT_WORKSPACE_INTEGRATION_SUCCESS_MESSAGE'),
});

export const CONNECT_WORKSPACE_INTEGRATION_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('IntegrationToastsNotifications:CONNECT_WORKSPACE_INTEGRATION_ERROR_MESSAGE'),
});

export const CONNECT_INTEGRATION_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('IntegrationToastsNotifications:CONNECT_INTEGRATION_ERROR_MESSAGE'),
});

export const INVALID_GOOGLE_MAP_API_KEY_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('IntegrationToastsNotifications:INVALID_GOOGLE_MAP_API_KEY'),
});
