import { CreateTeamInput } from '../../API';
import { GetTeamsResponse } from './types';
import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';
import { GetTeamsByTenantIdResponse } from '../../generated-sources/internal-api/models/GetTeamsByTenantIdResponse';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getTeams = async (workspaceId: string): Promise<GetTeamsResponse> => {
  return handleAPIRequest<GetTeamsResponse>(`${API_PUBLIC}/getTeamsByWorkspaceId`, { workspaceId });
};

export const saveTeam = async (team: CreateTeamInput): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/upsertTeam`, { team });
};

export const deleteTeam = async (id: string): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteTeam`, { id });
};

export const getTeamsByTenant = async (): Promise<GetTeamsByTenantIdResponse> => {
  return handleAPIRequest<GetTeamsByTenantIdResponse>(`${API_PUBLIC}/getTeamsByTenantId`, {});
};
