import { SelectItem } from 'primereact/selectitem';
import { createSelector } from 'reselect';
import { TeamMemberInput } from '../../API';
import labels from '../../pages/teams/labels';
import { isOldData } from '../../services/utils';
import { authenticationSelectors } from '../authentication';
import { globalSelectors } from '../global';
import { State } from '../rootStore';
import { workspacesSelectors } from '../workspaces';
import { DEFAULT_FILTER } from './constants';
import { UserResourceOption } from '../users/types';

const teamsState = (state: State) => state.teams;

const selectIsFetching = createSelector(teamsState, (teams) => teams.isFetching);
const selectError = createSelector(teamsState, (teams) => teams.error);
const selectTeams = createSelector(teamsState, (teams) => teams.teams);
const selectSelectedTeam = createSelector(teamsState, (teams) => teams.team);
const selectFilter = createSelector(teamsState, (state) => state.filter);
const selectSelectedTeams = createSelector(teamsState, (state) => state.selectedTeams);
const selectTeamsForTenant = createSelector(teamsState, (teams) => teams.teamsForTenant);

const selectLastLoadTime = createSelector(teamsState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectIsDefaultFilter = createSelector(selectFilter, (filter) => filter === DEFAULT_FILTER);
const selectFilterWorkspaces = createSelector(selectFilter, (filter) => filter.workspaceIds);
const selectFilterCreatedBy = createSelector(selectFilter, (filter) => filter.createdBy);
const selectFilterStatus = createSelector(selectFilter, (filter) => filter.active);
const selectIsFilterInUse = (userOptions: SelectItem[], statusOptions: SelectItem[]) =>
  createSelector(
    selectFilter,
    authenticationSelectors.selectWorkspaceId,
    (filter, workspaceId) =>
      filter.workspaceIds.length !== 1 ||
      filter.workspaceIds[0] !== workspaceId ||
      filter.createdBy.length < userOptions.length ||
      filter.active.length < statusOptions.length
  );

const selectFilteredTeams = createSelector(
  selectTeams,
  selectFilter,
  globalSelectors.selectLowercasedSearchString,
  (teams, filter, searchString) =>
    teams
      .filter((team) => {
        const byWorkspace =
          filter.workspaceIds?.includes(team.workspaceId || '') ||
          filter.workspaceIds.some((id) => team.workspacesForRead?.includes(id));
        const byCreatedBy = filter.createdBy?.includes(team.createdBy || '');
        const byStatus = filter.active.includes(team.active ? labels.active : labels.inactive);
        const bySearchString = Boolean(!searchString || team.name?.toLowerCase().includes(searchString));

        return byWorkspace && byCreatedBy && byStatus && bySearchString;
      })
      .sort((teamA, teamB) => (teamA.createdAt > teamB.createdAt ? 1 : -1))
);

const selectTeamId = createSelector(selectSelectedTeam, (team) => team.id);
const selectTeamName = createSelector(selectSelectedTeam, (team) => team.name);
const selectIsTeamActive = createSelector(selectSelectedTeam, (team) => team.active);
const selectMembers = createSelector(
  selectSelectedTeam,
  (team) => team.members?.filter((member): member is TeamMemberInput => member !== null) || []
);
const selectMembersIds = createSelector(selectMembers, (members) => members.map((member) => member?.userId || ''));
const selectWorkspaceId = createSelector(selectSelectedTeam, (team) => team.workspaceId || '');
const selectTeamWorkspace = createSelector(
  selectWorkspaceId,
  workspacesSelectors.selectWorkspacesById,
  (id, workspacesById) => workspacesById[id]
);
const selectWorkspacesForRead = createSelector(
  selectSelectedTeam,
  (team) => team.workspacesForRead?.filter((workspace): workspace is string => workspace !== null) || []
);
const selectSharedForReadTeam = createSelector(selectSelectedTeam, (team) => !!team.readOnly);

const selectIsExistingTeam = createSelector(selectSelectedTeam, (team) => Boolean(team.id));
const selectIsNameDuplicate = createSelector(selectTeamName, selectTeamId, selectTeams, (name, id, teams) =>
  teams.some((team) => team.name === name && team.id !== id)
);
const selectIsNameValid = createSelector(
  selectTeamName,
  selectIsNameDuplicate,
  (name, isDuplicate) => Boolean(name) && !isDuplicate
);
const selectTeamCreatedBy = createSelector(selectSelectedTeam, (team) => team.createdBy);

const selectTeamsByCurrentWorkspace = createSelector(
  authenticationSelectors.selectWorkspaceId,
  selectTeams,
  (workspaceId, teams) =>
    teams.filter((team) => team.workspaceId === workspaceId || team.workspacesForRead?.includes(workspaceId))
);
const selectTeamNamesById = createSelector(selectTeams, (teams) =>
  teams.reduce((namesById, team) => {
    if (team.id) namesById[team.id] = team.name || '';
    return namesById;
  }, {} as Record<string, string>)
);

const selectTeamNamesByUserIdForTenant = createSelector(selectTeamsForTenant, (teams) =>
  teams.reduce((namesByUserId, team) => {
    team.members?.forEach((member) => {
      const userId = member?.userId;
      const teamName = team.name;
      if (userId && teamName) {
        namesByUserId[userId] = namesByUserId[userId] ? `${namesByUserId[userId]}, ${teamName}` : teamName;
      }
    });
    return namesByUserId;
  }, {} as Record<string, string>)
);

const selectTeamNamesByIdForTenant = createSelector(selectTeamsForTenant, (teams) =>
  teams.reduce((namesById, team) => {
    if (team.id) namesById[team.id] = team.name || '';
    return namesById;
  }, {} as Record<string, string>)
);

const selectCreatedByFilterOptions = createSelector(selectTeams, (teams) => {
  const createdBy = new Set<string>();
  teams.forEach((team) => team.createdBy && createdBy.add(team.createdBy));
  return Array.from(createdBy).map((user) => ({ value: user, label: user }));
});

const selectIsDefaultCreatedByFilter = createSelector(
  selectCreatedByFilterOptions,
  selectFilterCreatedBy,
  (options, filter) => options.length === filter.length
);

const selectTeamsOptionsByWorkspaces = createSelector(
  selectTeamsForTenant,
  workspacesSelectors.selectWorkspacesById,
  (teams, workspacesById) =>
    teams.reduce((res, team) => {
      const workspaceId = team.workspaceId ?? '';

      let workspaceRecord = res.find((record) => record.workspaceId === workspaceId);

      if (!workspaceRecord) {
        workspaceRecord = {
          workspaceId,
          label: workspacesById[workspaceId]?.name ?? '',
          resource: [],
        };
        res.push(workspaceRecord);
      }

      workspaceRecord?.resource.push({
        id: team.id ?? '',
        name: team.name ?? '',
      });

      return res;
    }, [] as Array<UserResourceOption>)
);

export const teamsSelectors = {
  selectIsFetching,
  selectError,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectTeams,
  selectTeamsForTenant,
  selectSelectedTeam,
  selectSelectedTeams,
  selectLastLoadTime,
  selectIsDefaultFilter,
  selectFilterWorkspaces,
  selectFilterCreatedBy,
  selectFilterStatus,
  selectIsFilterInUse,
  selectFilteredTeams,

  selectTeamId,
  selectTeamName,
  selectIsTeamActive,
  selectMembers,
  selectMembersIds,
  selectWorkspaceId,
  selectTeamWorkspace,
  selectWorkspacesForRead,
  selectSharedForReadTeam,

  selectIsExistingTeam,
  selectIsNameDuplicate,
  selectIsNameValid,
  selectTeamCreatedBy,

  selectTeamsByCurrentWorkspace,
  selectTeamNamesById,
  selectTeamNamesByUserIdForTenant,
  selectTeamNamesByIdForTenant,
  selectCreatedByFilterOptions,
  selectIsDefaultCreatedByFilter,

  selectTeamsOptionsByWorkspaces,
};
