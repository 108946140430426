import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Path } from '../../../../routing';
import {
  bookingPageSelectors,
  confirmOverrideSmartAlertModalActions,
  confirmOverrideSmartAlertModalSelectors,
} from '../../../../store/bookingPages';
import { bookingTemplatesSelectors } from '../../../../store/bookingTemplates';
import { ModalName } from '../../../../store/modals';
import { AppDispatch } from '../../../../store/rootStore';
import { smartAlertsActions, smartAlertsSelectors, UpsertSmartTypeFrom } from '../../../../store/smartAlerts';
import { Modal } from '../../modal/Modal';
import { Option, Step } from './constants';
import labels from './labels';
import { ProgressSpinner } from 'primereact/progressspinner';

type SmartAlertWarningModalProps = {
  isFromBookingPage: boolean;
}

export const SmartAlertWarningModal = ({isFromBookingPage}: SmartAlertWarningModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const isBookingPagesFetching = useSelector(bookingPageSelectors.selectIsFetching);
  const isBookingTemplatesFetching = useSelector(bookingTemplatesSelectors.selectIsFetching);
  const smartAlertId = useSelector(smartAlertsSelectors.selectSmartAlertId);
  const smartAlertName = useSelector(smartAlertsSelectors.selectSmartAlertName);
  const bookingPage = useSelector(bookingPageSelectors.selectBookingPage);
  const bookingTemplate = useSelector(bookingTemplatesSelectors.selectBookingTemplate);
  const warningDescription = useSelector(smartAlertsSelectors.selectEditWarningDescription(smartAlertId));
  const bookingPages = useSelector(smartAlertsSelectors.selectBookingPagesWhereUsed(smartAlertId));
  const bookingTemplates = useSelector(smartAlertsSelectors.selectBookingTemplatesWhereUsed(smartAlertId));
  const payload = useSelector(confirmOverrideSmartAlertModalSelectors.selectModalRequest);

  const names: {
    [key in UpsertSmartTypeFrom]?: string | null | undefined;
  } = {
    [UpsertSmartTypeFrom.BOOKING_PAGE]: bookingPage?.what?.customName,
    [UpsertSmartTypeFrom.BOOKING_TEMPLATE]: bookingTemplate?.what?.customName,
  };
  const name = payload?.upsertType && payload.upsertType in names ? names[payload.upsertType] : undefined;
  const [option, setOption] = useState<Option>(Option.CREATE_NEW);
  const [step, setStep] = useState<Step>(Step.INIT);
  const [localName, setLocalName] = useState<string>();

  const handleCancel = () => {
    dispatch(smartAlertsActions.setDetachAlert(false));
    dispatch(confirmOverrideSmartAlertModalActions.closeModal());
    setOption(Option.CREATE_NEW);
    setStep(Step.INIT);
    setLocalName(`“${smartAlertName || ''}” ${labels.FOR} “${name || ''}”`);
  };

  const handleUpdateAll = () => {
    dispatch(smartAlertsActions.addToWithoutWarning(smartAlertId));
    dispatch(smartAlertsActions.setDetachAlert(false));
    dispatch(smartAlertsActions.upsertSmartTypeRequest(UpsertSmartTypeFrom.NONE));
    handleCancel();
  };

  const handleNext = () => {
    if (localName === undefined) setLocalName(`“${smartAlertName || ''}” ${labels.FOR} “${name || ''}”`);
    dispatch(smartAlertsActions.setDetachAlert(false));
    setStep(Step.SET_NAME);
  };

  const handleBack = () => {
    dispatch(smartAlertsActions.setDetachAlert(false));
    setStep(Step.INIT);
  };

  const handleSave = () => {
    if (!localName) return;
    if (payload?.upsertType) {
      dispatch(
        smartAlertsActions.removeFromSmartAlertAndCreateNew({
          smartAlertId,
          smartAlertName: localName,
          upsertType: payload.upsertType,
          ...(isFromBookingPage ? { bookingPageId: bookingPage.id } : { bookingTemplateId: bookingTemplate.id }),
        })
      );
    }
    handleCancel();
  };

  return (
    <Modal.Container
      name={ModalName.CONFIRM_OVERRIDE_SMART_ALERT_MODAL}
      closable={true}
      onClose={handleCancel}
      className="w-560px"
    >
      <Modal.Header>
        <div className="flex-left-center gap-8px">
          <div>{step === Step.INIT ? labels.warningTitle : `${labels.createNewTitle} “${name || ''}”`}</div>
          {(isBookingPagesFetching || isBookingTemplatesFetching) && (
            <div className="h-20px">
              <ProgressSpinner className="w-20px h-20px" strokeWidth="6" />
            </div>
          )}
        </div>
      </Modal.Header>
      {step === Step.INIT ? (
        <>
          <div className="mb-16px">{warningDescription}</div>
          <div className="max-h-200px mb-24px overflow-y-auto text-body-lg-reg">
            {bookingPages.length ? (
              <div className={classNames('flex flex-column gap-4px', bookingTemplates.length ? 'mb-16px' : null)}>
                <div className="text-label-s-med text-heavy-60">{labels.bookingPagesTitle}</div>
                {bookingPages.map((page) => (
                  <div key={page.id} className="text-body-lg-reg">
                    <Link to={Path.EditBookingPage.replace(':bookingPageId', page.id || '')} target="_blank">
                      {page.what?.customName || ''}
                    </Link>
                  </div>
                ))}
              </div>
            ) : null}
            {bookingTemplates.length ? (
              <div className="flex flex-column gap-4px">
                <div className="text-label-s-med text-heavy-60">{labels.bookingTemplatesTitle}</div>
                {bookingTemplates.map((template) => (
                  <div key={template.id} className="text-body-lg-reg">
                    <Link
                      to={Path.EditBookingTemplate.replace(':bookingTemplateId', template.id || '')}
                      target="_blank"
                    >
                      {template.what?.customName || ''}
                    </Link>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className="bg-heavy-10 w-full h-1px mb-24px" />
          <div>
            <div className="text-title-xs-med mb-16px">{labels.howToApplyChange}</div>
            <div className="flex flex-column gap-20px">
              <div className="flex gap-10px">
                <RadioButton
                  inputId={Option.CREATE_NEW}
                  checked={option === Option.CREATE_NEW}
                  onChange={() => setOption(Option.CREATE_NEW)}
                />
                <div className="flex flex-column gap-4px pt-2px">
                  <label htmlFor={Option.CREATE_NEW} className="text-title-xs-med cursor-pointer hover-text-heavy-80">
                    {labels.createNewTitle} “{name}”
                  </label>
                  <div className="text-heavy-60 text-body-s-reg">
                    {payload?.upsertType === UpsertSmartTypeFrom.BOOKING_PAGE
                      ? labels.createNewSubTitleForBookingPage
                      : labels.createNewSubTitleForBookingTemplate}
                  </div>
                </div>
              </div>
              <div className="flex gap-10px">
                <RadioButton
                  inputId={Option.UPDATE_ALL}
                  checked={option === Option.UPDATE_ALL}
                  onChange={() => setOption(Option.UPDATE_ALL)}
                />
                <div className="flex flex-column gap-4px pt-2px">
                  <label htmlFor={Option.UPDATE_ALL} className="text-title-xs-med cursor-pointer hover-text-heavy-80">
                    {labels.updateAllTitle}
                  </label>
                  <div className="text-heavy-60 text-body-s-reg">{labels.updateAllSubTitle}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mb-10px text-title-xs-med text-heavy-100">{labels.createNewLabelTitle}</div>
          <InputText
            className={classNames('w-full', !localName && 'p-invalid')}
            value={localName}
            onChange={(e) => setLocalName(e.target.value)}
            placeholder={labels.createNewInputPlaceholder}
          />
        </>
      )}
      <Modal.Buttons>
        {step === Step.INIT && (
          <>
            <Button
              onClick={option === Option.CREATE_NEW ? handleNext : handleUpdateAll}
              className="mr-6px w-120px flex-center"
              disabled={isBookingPagesFetching || isBookingTemplatesFetching}
            >
              {option === Option.CREATE_NEW ? labels.next : labels.update}
            </Button>
            <Button onClick={handleCancel} text>
              {labels.cancel}
            </Button>
          </>
        )}
        {step === Step.SET_NAME && (
          <>
            <Button onClick={handleSave} className="mr-6px w-120px flex-center">
              {labels.save}
            </Button>
            <Button onClick={handleBack} text>
              {labels.back}
            </Button>
          </>
        )}
      </Modal.Buttons>
    </Modal.Container>
  );
};
