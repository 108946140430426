import { Storage } from 'aws-amplify';
import { CreateUserDataInput, IntegrationInput } from '../../API';
import { appendPostfixToFileName } from '../../services/utils';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';

// getUserSettings we request through getUserDataById

export const saveUserSettings = async (input: CreateUserDataInput): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/updateUserData`, { data: input });
};

export const connectToZoom = async (code: string, redirectURI: string): Promise<IntegrationInput> => {
  return handleAPIRequest<IntegrationInput>(`${API_PUBLIC}/connectToZoom`, { code, redirectURI });
};

export const uploadPublicFile = async (file: File, path = ''): Promise<string> => {
  try {
    const result = await Storage.put(appendPostfixToFileName(path + file.name, '_' + Date.now()), file, {
      level: 'public',
      contentType: file.type,
    });
    return (await Storage.get(result.key, { level: 'public' })).split('?')[0]; //remove access-related attributes as /public/* files available for read for everyone
  } catch (error: unknown) {
    console.error('Redux userSettings service; Error uploading file: ', error);
    throw error;
  }
};

// export const uploadProtectedFile = async (file: File): Promise<string> => {
//   try {
//     const result = await Storage.put(appendPostfixToFileName(file.name, '_' + Date.now()), file, {
//       level: 'protected',
//       contentType: file.type,
//     });
//     return (await Storage.get(result.key, { level: 'protected' })).split('?')[0]; //remove access-related attributes as /protected/* files available for read for everyone
//   } catch (error: unknown) {
//     console.error('Redux userSettings service; Error uploading file: ', error);
//     throw error;
//   }
// };

// export const copyPublicFile = async (fileKey: string, copyFileKey: string): Promise<string> => {
//   try {
//     const result = await Storage.copy({ key: fileKey, level: 'public' }, { key: copyFileKey, level: 'public' });
//     return (await Storage.get(result.key, { level: 'public' })).split('?')[0]; //remove access-related attributes as /protected/* files available for read for everyone
//   } catch (error: unknown) {
//     console.error('Redux userSettings service; Error copying file: ', error);
//     throw error;
//   }
// };
