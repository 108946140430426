import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { UsersActionTypes, usersActions } from './actions';
import { updateUser, getUsers, inviteUsers } from './service';
import { authenticationSelectors } from '../authentication';
import { notificationsActions } from '../notifications';
import {
  DELETE_USER_ERROR_TOAST,
  DELETE_USER_SUCCESS_TOAST,
  DISABLE_USER_ERROR_TOAST,
  DISABLE_USER_SUCCESS_TOAST,
  EDIT_USER_ERROR_TOAST,
  EDIT_USER_SUCCESS_TOAST,
  ENABLE_USER_SUCCESS_TOAST,
  GET_USERS_ERROR_TOAST,
  INVITE_USERS_ERROR_TOAST,
  INVITE_USERS_SUCCESS_TOAST,
} from './constants';
import { confirmUsersModalActions, editUsersModalActions } from './modal';
import { AdminDataStatus, CreateAdminDataInput } from '../../API';
import { UserAdminDataFullRecord } from './types';
import { usersSelectors } from './selectors';
import { globalActions } from '../global';
import { handleServiceError } from '../utils/reduxUtils';
import { authenticationActions } from '../authentication';
import { userSettingsActions } from '../userSettings';
import { deleteUser } from '../global/services';
import { teamsSaga } from '../teams/sagas';
import { bookingTemplatesSagas } from '../bookingTemplates/sagas';
import { teamsActions } from '../teams';
import { bookingTemplatesActions } from '../bookingTemplates';

function* getUsersSaga() {
  try {
    const tenantId: string = yield select(authenticationSelectors.selectTenantId);
    const response: { users: UserAdminDataFullRecord[] } = yield call(getUsers, tenantId);

    if (response.users) {
      yield put(usersActions.getUsersSuccess(response.users));
    } else {
      throw new Error('AdminUserData not found');
    }
  } catch (error: unknown) {
    yield put(usersActions.getUsersFail(error?.toString()));
    yield call(handleServiceError, error, GET_USERS_ERROR_TOAST, true);
  }
}

function* inviteUsersSaga(action: ReturnType<typeof usersActions.inviteUsersRequest>) {
  try {
    if (action.type === UsersActionTypes.INVITE_USERS_REQUEST) {
      const tenantId: string = yield select(authenticationSelectors.selectTenantId);
      const data: CreateAdminDataInput[] = action.payload.data.map((record) => ({ ...record, tenantId }));
      yield call(inviteUsers, data, action.payload.expirationInviteDays);

      yield put(usersActions.inviteUsersSuccess());
      // yield put(inviteUsersModalActions.closeModal()); // comment because we show loading in inviteUsersButton now
      yield put(notificationsActions.showToast(INVITE_USERS_SUCCESS_TOAST));
      yield put(usersActions.getUsersPageDataRequest());
      yield put(authenticationActions.getLicenseRequest());
    }
  } catch (error: unknown) {
    yield put(usersActions.inviteUsersFail(error?.toString()));
    yield call(handleServiceError, error, INVITE_USERS_ERROR_TOAST);
  }
}

function* updateUserResources(userId: string) {
  const assignedTeams: (string | null)[] = yield select(usersSelectors.selectAssignedTeams);
  const assignedTemplates: (string | null)[] = yield select(usersSelectors.selectAssignedTemplates);
  const requests = [
    call(teamsSaga.updateUserTeams, userId, assignedTeams),
    call(bookingTemplatesSagas.updateUserTemplates, userId, assignedTemplates),
  ];
  yield all(requests);
}

function* editUserSaga(action: ReturnType<typeof usersActions.editUserRecordRequest>) {
  try {
    if (action.type === UsersActionTypes.EDIT_USER_RECORD_REQUEST) {
      const userId: string = yield select(authenticationSelectors.selectUserId);
      const userRecord: UserAdminDataFullRecord = yield select(usersSelectors.selectSelectedUser);
      yield call(updateUser, userRecord);

      const isResoureUpdate = userRecord.status !== AdminDataStatus.INVITE_SENT && userRecord.userId;

      if (isResoureUpdate) {
        yield call(updateUserResources, userRecord.userId || '');
      }

      if (userRecord.userId === userId) {
        // update current user profile info
        yield put(
          userSettingsActions.updateUserSettings({
            fullName: userRecord.fullName,
            countryCode: userRecord.countryCode,
            phoneNumber: userRecord.phoneNumber,
          })
        );
      }

      yield put(usersActions.editUserRecordSuccess());

      if (action.roleChanged) {
        yield put(globalActions.getMainDataRequest());
      } else {
        yield put(usersActions.getUsersPageDataRequest());
      }

      if (isResoureUpdate) {
        yield put(teamsActions.getTeamsByTenantRequest());
        yield put(bookingTemplatesActions.getBookingTemplatesByTenantRequest());
      }

      yield put(editUsersModalActions.closeModal());
      yield put(notificationsActions.showToast(EDIT_USER_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(usersActions.editUserRecordFail(error?.toString()));
    yield call(handleServiceError, error, EDIT_USER_ERROR_TOAST);
  }
}

function* disableUserSage() {
  try {
    const userRecord: UserAdminDataFullRecord = yield select(usersSelectors.selectSelectedUser);
    yield call(updateUser, userRecord);

    yield put(usersActions.disableUserRecordSuccess());
    const user = userRecord.fullName || userRecord.email;
    if (userRecord.status === AdminDataStatus.ACTIVE) {
      yield put(
        notificationsActions.showToast({
          ...ENABLE_USER_SUCCESS_TOAST,
          message: '"' + user + '" ' + ENABLE_USER_SUCCESS_TOAST.message,
        })
      );
    } else {
      yield put(confirmUsersModalActions.closeModal());
      yield put(
        notificationsActions.showToast({
          ...DISABLE_USER_SUCCESS_TOAST,
          message: '"' + user + '" ' + DISABLE_USER_SUCCESS_TOAST.message,
        })
      );
    }

    yield put(usersActions.getUsersPageDataRequest());
    yield put(authenticationActions.getLicenseRequest());
  } catch (error: unknown) {
    yield put(usersActions.disableUserRecordFail(error?.toString()));
    yield call(handleServiceError, error, DISABLE_USER_ERROR_TOAST);
  }
}

function* deleteUserSage() {
  try {
    const user: UserAdminDataFullRecord = yield select(usersSelectors.selectSelectedUser);
    yield call(deleteUser, user.email);

    yield put(usersActions.deleteUserRecordSuccess());
    yield put(confirmUsersModalActions.closeModal());
    yield put(
      notificationsActions.showToast({
        ...DELETE_USER_SUCCESS_TOAST,
        message: '"' + (user.fullName ? user.fullName : user.email) + '" ' + DELETE_USER_SUCCESS_TOAST.message,
      })
    );

    yield put(usersActions.getUsersPageDataRequest());
    yield put(authenticationActions.getLicenseRequest());
  } catch (error: unknown) {
    yield put(usersActions.deleteUserRecordFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_USER_ERROR_TOAST);
  }
}

export function* watchUsersSaga() {
  yield takeLatest(UsersActionTypes.GET_USERS_REQUEST, getUsersSaga);
  yield takeLatest(UsersActionTypes.GET_USERS_PAGE_DATA_REQUEST, getUsersSaga); // TODO: remove GET_USERS_PAGE_DATA_REQUEST because it's copy GET_USERS_REQUEST
  yield takeLatest(UsersActionTypes.INVITE_USERS_REQUEST, inviteUsersSaga);
  yield takeLatest(UsersActionTypes.EDIT_USER_RECORD_REQUEST, editUserSaga);
  yield takeLatest(UsersActionTypes.DISABLE_USER_RECORD_REQUEST, disableUserSage);
  yield takeLatest(UsersActionTypes.DELETE_USER_RECORD_REQUEST, deleteUserSage);
}

export const usersSaga = {
  getUsers: getUsersSaga,
};
