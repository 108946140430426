import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { billingActions, billingSelectors, getCurrencyLabelByCode, SubscriptionInvoice } from '../../../store/billing';
import { userSettingsSelectors } from '../../../store/userSettings';
import { SumoTable } from '../../common';
import labels from './labels';
import { Column } from 'primereact/column';
import { convertUnixToFormattedDate } from '../../../services/DateService';
import { ArrowDownTrayIcon, DocumentCheckIcon } from '@heroicons/react/24/outline';

export const BillingInvoices = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(billingSelectors.selectIsFetching);
  const invoices = useSelector(billingSelectors.selectInvoices);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat);

  const [downloadedIds, setDownloadedIds] = useState<string[]>([]);

  const handleDownloadInvoice = (id: string) => {
    dispatch(billingActions.downloadRequest(id));
    setDownloadedIds([...downloadedIds, id]);
  };

  return (
    <SumoTable size="large" className="-mx-20px" value={invoices} loading={isFetching}>
      <Column
        header={labels.invoice}
        bodyClassName="text-overflow-ellipsis"
        body={(data: SubscriptionInvoice) => data.line_items?.[0].description}
      />
      <Column
        header={labels.amount}
        bodyClassName="text-overflow-ellipsis w-60px"
        body={(data: SubscriptionInvoice) =>
          `${getCurrencyLabelByCode(data.currency_code)}${Number(data.total) / 100}`
        }
      />
      <Column
        header={labels.date}
        bodyClassName="text-overflow-ellipsis"
        body={(data: SubscriptionInvoice) =>
          data?.date && convertUnixToFormattedDate(data.date, dateFormat || '', timeFormat || '')
        }
      />
      <Column
        header={labels.status}
        bodyClassName="text-overflow-ellipsis text-label-s-med w-80px"
        body={(data: SubscriptionInvoice) =>
          data?.status === 'paid' ? (
            <div className="w-fit py-6px px-8px border-radius-6px text-egg-blue-dark bg-egg-blue-light">
              {labels.statusPaid}
            </div>
          ) : (
            <div className="w-fit py-6px px-8px border-radius-6px text-saffron-main bg-saffron-light">
              {labels.statusDue}
            </div>
          )
        }
      />
      <Column
        header={labels.download}
        body={(data: SubscriptionInvoice) =>
          downloadedIds.includes(data.id) ? (
            <div className="flex p-8px">
              <DocumentCheckIcon className="icon-16px text-egg-blue-main" />
            </div>
          ) : (
            <Button className="action-button" onClick={() => handleDownloadInvoice(data.id || '')}>
              <ArrowDownTrayIcon className="icon-16px" />
            </Button>
          )
        }
        className="w-80px"
      />
    </SumoTable>
  );
};
