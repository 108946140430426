import { t } from '../../i18n/i18n';
import { CHARGEBEE_YEARLY_TERM, TIERS_DEFAULT_MONTHLY } from './constants';
import { ItemPrices, Tier } from './types';

export const getTiersByTerm = (itemPrices: ItemPrices[], term: string) => {
  return itemPrices.find((price) => price.id === term)?.tiers || TIERS_DEFAULT_MONTHLY;
};

export const getTotalAmount = (quantity: number, tiers: Tier[]) => {
  let totalAmount = 0;
  const remainingQuantity = quantity;

  for (const tier of tiers) {
    if (remainingQuantity === 0) break;

    const pricePerUser = tier.price / 100; // Convert price to dollars
    const startingUnit = tier.starting_unit;
    const endingUnit = tier.ending_unit || Infinity; // Assume no upper limit if ending_unit is not defined

    if (remainingQuantity >= startingUnit) {
      const applicableQuantity = Math.min(remainingQuantity, endingUnit) - startingUnit + 1;
      totalAmount += pricePerUser * Math.min(applicableQuantity, remainingQuantity);
      // remainingQuantity -= applicableQuantity;
    }
  }
  return totalAmount;
};

export const getCurrencyLabelByCode = (code?: string) => {
  switch (code) {
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'USD':
    default:
      return '$';
  }
};

export const getPriceBreakdownByTiers = (tiers: Tier[], term: string, quantity: number, currencyLabel: string) => {
  let remainingQuantity = quantity;
  return tiers.map((tier) => {
    const tierQuantity =
      !tier.ending_unit || (quantity || 0) <= tier.ending_unit
        ? remainingQuantity
        : tier.ending_unit - tier.starting_unit + 1;

    if (tierQuantity <= 0) return null;
    const tierPrice = tier.price / 100;
    const tierAmount = tierPrice * tierQuantity;

    remainingQuantity = remainingQuantity - tierQuantity; // Ensure remainingQuantity is not null by defaulting to 0
    return `${tierQuantity} ${t('Billing:LICENSES_TITLE')} X ${currencyLabel}${tierPrice} X ${
      term === CHARGEBEE_YEARLY_TERM ? 12 : 1
    } ${t('Billing:MONTH_TITLE')} = ${currencyLabel}${tierAmount}`;
  });
};

export const getPriceByQuantityTiersOneLicense = (itemPrices: ItemPrices[], term: string, quantity: number) => {
  const tiers = getTiersByTerm(itemPrices, term);
  let price = tiers[0].price;

  for (const tier of tiers) {
    if (quantity <= 0) break;

    if (!tier.ending_unit) {
      price = tier.price;
      break;
    }

    if (quantity >= tier.starting_unit && quantity <= tier.ending_unit) {
      price = tier.price;
      break;
    }
  }

  if (term === CHARGEBEE_YEARLY_TERM) {
    price = price / 12;
  }

  return price / 100;
};

export const calculateTieredPrice = (quantity: number, itemPrices: ItemPrices[], term: string, tax: number) => {
  let remainingSeats = quantity;
  let totalCost = 0;

  const termTiers = itemPrices.find((itemPrice) => itemPrice.id === term)?.tiers;

  termTiers?.forEach((tier) => {
    if (remainingSeats <= 0) return;

    const tierMax = tier.ending_unit || remainingSeats; // use tier's ending unit or remaining seats if it's the last tier
    const seatsInTier = Math.min(remainingSeats, tierMax - tier.starting_unit + 1); // calculate how many seats fall into the current tier

    totalCost += seatsInTier * (tier.price / 100); // add the cost for seats in the current tier
    remainingSeats -= seatsInTier; // subtract the number of seats allocated from the remaining seats
  });

  totalCost *= (1 + (tax || 0) / 100); 

  return +totalCost.toFixed(2);
};
