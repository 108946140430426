import { AdminData, IntegrationType, VideoConferenceType } from '../../API';
export type UserRecordTemplate = {
  email: string;
  role: string;
  team: string;
  bookingTemplateIds: string;
};

export enum UserStatus {
  InviteSent = 'Invite Sent',
  Active = 'Active',
  Inactive = 'Inactive',
}

export type UserAdminDataFullRecord = AdminData & {
  countryCode?: string;
  phoneNumber?: string;
  fullName?: string;
  avatar?: string;
  integrationTypes?: IntegrationType[];
  defaultVideoIntegration?: VideoConferenceType;
};

export type UserOption = {
  workspaceIds: Array<string | null>;
  value: string;
  label: string;
};

export type UsersGroupedByWorkspaceOption = {
  workspaceId: string;
  workspaceName: string;
  users: Array<{ value: string; label: string }>;
};

export type UserFilterType = {
  workspaceIds: string[];
  roles: string[];
  statuses: string[];
};

export type UserResources = {
  bookingTemplateIds: (string | null)[];
  team: (string | null)[];
};

export type UserResourceOption = {
  workspaceId: string;
  label: string;
  resource: Array<{ id: string; name: string }>;
};
