import { call, put, select, takeLatest } from 'redux-saga/effects';
import { signOut, deleteAndCreateUser, generateCookies, getLicenses, getTenantData } from './service';
import { AuthenticationActionTypes, authenticationActions } from './actions';
import {
  CHOOSE_AN_ACCOUNT_ERROR_TOAST,
  ENCRYPTED_USER_ID,
  GET_USER_AUTH_ERROR_TOAST,
  GET_USER_LOGOUT_ERROR_TOAST,
  SUMO1TenantId,
  THIRD_PARTY,
  USER_CONTEXT_PARAM,
} from './constants';
import { UserSettingsKeys } from '../userSettings';
import { authenticationSelectors } from './selectors';
import { CreateLicenseInput, CreateTenantInput, CreateUserDataInput, IntegrationType, UserRecordType } from '../../API';
import { getAuthResponse, handleAuthResponse } from './inviteSagas';
import { globalActions } from '../global';
import { DeleteAndCreateUserRequest, GenerateCookiesResponse, ThirdPartyLambdaResponse, ThirdPartyType } from './types';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { handleServiceError } from '../utils/reduxUtils';
import { VIEW_AS_USER_FAIL_TOAST } from '../opsConsole/staff';
import { getUserDataById } from '../global/services';

function* thirdPartyAuthSaga(action: ReturnType<typeof authenticationActions.thirdPartyAuthRequest>) {
  if (action.type === AuthenticationActionTypes.THIRD_PARTY_AUTH_REQUEST) {
    try {
      const authResponse: ThirdPartyLambdaResponse = yield call(getAuthResponse, action.payload);
      yield call(handleAuthResponse, authResponse, action.payload);
    } catch (error: unknown) {
      yield put(authenticationActions.thirdPartyAuthFail(error?.toString()));
      yield call(handleServiceError, error, GET_USER_AUTH_ERROR_TOAST);
    }
  }
}

function* chooseAccountSaga(action: ReturnType<typeof authenticationActions.chooseAnAccountRequest>) {
  try {
    if (action.type === AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_REQUEST) {
      const { email, tenantId } = action.payload;
      const input: DeleteAndCreateUserRequest = {
        email,
        newTenantId: tenantId,
      };
      yield call(deleteAndCreateUser, input);

      if (tenantId) {
        yield put(globalActions.getMainDataRequest()); // let's start authentication
        yield put(authenticationActions.chooseAnAccountSuccess());
      } else {
        throw new Error('tenantId in chosen account was not found');
      }
    }
  } catch (error: unknown) {
    yield call(handleServiceError, error, CHOOSE_AN_ACCOUNT_ERROR_TOAST);
    yield put(authenticationActions.chooseAnAccountFail(error?.toString()));
  }
}

function clearLocalStorage(isViewAsUser = false) {
  localStorage.removeItem(UserSettingsKeys.LINK);
  localStorage.removeItem(UserSettingsKeys.WORKSPACE_ID);
  localStorage.removeItem(THIRD_PARTY);
  localStorage.removeItem(ENCRYPTED_USER_ID);

  if (!isViewAsUser) {
    localStorage.removeItem(UserSettingsKeys.USER_ID);
    localStorage.removeItem(UserSettingsKeys.TENANT_ID);
    localStorage.removeItem(UserSettingsKeys.SUMO1_STAFF_DATA);
    localStorage.removeItem(UserSettingsKeys.QUICK_SETUP_WEEKLY_HOURS);
    localStorage.removeItem(USER_CONTEXT_PARAM);
  }
}

function* resetViewAsUserSaga(action: ReturnType<typeof authenticationActions.resetViewAsUserRequest>) {
  try {
    if (action.type === AuthenticationActionTypes.RESET_VIEW_AS_USER_REQUEST) {
      const staffUserId: string = yield select(authenticationSelectors.selectSumo1AdminUserId);
      // clear user items storage
      yield call(clearLocalStorage, true);

      // replace with staff data
      localStorage.setItem(UserSettingsKeys.USER_ID, staffUserId);
      localStorage.setItem(UserSettingsKeys.TENANT_ID, SUMO1TenantId);

      yield put(authenticationActions.resetViewAsUserSuccess());
      if (action.payload.redirect) {
        navigationService.navigateTo(Path.OPSConsoleOrgs);
      }

      // always reload the page to process closed tab scenario
      window.location.reload();
    }
  } catch (error: unknown) {
    yield put(authenticationActions.resetViewAsUserFail(error?.toString()));
  }
}

function* logoutUserSaga(action: ReturnType<typeof authenticationActions.logoutUserRequest>) {
  try {
    if (action.type === AuthenticationActionTypes.LOGOUT_USER_REQUEST) {
      const isViewAsUserMode: boolean = yield select(authenticationSelectors.selectIsViewAsUser);
      if (isViewAsUserMode) {
        yield put(authenticationActions.resetViewAsUserRequest({}));
      } else {
        yield call(clearLocalStorage);
        navigationService.navigateTo(action.redirectTo);
        yield put(globalActions.resetWholeStore());
        yield put(authenticationActions.logoutUserSuccess());
        yield call(signOut);
      }
    }
  } catch (error: unknown) {
    yield put(authenticationActions.logoutUserFail(error?.toString()));
    yield call(handleServiceError, error, GET_USER_LOGOUT_ERROR_TOAST);
  }
}

// TODO: move it to Billing saga
function* getLicenseSaga() {
  try {
    const tenantId: string = yield select(authenticationSelectors.selectTenantId);
    const licenses: CreateLicenseInput[] = yield call(getLicenses, tenantId);

    yield put(authenticationActions.getLicenseSuccess(licenses));
  } catch (error: unknown) {
    yield put(authenticationActions.getLicenseFail(error?.toString()));
  }
}

function* getTenantSaga() {
  try {
    const tenantId: string = yield select(authenticationSelectors.selectTenantId);
    const tenant: CreateTenantInput = yield call(getTenantData, tenantId);
    yield put(authenticationActions.getTenantSuccess(tenant));
  } catch (error: unknown) {
    yield put(authenticationActions.getTenantFail(error?.toString()));
  }
}

function* viewAsUserSaga(action: ReturnType<typeof authenticationActions.viewAsUserRequest>) {
  try {
    if (action.type === AuthenticationActionTypes.VIEW_AS_USER_REQUEST) {
      const { tenantId, userId } = action.payload;
      localStorage.setItem(UserSettingsKeys.TENANT_ID, tenantId);
      localStorage.removeItem(UserSettingsKeys.WORKSPACE_ID);
      localStorage.setItem(UserSettingsKeys.USER_ID, userId);
      yield put(authenticationActions.updateUserDataCore({ userId, tenantId }));

      const generateCookiesResponse: GenerateCookiesResponse = yield call(generateCookies, userId);
      localStorage.setItem(ENCRYPTED_USER_ID, generateCookiesResponse.encryptedUserId);

      const userDataResponse: CreateUserDataInput[] = yield call(getUserDataById);
      const profileRecord = userDataResponse.find((record) => record.recordType === UserRecordType.PROFILE);

      if (profileRecord && profileRecord.userSettings) {
        const link = profileRecord.link;
        localStorage.setItem(UserSettingsKeys.LINK, link);
        yield put(authenticationActions.updateUserDataCore({ link }));

        const userSettings = profileRecord.userSettings;
        const integrations = userSettings.integrations || [];
        const isGoogleIntegrated = integrations.some(
          (integration) =>
            integration?.type === IntegrationType.GOOGLE_CALENDAR || integration?.type === IntegrationType.GOOGLE_MEET
        );
        const thirdParty = isGoogleIntegrated ? ThirdPartyType.GOOGLE : ThirdPartyType.MICROSOFT;
        yield put(authenticationActions.setThirdParty(thirdParty));
        localStorage.setItem(THIRD_PARTY, thirdParty);

        if (userSettings.isQuickSetupFinished) {
          navigationService.navigateTo(Path.BookingPages);
        } else {
          navigationService.navigateTo(Path.QuickSetup);
        }

        yield put(authenticationActions.viewAsUserSuccess());
      } else {
        throw new Error('profileRecord not found');
      }
    }
  } catch (error: unknown) {
    yield put(authenticationActions.viewAsUseFail(error?.toString()));
    yield call(handleServiceError, error, VIEW_AS_USER_FAIL_TOAST, true);
  }
}

export function* watchAuthenticationSaga() {
  yield takeLatest(AuthenticationActionTypes.THIRD_PARTY_AUTH_REQUEST, thirdPartyAuthSaga);
  yield takeLatest(AuthenticationActionTypes.LOGOUT_USER_REQUEST, logoutUserSaga);
  yield takeLatest(AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_REQUEST, chooseAccountSaga);
  yield takeLatest(AuthenticationActionTypes.GET_TENANT_REQUEST, getTenantSaga);
  yield takeLatest(AuthenticationActionTypes.VIEW_AS_USER_REQUEST, viewAsUserSaga);
  yield takeLatest(AuthenticationActionTypes.RESET_VIEW_AS_USER_REQUEST, resetViewAsUserSaga);
  yield takeLatest(AuthenticationActionTypes.GET_LICENSE_REQUEST, getLicenseSaga);
}

export const authenticationSagas = {
  thirdPartyAuth: thirdPartyAuthSaga,
  getTenant: getTenantSaga,
  getLicense: getLicenseSaga,
};
