export const Billing = Object.freeze({
  ACCOUNT_SUSPENDED_PART1: `Votre compte est suspendu. Vous devez`,
  ACCOUNT_SUSPENDED_PART2: `payer votre facture en attente`,
  ACCOUNT_SUSPENDED_PART3: `pour réactiver le service.`,
  ADD_SEATS: `Ajouter des sièges`,
  ADD_SEATS_DESCRIPTION_PART_1: `Ajouter`,
  ADD_SEATS_DESCRIPTION_PART_2: `siège(s) ajoutera`,
  ADD_SEATS_DESCRIPTION_PART_3: `à votre`,
  ADD_SEATS_DESCRIPTION_PART_4: `facture, à partir de`,
  ADD_SEATS_PRORATED_PART_1: `Pour les jours restants`,
  ADD_SEATS_PRORATED_PART_2: `jusqu'à votre prochain paiement, votre carte de crédit sera débitée d'un montant au prorata de`,
  ADD_SEATS_PRORATED_PART_3: `aujourd'hui.`,
  AMOUNT: `Montant`,
  ANNUAL_TITLE: `Annuel`,
  ANNUALLY_TITLE: `annuellement`,
  BILL_IS_LATE_PART_1: `La facture est actuellement`,
  BILL_IS_LATE_PART_2_MULTIPLE: `jours de retard.`,
  BILL_IS_LATE_PART_2_SINGLE: `jour de retard.`,
  BILLING_ADDRESS: `Adresse de facturation`,
  BILLING_DETAILS_DESCRIPTION: `Gérez votre méthode de paiement et adresse de facturation`,
  BILLING_DETAILS_TITLE: `Détails de facturation`,
  BUY_LICENSES_MESSAGE: `Votre facture sera`,
  BY_INVOICE: `Par facture`,
  CANCEL_SUBSCRIPTION: `Annuler l'abonnement`,
  CANCEL_SUBSCRIPTION_MESSAGE: `Êtes-vous sûr de vouloir annuler l'abonnement ?`,
  CANCELLATION_TEXT: `Votre abonnement est prévu pour être annulé le`,
  CANCELLATION_DESCRIPTION: `Êtes-vous sûr de vouloir annuler le changement planifié ?`,
  CHANGE: `Modifier`,
  CHANGE_SUBSCRIPTION: `Modifier l'abonnement`,
  CARD: `Carte de Crédit`,
  CHANGE_TERM: `Modifier la période`,
  CHECKOUT: `Paiement`,
  DAYS: `Jours`,
  DATE_ISSUE: `Date d'émission`,
  DOWNLOAD: `Télécharger`,
  EMAIL: `E-mail`,
  EXPIRE_MESSAGE_PART_1: `La facture est`,
  EXPIRE_MESSAGE_PART_2: `jours de retard. Votre organisation sera inopérable dans`,
  EXPIRE_MESSAGE_PART_3: `jours.`,
  INVOICE: `Facture`,
  INVOICE_DESCRIPTION: `Voir et télécharger`,
  INVOICE_TITLE: `Historique des factures`,
  LICENSE_MESSAGE_PART_1: `Vous avez déjà`,
  LICENSE_MESSAGE_PART_2: `licences assignées aux utilisateurs. Veuillez entrer plus`,
  LICENSE_MESSAGE_PART_3: `ou désactiver/supprimer des utilisateurs pour libérer des licences`,
  LICENSES_PLACEHOLDER: `Entrez un nombre`,
  LICENSES_TITLE: `licences`,
  LOADING_LABEL_1: `Connexion…`,
  LOADING_LABEL_2: `Ajout de licences`,
  MONTH_TITLE: `mois`,
  MONTHLY_TITLE: `Mensuel`,
  NEXT_INVOICE_DUE: `Prochaine facture due`,
  NOTIFICATION_DESCRIPTION: `Recevoir des notifications par e-mail pour les nouvelles factures`,
  NOTIFICATION_TITLE: `Notifications`,
  NUMBER_OF_LICENSES: `# Licences`,
  ORDER_SUMMARY: `Résumé de la commande`,
  PAY_NOW_TITLE: `Payer maintenant`,
  PAYMENT_METHOD: `Méthode de paiement`,
  PER_USER_MONTH: `par utilisateur / mois`,
  PLAN_SILVER: `Argent`,
  PLAN_TRIAL: `Essai`,
  RECEIVE_NOTIFICATIONS: `Recevoir des notifications par e-mail pour les nouvelles factures`,
  REMOVE_SEATS: `Supprimer des sièges`,
  REMOVE_SEATS_DESCRIPTION_PART_1: `Supprimer`,
  REMOVE_SEATS_DESCRIPTION_PART_2: `siège(s) réduira votre`,
  REMOVE_SEATS_DESCRIPTION_PART_3: `facture de`,
  REMOVE_SEATS_DESCRIPTION_PART_4: `à partir de`,
  REMOVE_SEATS_NOTE_PART1: `Pour supprimer plus de`,
  REMOVE_SEATS_NOTE_PART2: `siège(s), vous pouvez désactiver ou supprimer des utilisateurs pour libérer des licences.`,
  REACTIVATE: `Réactiver`,
  SCHEDULED_CHANGES: `Modifications prévues pour`,
  SCHEDULED_COLUMN_CHANGE_BY: `Changer par`,
  SCHEDULED_COLUMN_DATE: `Date de demande`,
  SCHEDULED_COLUMN_LABEL: `Modification de commande`,
  SCHEDULED_SEATS_CHANGE_PART_1: `Réduire`,
  SCHEDULED_SEATS_CHANGE_PART_2: `licences`,
  SCHEDULED_TERM_CHANGE: `Période changée à`,
  SEATS: `Sièges`,
  STATUS: `Statut`,
  STATUS_DUE: `À payer`,
  STATUS_PAID: `Payé`,
  SUBSCRIPTION: `Abonnement`,
  TERM: `Période`,
  TERM_ANNUAL: `Annuel`,
  TERM_MONTHLY: `Mensuel`,
  TITLE: `Facturation`,
  TRIAL_LICENSE_EXPIRED: `Votre licence SUMO1 a expiré. Pour continuer à utiliser SUMO1 sans interruptions, veuillez acheter une nouvelle licence.`,
  UNPAID_INVOICE_MESSAGE: `Vous avez une facture impayée.`,
  USERS: `Utilisateurs`,
  USERS_OF: `de`,
  YOUR_PLAN: `Votre plan`,
  TAX: `Impôt`,
  HAVE_UNPAID_INVOICE: `Vous avez une facture impayée.`,
  VIEW_BILLING: `Voir la facturation`,
  PAY_INVOICE: `Payer la facture`,
  PAY_BY_INVOICE_MIN_VALUE_ORDER_TOOLTIP: `Pour activer le paiement par facture, votre commande doit être d'au moins`,
});

export const BillingToastsNotifications = Object.freeze({
  ADD_SEATS_ERROR_TOAST: `Impossible d'ajouter des sièges`,
  ADD_SEATS_SUCCESS_TOAST: `Veuillez patienter jusqu'à 1 minute avant que nous recevions votre paiement`,
  ADD_SEATS_SUCCESS_TOAST_TITLE: `Sièges ajoutés.`,
  BUY_LICENSE_SUCCESS_TOAST: `Abonnement créé avec succès`,
  CHANGE_TERM_ERROR_TOAST: `Impossible de changer le terme.`,
  CHANGE_TERM_SUCCESS_TOAST: `Terme changé avec succès.`,
  CHARGEBEE_PORTAL_CLOSE_TOAST: `Veuillez patienter jusqu'à 1 minute avant que nous recevions votre demande si vous avez effectué des modifications, puis rouvrez la page de facturation`,
  DELETE_CHANGE_ERROR_TOAST: `Impossible de supprimer la modification planifiée.`,
  DELETE_CHANGE_SUCCESS_TOAST: `Modification planifiée supprimée avec succès.`,
  GET_BILLING_ERROR_TOAST: `Erreur de connexion ChargeBee`,
  GET_ITEM_TIERS_ERROR_TOAST: `Impossible d'obtenir les prix des licences`,
  PAY_LATER_ERROR_TOAST: `La planification du paiement a échoué.`,
  REMOVE_SEATS_ERROR_TOAST: `Impossible de supprimer des sièges`,
  REMOVE_SEATS_SUCCESS_TOAST: `Changements programmés`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_1: `Réduire`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_2: `sièges`,
  CALCULATE_TAXES_ERROR_TOAST: `Erreur lors du calcul de l'impôt`,
  REACTIVATE_SUBSCRIPTION_SUCCESS_TOAST: `Abonnement réactivé avec succès`,
  REACTIVATE_SUBSCRIPTION_ERROR_TOAST: `Échec de l'activation de l'abonnement. Veuillez mettre à jour votre moyen de paiement.`,
  PAYMENT_ERROR_TOAST: `Un problème est survenu avec la carte lors du paiement, veuillez vérifier la carte.`,
  DELETE_PAYMENT_SOURCE_SUCCESS_TOAST: `La carte a été supprimée avec succès.`,
  DELETE_PAYMENT_SOURCE_ERROR_TOAST: `Erreur lors de la suppression de la carte.`,
  REPEAT_PAYMENT_SUCCESS_TOAST: `La facture a été payée avec succès.`,
  REPEAT_PAY_NO_PAYMENT_SOURCES_ERROR_TOAST: `Vous n'avez aucune carte disponible pour le paiement.`,
  REPEAT_PAY_INVOICE_NOT_COLLECTED_ERROR_TOAST: `Échec du paiement d'une ou plusieurs factures.`,
  REPEAT_PAYMENT_ERROR_TOAST: `Une erreur s'est produite lors de la tentative de paiement.`,
  UPDATE_PAYMENT_METHOD_SUCCESS_TOAST: `Le mode de paiement a été modifié avec succès.`,
  UPDATE_PAYMENT_METHOD_ERROR_TOAST: `Échec de la mise à jour du mode de paiement.`,
});
