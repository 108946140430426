import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduledChangesInput } from '../../../API';
import { BillingChangeModal, BillingSubscriptionButtons } from '../../../components/billing';
import { formatDateTimeThroughGap } from '../../../services/DateService';
import { authenticationSelectors } from '../../../store/authentication';
import { billingActions, billingSelectors } from '../../../store/billing';
import { userSettingsSelectors } from '../../../store/userSettings';
import { ConfirmationModal, SumoTable } from '../../common';
import labels from './labels';

export const BillingSubscription = () => {
  const dispatch = useDispatch();
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const licensesAssigned = useSelector(authenticationSelectors.selectAssigned);
  const isTrialLicense = useSelector(authenticationSelectors.selectIsTrialLicense);
  const isFetching = useSelector(billingSelectors.selectIsFetching);
  const quantity = useSelector(billingSelectors.selectSubscriptionQuantity);
  const nextBillingDate = useSelector(billingSelectors.selectNextBillingDate);
  const termLabel = useSelector(billingSelectors.selectTermLabel);
  const nextBillAmountLabel = useSelector(billingSelectors.selectNextBillAmountLabel);
  const scheduledChanges = useSelector(billingSelectors.selectSumoScheduledChanges);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat);
  const licenseEndDays = useSelector(authenticationSelectors.selectDaysUntilLicenseEnd);
  const totalLicenseCount = useSelector(authenticationSelectors.selectTotalLicenseCount);
  const isSubscriptionNonRenewing = useSelector(billingSelectors.selectIsStatusNonRenewing);
  const isSubscriptionCancelled = useSelector(billingSelectors.selectIsStatusCancelled);
  const isSubscriptionActive = useSelector(billingSelectors.selectIsSubscriptionActive);
  const cancelDate = useSelector(billingSelectors.selectCancelDate);
  const isPresentUnpaidInvoices = useSelector(billingSelectors.selectIsPresentUnpaidInvoices);
  const lastUnpaidInvoiceAmount = useSelector(billingSelectors.selectLastUnpaidInvoiceAmount);

  const [changeToDelete, setChangeToDelete] = useState<ScheduledChangesInput | null>(null);

  const handleDeleteScheduledChange = (data: ScheduledChangesInput) => {
    setChangeToDelete(data);
  };

  const handleConfirmDeleteScheduledChange = () => {
    dispatch(billingActions.deleteScheduledChangeRequest(changeToDelete?.id || ''));
    setChangeToDelete(null);
  };

  const handleCancelDeleteScheduledChange = () => {
    setChangeToDelete(null);
  };

  const getChangeLabel = (data: ScheduledChangesInput | null) =>
    data?.plan
      ? `${labels.termChangeLabel} ${data.plan}`
      : `${labels.scheduledChanges1Label} ${data?.seats} ${labels.scheduledChanges2Label}`;

  const getPlanLabel = () => {
    return isTrialLicense ? labels.planTrial : labels.planSilver;
  };

  const getTermLabel = () => {
    return isTrialLicense ? `${licenseEndDays} ${labels.days}` : termLabel;
  };

  const getUsersLabel = () => {
    return `${licensesAssigned} ${labels.usersOf} ${isTrialLicense ? totalLicenseCount : quantity}`;
  };

  const getNextBillAmountLabel = () => {
    if (!isSubscriptionActive && isPresentUnpaidInvoices) {
      return lastUnpaidInvoiceAmount;
    }
    if (isSubscriptionNonRenewing) {
      return `${labels.cancellationText} ${cancelDate}`;
    }
    return nextBillAmountLabel;
  };

  const getNextInvoiceDueLabel = () =>
    !isSubscriptionNonRenewing ? `${labels.nextInvoiceDue} ${nextBillingDate}` : '';

  return (
    <>
      <BillingChangeModal />

      <ConfirmationModal
        visible={Boolean(changeToDelete)}
        title={labels.cancel}
        description={`${labels.cancellationDescription} 
        
        ${getChangeLabel(changeToDelete)}`}
        confirmButtonLabel={labels.yesCancel}
        cancelButtonLabel={labels.no}
        confirmButtonProps={{ className: 'button-tomato' }}
        cancelButtonProps={{ className: 'min-w-90px' }}
        onConfirm={handleConfirmDeleteScheduledChange}
        onCancel={handleCancelDeleteScheduledChange}
        onClose={handleCancelDeleteScheduledChange}
      />

      <div className="sumo-card border-none px-20px py-24px">
        <div className="flex-left-center gap-6px">
          <div className="text-title-lg-med">{labels.subscription}</div>
          {isFetching ? (
            <Skeleton className="w-56px h-24px" />
          ) : isLicenseActive || isSubscriptionActive ? (
            <div className="text-label-s-med text-heavy-60 bg-heavy-10 border-radius-6px px-8px py-6px">
              {labels.active}
            </div>
          ) : (
            <div className="text-label-s-med text-tomato-main bg-tomato-soft border-radius-6px px-8px py-6px">
              {labels.suspended}
            </div>
          )}
        </div>

        {isFetching ? (
          <div className="flex gap-20px mt-12px">
            {Array(4)
              .fill(null)
              .map((_, i) => (
                <Skeleton key={i} className="w-120px h-42px" />
              ))}
          </div>
        ) : (
          <div className="flex gap-80px mt-12px">
            <div className="flex flex-column">
              <div className="text-title-s-med">{getPlanLabel()}</div>
              <div className="text-body-s-reg text-heavy-60">{labels.yourPlan}</div>
            </div>
            <div className="flex flex-column ml-16px">
              <div className="text-title-s-med">{getTermLabel()}</div>
              <div className="text-body-s-reg text-heavy-60">{labels.term}</div>
            </div>
            <div className="flex flex-column min-w-60px">
              <div className="text-title-s-med">{getUsersLabel()}</div>
              <div className="text-body-s-reg text-heavy-60">{labels.users}</div>
            </div>
            {!isTrialLicense && !isSubscriptionCancelled && (
              <>
                <div className="sumo-divider-vertical -my-8px" />
                <div className="flex flex-column -ml-40px">
                  <div className="text-title-s-med">{getNextBillAmountLabel()}</div>
                  {!isSubscriptionActive && isPresentUnpaidInvoices ? (
                    <div className="px-8px py-6px border-radius-6px text-label-s-med bg-saffron-light">
                      {labels.unpaidInvoiceMessage}
                    </div>
                  ) : (
                    <div className="text-body-s-reg text-heavy-60">{getNextInvoiceDueLabel()}</div>
                  )}
                </div>
              </>
            )}
          </div>
        )}

        <BillingSubscriptionButtons />

        {Boolean(scheduledChanges?.length) && (
          <>
            <div className="sumo-divider my-24px" />
            <div className="text-title-xs-med mb-24px">{`${labels.scheduledChanges} ${nextBillingDate}`}</div>
            <SumoTable size="small" className="datatable-no-lines -m-8px" value={scheduledChanges} loading={isFetching}>
              <Column
                header={labels.scheduledColumnLabel}
                bodyClassName="text-overflow-ellipsis"
                body={getChangeLabel}
              />
              <Column
                field="changeBy"
                header={labels.scheduledColumnChangeBy}
                bodyClassName="text-overflow-ellipsis max-w-60px"
              />
              <Column
                field="requestDate"
                header={labels.scheduledColumnDate}
                bodyClassName="text-overflow-ellipsis max-w-60px"
                body={(data: ScheduledChangesInput) =>
                  data?.requestDate && formatDateTimeThroughGap(data.requestDate, dateFormat || '', timeFormat || '')
                }
              />
              <Column
                body={(data: ScheduledChangesInput) => (
                  <Button
                    label={labels.cancel}
                    className="button-blue button-sm"
                    text
                    onClick={() => handleDeleteScheduledChange(data)}
                  />
                )}
                className="w-80px"
              />
            </SumoTable>
          </>
        )}
      </div>
    </>
  );
};
