import { CustomFieldInput, CustomType, TimeUnit } from '../API';
import { t } from '../i18n/i18n';
import { DATA_VALIDITY_PERIOD_MILLISECONDS, HeroIcons, languageNames } from '../types/constants';
import { HeroIconComponent, HeroIconKey } from '../types/types';

export const checkLink = (link: string) => {
  const validPattern = /^[a-z0-9-.&=_'-+,<>]*$/; // check if it only contains a-z, 0-9 and -.&=_'-+,<>
  return link.length > 2 && validPattern.test(link);
};

export const languageOptions = Object.keys(languageNames).map((key) => ({
  label: languageNames[key],
  value: key,
}));

export const changeTheme = (theme: string) => {
  const themeEl = document.getElementById('theme-link') as HTMLLinkElement;
  if (themeEl) {
    themeEl.href = `${process.env.PUBLIC_URL}/theme/theme-${theme}/blue/theme.css`;
  }
};

// handler to download CSV
export const downloadCSV = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export function isEqualArrays<T>(arr1: T[], arr2: T[]) {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}

export const appendPostfixToFileName = (fileName: string, postfix: string) => {
  const index = fileName.lastIndexOf('.');
  return fileName.substring(0, index) + postfix + fileName.substring(index);
};

export const validatePhoneNumber = (phoneNumber: string | null | undefined) => {
  const phoneLength = phoneNumber?.replaceAll('-', '').length || 0;
  return phoneLength >= 4 && phoneLength <= 16;
};

export const getTimeUnitLabel = (unit: TimeUnit | null | undefined) => {
  switch (unit) {
    case TimeUnit.DAY:
      return t('Common:DAYS');
    case TimeUnit.HOUR:
      return t('Common:HOURS');
    case TimeUnit.MINUTE:
    default:
      return t('Common:MINUTES');
  }
};

export const calculateMinutes = (count: number | null | undefined, unit: TimeUnit | null | undefined) => {
  switch (unit) {
    case TimeUnit.DAY:
      return 1440 * (count || 0);
    case TimeUnit.HOUR:
      return 60 * (count || 0);
    case TimeUnit.MINUTE:
    default:
      return count || 0;
  }
};

export const getInitials = (fullName: string) => {
  const words = fullName.split(' ');
  return words[1]
    ? words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
    : words[0]?.charAt(0).toUpperCase() || 'A';
};

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const hasPreviousDuplicates = (array: (string | null)[] | null | undefined, index: number) =>
  array?.slice(0, index).find((previousValue) => previousValue === array[index]);

export const isObjectDeepEqual = (obj1: object | null | undefined, obj2: object | null | undefined) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const HeroIconMap = Object.keys(HeroIcons).reduce((map, iconName) => {
  map[iconName] = HeroIcons[iconName as HeroIconKey] as HeroIconComponent;
  return map;
}, {} as Record<string, HeroIconComponent | null>);

export const getFileSizeString = (bytes: number): string => {
  if (bytes === 0) return '0 B';

  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const fileSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));
  return `${fileSize} ${sizes[i]}`;
};

export const isOldData = (dataLoadTime: number) =>
  new Date().getTime() - dataLoadTime > DATA_VALIDITY_PERIOD_MILLISECONDS;

export const separateStringWithSpaces = (str: string, afterHowManyChars = 3): string => {
  const regex = new RegExp(`(.{${afterHowManyChars}})`, 'g');
  return str.replace(regex, '$1 ').trim();
};

export const phoneNumberToReadableFormat = (phoneNumber: string | null | undefined): string | null => {
  if (!phoneNumber) {
    return null;
  }

  let phoneCode: string;
  let numberPart: string;

  if (/^\+\d{1,3}-\d+$/.test(phoneNumber)) {
    // Handle format +{code}-{number} (+375-448899)
    const parts = phoneNumber.split('-');
    phoneCode = parts[0];
    numberPart = parts[1];
  } else if (/^[A-Z]{2}_[0-9]{1,3}-\d+$/.test(phoneNumber)) {
    // Handle format {countryCode}_{phoneCode}-{number} (PL_48-339944)
    const parts = phoneNumber.split(/_|-/);
    phoneCode = '+' + parts[1];
    numberPart = parts[2];
  } else {
    return null;
  }

  if (numberPart === '' || !/^\d+$/.test(numberPart)) {
    return null;
  }

  const formattedNumber = phoneCode + ' ' + numberPart.replace(/(.{3})(?=.)/g, '$1 ');
  return formattedNumber.trim();
};

export const formatingCustomFieldValue = (customField: CustomFieldInput): string | null => {
  if (!customField) return null;

  switch (customField?.type) {
    case CustomType.PHONE: {
      return phoneNumberToReadableFormat(customField?.value);
    }
    default:
      return customField?.value || null;
  }
};

export const deepEqual = (a: any, b: any) => {
  if (a === b) {
    return true;
  }

  if (a == null || typeof a != 'object' || b == null || typeof b != 'object') {
    return false;
  }

  let propertiesInA = 0,
    propertiesInB = 0;
  for (const property in a) {
    propertiesInA += 1;
  }
  for (const property in b) {
    propertiesInB += 1;
    if (!(property in a) || !deepEqual(a[property], b[property])) {
      return false;
    }
  }
  return propertiesInA == propertiesInB;
};

export const formatToSentence = (text: string): string => {
  if (!text) return '';
  text = text.charAt(0).toUpperCase() + text.slice(1);
  if (!text.endsWith('.')) {
    text += '.';
  }
  return text;
};
