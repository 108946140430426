import { createSelector } from 'reselect';
import { t } from '../../i18n/i18n';
import { isOldData } from '../../services/utils';
import { authenticationSelectors } from '../authentication';
import { bookingPageSelectors } from '../bookingPages';
import { bookingTemplatesSelectors } from '../bookingTemplates';
import { globalSelectors } from '../global';
import { State } from '../rootStore';
import { usersSelectors } from '../users';
import { FILTER_USER_OPTION_DELETED_USERS } from '../users/constants';
import { userSettingsSelectors } from '../userSettings';
import { DEFAULT_FILTER, DELAY_UNITS } from './constants';
import { SmartAlertsByIdType, SmartAlertStatus, SmartAlertType, SmartTypes } from './types';
import { getHowLongText, getImmediatelyText } from './utils';

const smartAlertsState = (state: State) => state.smartAlerts;

const selectIsFetching = createSelector(smartAlertsState, (state) => state.isFetching);
const selectError = createSelector(smartAlertsState, (state) => state.error);
const selectSmartAlerts = createSelector(smartAlertsState, (state) => state.smartAlerts);
const selectSmartType = createSelector(smartAlertsState, (state) => state.smartType);
const selectSmartAlert = createSelector(smartAlertsState, (state) => state.smartAlert);
const selectSmartAlertTemplates = createSelector(smartAlertsState, (state) =>
  state.smartAlertTemplates.filter((template) => !!template.createdBy)
);
const selectSmartAlertTemplate = createSelector(smartAlertsState, (state) => state.smartAlertTemplate);
const selectFilter = createSelector(smartAlertsState, (state) => state.filter);
const selectSelectedAlerts = createSelector(smartAlertsState, (state) => state.selectedAlerts);
const selectCloneName = createSelector(smartAlertsState, (state) => state.cloneName);
const selectBookingPageIds = createSelector(smartAlertsState, (state) => state.bookingPageIds);
const selectBookingTemplateIds = createSelector(smartAlertsState, (state) => state.bookingTemplateIds);
const selectDetachAlert = createSelector(smartAlertsState, (state) => state.detachAlert);
const selectWithoutWarning = createSelector(smartAlertsState, (state) => state.withoutWarning);

const selectLastLoadTime = createSelector(smartAlertsState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectIsDefaultFilter = createSelector(selectFilter, (filter) => filter === DEFAULT_FILTER);
const selectFilterUsers = createSelector(selectFilter, (filter) => filter.userIds);
const selectFilterTypes = createSelector(selectFilter, (filter) => filter.types);
const selectFilterStatus = createSelector(selectFilter, (filter) => filter.status);

const selectIsFilterInUse = createSelector(
  selectFilter,
  authenticationSelectors.selectUserId,
  (filter, userId) =>
    filter.userIds.length !== 1 ||
    filter.userIds[0] !== userId ||
    filter.types.length !== 3 ||
    filter.status.length !== 1 ||
    filter.status[0] !== SmartAlertStatus.UNHIDDEN
);

const selectShowPersonal = createSelector(selectFilterTypes, (types) => types.includes(SmartAlertType.PERSONAL));
const selectShowShared = createSelector(selectFilterTypes, (types) => types.includes(SmartAlertType.SHARED));
const selectShowTemplate = createSelector(selectFilterTypes, (types) => types.includes(SmartAlertType.TEMPLATE));

const selectShowUnhidden = createSelector(selectFilterStatus, (status) => status.includes(SmartAlertStatus.UNHIDDEN));
const selectShowHidden = createSelector(selectFilterStatus, (status) => status.includes(SmartAlertStatus.HIDDEN));

const selectIsSelectedAlert = (id: string) =>
  createSelector(selectSelectedAlerts, (selectedAlerts) => selectedAlerts.some((item) => item.id === id));

const selectSmartAlertTemplatesForCurrentUser = createSelector(
  selectSmartAlertTemplates,
  authenticationSelectors.selectWorkspaceId,
  authenticationSelectors.selectUserId,
  (templates, workspaceId, userId) =>
    templates
      .filter((teamplate) => {
        if (teamplate.createdBy === userId) {
          // craeted by current user
          return true;
        }
        const shareWith = teamplate.shareWith?.find((share) => share?.workspaceId === workspaceId);
        if (shareWith) {
          const userIds = shareWith.userIds || [];
          // shared with all workspace or current user
          return !userIds.length || userIds.includes(userId);
        }
        return false;
      })
      .sort((a, b) => (a.createdAt <= b.createdAt ? 1 : -1))
);

const selectAlerts = createSelector(
  selectSmartAlerts,
  selectShowUnhidden,
  selectShowHidden,
  (alerts, showUnhidden, showHidden) =>
    alerts
      .filter((alert) => (showUnhidden && !alert.isHide) || (showHidden && alert.isHide))
      .sort((a, b) => (a.createdAt <= b.createdAt ? 1 : -1))
);
const selectPersonalSmartAlertsForCurrentUser = createSelector(
  selectSmartAlerts,
  authenticationSelectors.selectUserId,
  (smartAlerts, userId) =>
    smartAlerts
      .filter((smartAlert) => smartAlert.createdBy === userId)
      .sort((a, b) => (a.createdAt <= b.createdAt ? 1 : -1))
);

const selectPersonalSmartAlertsWithFilter = createSelector(
  selectAlerts,
  selectFilterUsers,
  selectShowPersonal,
  usersSelectors.selectUserIds,
  (smartAlerts, users, showPersonal, allUserIds) => {
    if (!showPersonal) {
      return [];
    }

    const smartAlertsResponse = smartAlerts.filter((smartAlert) => users.includes(smartAlert.createdBy || ''));

    if (users.includes(FILTER_USER_OPTION_DELETED_USERS)) {
      smartAlerts.forEach((smartAlert) => {
        if (!allUserIds.includes(smartAlert.createdBy)) {
          smartAlertsResponse.push(smartAlert);
        }
      });
    }
    return smartAlertsResponse;
  }
);

const selectHasMyPersonalSmartAlert = createSelector(
  selectAlerts,
  authenticationSelectors.selectUserId,
  selectShowPersonal,
  (smartAlerts, userId, showPersonal) =>
    showPersonal && smartAlerts.some((smartAlert) => smartAlert.createdBy === userId)
);

const selectPersonalSearched = createSelector(
  selectPersonalSmartAlertsWithFilter,
  globalSelectors.selectLowercasedSearchString,
  (personalAlerts, searchString) => personalAlerts.filter((alert) => alert.name?.toLowerCase().includes(searchString))
);

const selectSharedSmartAlertsForCurrentUser = createSelector(
  selectSmartAlerts,
  selectPersonalSmartAlertsForCurrentUser,
  authenticationSelectors.selectWorkspaceId,
  authenticationSelectors.selectUserId,
  (smartAlerts, personalAlerts, workspaceId, userId) =>
    smartAlerts
      .filter((smartAlert) => {
        if (personalAlerts.some((alert) => alert.id === smartAlert.id)) {
          // don't show one alert twice
          return false;
        }
        const shareWith = smartAlert.shareWith?.find((share) => share?.workspaceId === workspaceId);
        if (shareWith) {
          const userIds = shareWith.userIds || [];
          // shared with all workspace or current user
          return !userIds.length || userIds.includes(userId);
        } else return false;
      })
      .sort((a, b) => (a.createdAt <= b.createdAt ? 1 : -1))
);

const selectSharedSmartAlertsWithFilter = createSelector(
  selectSmartAlerts,
  selectFilterUsers,
  selectShowShared,
  selectPersonalSmartAlertsWithFilter,
  authenticationSelectors.selectWorkspaceId,
  (smartAlerts, users, showShared, personalAlerts, workspaceId) =>
    showShared
      ? smartAlerts.filter((smartAlert) => {
          if (personalAlerts.some((alert) => alert.id === smartAlert.id)) {
            // don't show one alert twice
            return false;
          }
          const shareWith = smartAlert.shareWith?.find((share) => share?.workspaceId === workspaceId);
          if (shareWith) {
            const userIds = shareWith.userIds || [];
            // shared with all workspace or selected user
            return !userIds.length || userIds.some((id) => id && users.includes(id));
          } else return false;
        })
      : []
);

const selectSharedSearched = createSelector(
  selectSharedSmartAlertsWithFilter,
  globalSelectors.selectLowercasedSearchString,
  (sharedAlerts, searchString) => sharedAlerts.filter((alert) => alert.name?.toLowerCase().includes(searchString))
);

const selectSmartTemplatesWithFilter = createSelector(
  selectSmartAlertTemplates,
  selectFilterUsers,
  selectShowTemplate,
  usersSelectors.selectUserIds,
  authenticationSelectors.selectWorkspaceId,
  (templates, users, showTemplates, allUserIds, workspaceId) => {
    if (!showTemplates) {
      return [];
    }

    const smartTemplatesResponse = templates.filter((template) => {
      if (users.includes(template.createdBy || '')) return true;
      const shareWith = template.shareWith?.find((share) => share?.workspaceId === workspaceId);
      if (shareWith) {
        const userIds = shareWith.userIds || [];
        // shared with all workspace or selected user
        return !userIds.length || userIds.some((id) => id && users.includes(id));
      } else return false;
    });

    if (users.includes(FILTER_USER_OPTION_DELETED_USERS)) {
      templates.forEach((template) => {
        if (!allUserIds.includes(template.createdBy)) {
          smartTemplatesResponse.push(template);
        }
      });
    }
    return smartTemplatesResponse;
  }
);

const selectSearchedAlertTemplates = createSelector(
  selectSmartTemplatesWithFilter,
  globalSelectors.selectLowercasedSearchString,
  (templates, searchString) =>
    templates
      .filter((template) => template.name?.toLowerCase().includes(searchString))
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
);

const selectSmartAlertsToUse = createSelector(
  selectPersonalSmartAlertsForCurrentUser,
  selectSharedSmartAlertsForCurrentUser,
  (personal, shared) => [...personal, ...shared]
);

const selectIsNoAlertsToShow = createSelector(
  selectPersonalSearched,
  selectSharedSearched,
  selectSearchedAlertTemplates,
  (personal, shared, templates) => !personal.length && !shared.length && !templates.length
);

const selectItemsToShowLength = createSelector(
  selectSmartAlerts,
  selectSmartAlertTemplates,
  (alerts, templates) => alerts.length + templates.length
);
const selectIsCloneNameValid = createSelector(selectCloneName, (name) => Boolean(name));

const selectSmartAlertName = createSelector(selectSmartAlert, (alert) => alert.name);
const selectSmartAlertId = createSelector(selectSmartAlert, (alert) => alert.id);
const selectSmartAlertWhenType = createSelector(selectSmartAlert, (alert) => alert.whenSend?.eventSendType);
const selectSmartAlertDelay = createSelector(selectSmartAlert, (alert) => alert.whenSend?.delay || 0);
const selectSmartAlertDelayUnit = createSelector(selectSmartAlertDelay, (delay) =>
  delay % 1440 === 0 ? DELAY_UNITS.days : delay % 60 === 0 ? DELAY_UNITS.hours : DELAY_UNITS.minutes
);
const selectSmartAlertDelayNum = createSelector(selectSmartAlertDelay, (delay) =>
  delay % 1440 === 0 ? delay / 1440 : delay % 60 === 0 ? delay / 60 : delay
);
const selectSmartAlertIsNew = createSelector(selectSmartAlertId, (id) => !id);
const selectSmartAlertShareWith = createSelector(selectSmartAlert, (alert) => alert.shareWith);
const selectSmartAlertShareWithWorkspaces = createSelector(
  selectSmartAlertShareWith,
  (shareWith) =>
    shareWith?.map((share) => share?.workspaceId).filter((workspace): workspace is string => !!workspace) || []
);
const selectSmartAlertShareWithUsersByWorkspace = createSelector(selectSmartAlertShareWith, (shareWith) =>
  shareWith?.reduce((users: string[], share) => {
    share?.userIds?.forEach((user) => users.push(`${share.workspaceId}_${user}`));
    return users;
  }, [])
);
const selectIsAlertNameValid = createSelector(selectSmartAlertName, (name) => Boolean(name));

const selectSmartAlertTemplateName = createSelector(selectSmartAlertTemplate, (template) => template.name);
const selectSmartAlertTemplateDesc = createSelector(selectSmartAlertTemplate, (template) => template.description);

const selectHowLongText = createSelector(selectSmartAlertWhenType, (whenType) =>
  whenType ? getHowLongText(whenType) : ''
);
const selectImmediatelyText = createSelector(selectSmartAlertWhenType, (whenType) =>
  whenType ? getImmediatelyText(whenType) : ''
);

const selectSmartAlertById = (id: string) =>
  createSelector(selectSmartAlerts, (alerts) => alerts.find((alert) => alert.id === id));

const selectSmartAlertTemplateById = (id: string) =>
  createSelector(selectSmartAlertTemplates, (templates) => templates.find((template) => template.id === id));

const selectSmartAlertsById = createSelector(selectSmartAlerts, (alerts) =>
  alerts.reduce((res, alert) => {
    res[alert.id] = alert;
    return res;
  }, {} as SmartAlertsByIdType)
);

const selectIsAlertReadOnly = (id: string, type: SmartTypes) =>
  createSelector(
    selectSmartAlertById(id),
    selectSmartAlertTemplateById(id),
    authenticationSelectors.selectUserId,
    userSettingsSelectors.selectIsWorkspaceOrSuperAdmin,
    userSettingsSelectors.selectSmartAlertsCreate,
    userSettingsSelectors.selectSmartAlertsEdit,
    userSettingsSelectors.selectSmartAlertTemplatesEdit,
    (alert, template, userId, isAdmin, create, alertEdit, templateEdit) =>
      id
        ? type === SmartTypes.ALERT
          ? (alert?.createdBy == userId && !alertEdit) || (alert?.createdBy !== userId && !isAdmin)
          : (template?.createdBy == userId && !templateEdit) || (template?.createdBy !== userId && !isAdmin)
        : !create
  );

const selectIsAlertDeleteLocked = (id: string, type: SmartTypes) =>
  createSelector(
    selectSmartAlertById(id),
    selectSmartAlertTemplateById(id),
    authenticationSelectors.selectUserId,
    userSettingsSelectors.selectIsWorkspaceOrSuperAdmin,
    userSettingsSelectors.selectSmartAlertsDelete,
    userSettingsSelectors.selectSmartAlertTemplatesDelete,
    (alert, template, userId, isAdmin, alertDelete, templateDelete) =>
      type === SmartTypes.ALERT
        ? (alert?.createdBy == userId && !alertDelete) || (alert?.createdBy !== userId && !isAdmin)
        : (template?.createdBy == userId && !templateDelete) || (template?.createdBy !== userId && !isAdmin)
  );

const selectIsBulkDeleteAvail = createSelector(
  selectSelectedAlerts,
  (state: State) => state,
  (selectedAlerts, state) => selectedAlerts.every((alert) => !selectIsAlertDeleteLocked(alert.id, alert.type)(state))
);

const selectBookingPagesWhereUsed = (smartAlertId: string, excludeCurrent = false) =>
  createSelector(
    bookingPageSelectors.selectBookingPages,
    bookingPageSelectors.selectBookingPage,
    (bookingPages, bookingPage) =>
      bookingPages.filter(
        (page) => (!excludeCurrent || bookingPage.id !== page.id) && page.smartAlertIds?.includes(smartAlertId)
      )
  );

const selectBookingTemplatesWhereUsed = (smartAlertId: string, excludeCurrent = false) =>
  createSelector(
    bookingTemplatesSelectors.selectBookingTemplates,
    bookingTemplatesSelectors.selectBookingTemplate,
    (bookingTemplates, bookingTemplate) =>
      bookingTemplates.filter(
        (template) =>
          (!excludeCurrent || bookingTemplate.id !== template.id) && template.smartAlertIds?.includes(smartAlertId)
      )
  );

const selectBookingPagesIdsWhereUsed = (id: string) =>
  createSelector(selectBookingPagesWhereUsed(id), (bookingPages) => bookingPages.map((page) => page.id));

const selectBookingTemplatesIdsWhereUsed = (id: string) =>
  createSelector(selectBookingTemplatesWhereUsed(id), (bookingTemplates) =>
    bookingTemplates.map((template) => template.id)
  );

const selectEditWarningDescription = (smartAlertId: string) =>
  createSelector(
    selectBookingPagesWhereUsed(smartAlertId),
    selectBookingTemplatesWhereUsed(smartAlertId),
    (bookingPages, bookingTemplates) => {
      let res = '';
      if (bookingPages.length && bookingTemplates.length) {
        res = t('SmartAlertsWarningModal:WARNING_BP_AND_BT');
      } else if (bookingPages.length) {
        res = t('SmartAlertsWarningModal:WARNING_BP');
      } else if (bookingTemplates.length) {
        res = t('SmartAlertsWarningModal:WARNING_BT');
      }
      return res;
    }
  );

const selectShowWarningModal = (id: string, onBookingPage: boolean) =>
  createSelector(
    selectWithoutWarning,
    selectBookingPagesWhereUsed(id, onBookingPage),
    selectBookingTemplatesWhereUsed(id, !onBookingPage),
    (withoutWarning, bookingPages, bookingTemplates) =>
      !withoutWarning.includes(id) && (!!bookingPages.length || !!bookingTemplates.length)
  );

const selectIsThereSmartAlertSMSInvitee = (ids: Array<string | null>) =>
  createSelector(selectSmartAlertsById, (alertsById) =>
    ids.some((id) => id && alertsById[id] && alertsById[id].whomSend?.smsToInvitee?.isActive)
  );

export const smartAlertsSelectors = {
  selectIsFetching,
  selectError,
  selectSmartAlerts,
  selectSmartType,
  selectSmartAlert,
  selectSmartAlertTemplates,
  selectSmartAlertTemplate,
  selectSelectedAlerts,
  selectCloneName,
  selectBookingPageIds,
  selectBookingTemplateIds,
  selectDetachAlert,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,

  selectIsDefaultFilter,
  selectFilterUsers,
  selectFilterTypes,
  selectFilterStatus,
  selectIsFilterInUse,
  selectSmartAlertTemplatesForCurrentUser,
  selectPersonalSmartAlertsWithFilter,
  selectHasMyPersonalSmartAlert,
  selectPersonalSearched,
  selectSharedSmartAlertsWithFilter,
  selectSharedSearched,
  selectSmartAlertsToUse,
  selectSearchedAlertTemplates,
  selectIsNoAlertsToShow,
  selectItemsToShowLength,
  selectIsCloneNameValid,
  selectIsSelectedAlert,

  selectSmartAlertName,
  selectSmartAlertId,
  selectSmartAlertWhenType,
  selectSmartAlertDelay,
  selectSmartAlertDelayUnit,
  selectSmartAlertDelayNum,
  selectSmartAlertIsNew,
  selectSmartAlertShareWith,
  selectSmartAlertShareWithWorkspaces,
  selectSmartAlertShareWithUsersByWorkspace,
  selectIsAlertNameValid,

  selectSmartAlertTemplateName,
  selectSmartAlertTemplateDesc,

  selectHowLongText,
  selectImmediatelyText,
  selectSmartAlertById,
  selectSmartAlertsById,
  selectIsAlertReadOnly,
  selectIsAlertDeleteLocked,
  selectIsBulkDeleteAvail,

  selectBookingPagesWhereUsed,
  selectBookingTemplatesWhereUsed,
  selectBookingPagesIdsWhereUsed,
  selectBookingTemplatesIdsWhereUsed,
  selectEditWarningDescription,
  selectShowWarningModal,
  selectIsThereSmartAlertSMSInvitee,
};
