import { TrashIcon, UsersIcon, ViewColumnsIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SmartAlertTemplate } from '../../API';
import { ReactComponent as NoItemsImage } from '../../assets/images/noConnectionLarge.svg';
import {
  BulkActionRow,
  CardSkeleton,
  ConfirmationModal,
  EmptyListScreen,
  SectionHeader,
} from '../../components/common';
import { SmartAlertCard, SmartAlertCloneModal } from '../../components/smartAlerts';
import { Path } from '../../routing';
import { navigationService } from '../../services/NavigationService';
import { authenticationSelectors } from '../../store/authentication';
import {
  DEFAULT_FILTER,
  DEFAULT_SMART_ALERT_TEMPLATE,
  DeleteSmartTypeModalRequest,
  deleteSmartTypesModalActions,
  deleteSmartTypesModalSelectors,
  smartAlertsActions,
  smartAlertsSelectors,
  SmartTypes,
  SMART_ALERT_STATUS_OPTIONS,
  SMART_ALERT_TYPE_OPTIONS,
} from '../../store/smartAlerts';
import { getDeleteModalDescription, getDeleteModalWarning } from '../../store/smartAlerts/utils';
import { usersSelectors } from '../../store/users';
import { userSettingsSelectors } from '../../store/userSettings';
import { SKELETON_LENGTH } from '../../types/constants';
import { TEMPLATES } from './constants';
import labels from './labels';

export const SmartAlerts = () => {
  const dispatch = useDispatch();
  const userId = useSelector(authenticationSelectors.selectUserId);
  const isSpinnerFetching = useSelector(smartAlertsSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(smartAlertsSelectors.selectIsSkeletonFetching);
  const itemsToShowLength = useSelector(smartAlertsSelectors.selectItemsToShowLength);
  const personalSmartAlerts = useSelector(smartAlertsSelectors.selectPersonalSearched);
  const sharedSmartAlerts = useSelector(smartAlertsSelectors.selectSharedSearched);
  const customTemplates = useSelector(smartAlertsSelectors.selectSearchedAlertTemplates);
  const templates = customTemplates.length ? customTemplates : TEMPLATES;
  const usersOptions = useSelector(usersSelectors.selectLoggedUsersInCurrentWorkspaceAndDeletedUsersOptions);
  const users = useSelector(smartAlertsSelectors.selectFilterUsers);
  const types = useSelector(smartAlertsSelectors.selectFilterTypes);
  const status = useSelector(smartAlertsSelectors.selectFilterStatus);
  const isFilterActive = useSelector(smartAlertsSelectors.selectIsFilterInUse);
  const isDefaultFilter = useSelector(smartAlertsSelectors.selectIsDefaultFilter);
  const selectedAlerts = useSelector(smartAlertsSelectors.selectSelectedAlerts);

  const isCreate = useSelector(userSettingsSelectors.selectSmartAlertsCreate);
  const isReadTemplates = useSelector(userSettingsSelectors.selectSmartAlertTemplatesRead);
  const isBulkDelete = useSelector(smartAlertsSelectors.selectIsBulkDeleteAvail);
  const isDeleteModalOpen = useSelector(deleteSmartTypesModalSelectors.selectIsModalOpen);
  const modalRequest = useSelector(deleteSmartTypesModalSelectors.selectModalRequest) as DeleteSmartTypeModalRequest;
  const noCustomItems = !personalSmartAlerts.length && !sharedSmartAlerts.length && !customTemplates.length;

  useEffect(() => {
    dispatch(smartAlertsActions.getSmartTypesRequest());
    if (isDefaultFilter) {
      dispatch(smartAlertsActions.setFilter({ ...DEFAULT_FILTER, userIds: [userId] }));
    }
  }, []);

  const handleCreateAlerts = () => {
    if (!noCustomItems) {
      navigationService.navigateTo(
        Path.EditSmartAlert.replace(':smartType', SmartTypes.ALERT).replace(':smartAlertId', 'pre-select')
      );
    } else {
      handleUpdateSmartAlert(DEFAULT_SMART_ALERT_TEMPLATE, 'new', SmartTypes.ALERT);
    }
  };

  const handleBulkClose = () => {
    dispatch(smartAlertsActions.selectAlerts([]));
  };

  const handleBulkDelete = () => {
    dispatch(deleteSmartTypesModalActions.openModal({ selectedAlerts } as DeleteSmartTypeModalRequest));
  };

  const bulkContent = (
    <>
      {isBulkDelete && (
        <Button text className="gap-8px text-button-md-med" onClick={handleBulkDelete}>
          <TrashIcon className="icon-18px" />
          {labels.delete}
        </Button>
      )}
    </>
  );

  const resetFilters = () => {
    dispatch(smartAlertsActions.setFilter({ ...DEFAULT_FILTER, userIds: [userId] }));
  };

  const handleSelectUsers = (e: MultiSelectChangeEvent) => {
    dispatch(smartAlertsActions.setFilter({ userIds: e.target.value }));
  };

  const handleSelectTypes = (e: MultiSelectChangeEvent) => {
    dispatch(smartAlertsActions.setFilter({ types: e.target.value }));
  };

  const handleSelectStatus = (e: MultiSelectChangeEvent) => {
    dispatch(smartAlertsActions.setFilter({ status: e.target.value }));
  };

  const handleClose = () => {
    dispatch(deleteSmartTypesModalActions.closeModal());
  };

  const handleConfirmDelete = () => {
    dispatch(smartAlertsActions.deleteSmartTypesRequest(modalRequest.selectedAlerts));
    handleClose();
  };

  const handleUpdateSmartAlert = (alert: SmartAlertTemplate, smartAlertId: string, smartType: SmartTypes) => {
    dispatch(
      smartAlertsActions.updateSmartAlert({
        name: alert.name,
        icon: alert.icon,
        isHide: alert.isHide,
        shareWith: alert.shareWith,
        whenSend: alert.whenSend,
        whomSend: alert.whomSend,
      })
    );
    const path = Path.EditSmartAlert.replace(':smartType', smartType).replace(':smartAlertId', smartAlertId);
    navigationService.navigateTo(path);
  };

  return (
    <>
      <BulkActionRow selectedCount={selectedAlerts.length} handleClose={handleBulkClose} actionsContent={bulkContent} />
      <SmartAlertCloneModal />
      <ConfirmationModal
        visible={isDeleteModalOpen}
        title={labels.delete}
        description={getDeleteModalDescription(modalRequest?.selectedAlerts || [], modalRequest?.name)}
        additionalText={getDeleteModalWarning(modalRequest?.selectedAlerts || [])}
        confirmButtonLabel={labels.yes}
        cancelButtonLabel={labels.no}
        onConfirm={handleConfirmDelete}
        onCancel={handleClose}
        onClose={handleClose}
      />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          title={labels.title}
          loading={isSpinnerFetching}
          itemsLength={itemsToShowLength}
          searchPaths={[Path.SmartAlerts, Path.EditSmartAlert]}
          buttonLabel={
            !noCustomItems ? labels.createAlert : labels.createCustomAlert
          }
          buttonOutlined={noCustomItems}
          onButtonClick={handleCreateAlerts}
          filters={[
            { label: labels.user, value: users, options: usersOptions, onChange: handleSelectUsers },
            { label: labels.type, value: types, options: SMART_ALERT_TYPE_OPTIONS, onChange: handleSelectTypes },
            { label: labels.status, value: status, options: SMART_ALERT_STATUS_OPTIONS, onChange: handleSelectStatus },
          ]}
          onFilterReset={resetFilters}
          isFilterActive={isFilterActive}
          hideButton={!isCreate}
        />

        <div className="flex flex-column gap-40px">
          {isSkeletonFetching ? (
            <div className="flex flex-wrap -m-8px">
              {[...Array(SKELETON_LENGTH)].map((value, index) => (
                <div className="w-12 lg:w-6 p-8px" key={index}>
                  <CardSkeleton hideDetails hideOptions />
                </div>
              ))}
            </div>
          ) : personalSmartAlerts.length || sharedSmartAlerts.length || (templates.length && isReadTemplates) ? (
            <>
              {personalSmartAlerts.length ? (
                <div className="flex flex-wrap -m-8px">
                  {personalSmartAlerts.map((alert) => (
                    <div className="w-12 lg:w-6 p-8px" key={alert.id}>
                      <SmartAlertCard alert={alert} />
                    </div>
                  ))}
                </div>
              ) : null}
              {noCustomItems ? (
                <div className="flex-center flex-row">
                  <NoItemsImage className="w-189px min-w-189px" />
                  <div className="flex flex-column gap-4px">
                    <div className="text-title-md-med text-heavy-80">{labels.emptyTitle}</div>
                    <div className="text-body-s-reg text-heavy-60">{labels.emptyDescription}</div>
                  </div>
                </div>
              ) : null}
              {!!sharedSmartAlerts.length && (
                <div className="flex flex-wrap -m-8px">
                  <div className="w-12 p-8px flex-left-center gap-6px text-label-s-med text-heavy-60">
                    <UsersIcon className="icon-20px" />
                    <div>{labels.sharedLabel}</div>
                  </div>
                  {sharedSmartAlerts.map((alert) => (
                    <div className="w-12 lg:w-6 p-8px" key={alert.id}>
                      <SmartAlertCard alert={alert} />
                    </div>
                  ))}
                </div>
              )}
              {customTemplates.length || noCustomItems ? (
                <div className="flex flex-wrap -m-8px">
                  <div className="w-12 p-8px flex-left-center gap-6px text-label-s-med text-heavy-60">
                    <ViewColumnsIcon className="icon-20px" />
                    <div>{labels.templatesLabel}</div>
                  </div>
                  {isReadTemplates
                    ? customTemplates.map((template) => (
                        <div className="w-12 lg:w-6 p-8px" key={template.id}>
                          <SmartAlertCard
                            template={template}
                            {...(!template.createdBy
                              ? { useTemplate: () => handleUpdateSmartAlert(template, template.id, SmartTypes.ALERT) }
                              : {})}
                          />
                        </div>
                      ))
                    : null}
                  {noCustomItems
                    ? TEMPLATES.map((template) => (
                        <div className="w-12 lg:w-6 p-8px" key={template.id}>
                          <SmartAlertCard
                            template={template}
                            {...(!template.createdBy
                              ? {
                                  useTemplate: () =>
                                    handleUpdateSmartAlert(
                                      template,
                                      template.id,
                                      !template.createdBy ? SmartTypes.ALERT : SmartTypes.TEMPLATE
                                    ),
                                }
                              : {})}
                          />
                        </div>
                      ))
                    : null}
                </div>
              ) : null}
            </>
          ) : (
            <div className="w-12">
              <EmptyListScreen description={labels.emptyListDesc} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
