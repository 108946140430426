import { StaffRoles } from '../API';
import { t } from '../i18n/i18n';
import { timeZoneService } from '../services/TimeZoneService';
import urlConstants from '../shared/JSON/urlConstants.json';
export * as HeroIcons from '@heroicons/react/24/outline';

export const API_PUBLIC = '/api/public';
// export const API_JS = '/api/js'; does not used anymore

export const languageNames: { [key: string]: string } = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  pt: 'Português',
  es: 'Español',
  // Add more languages here
};

export const CURRENT_TIME_ZONE = timeZoneService.getCurrentTimeZone();
export const TIME_ZONES_BY_NAME = timeZoneService.getTimeZonesDataByName();
export const TIME_ZONE_OPTIONS = timeZoneService.getCommonTimeZonesByRegion();
export const TIME_ZONE_LIST = timeZoneService.getTimeZonesDataList();
export const isUserFromCanadaOrUS = timeZoneService.isUserFromUSOrCanada();

export const GA_TRACKING_ID = 'G-088S2N96V9'; // Google Analytics TRACKING_ID
export const GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID || '';

export const MAX_LENGTH_COLOR_PICKER = 7;
export const MAX_LENGTH_ZIP_CODE = 9;
export const MAX_LENGTH_ADDRESS_LINE = 70;
export const MAX_LENGTH_ADDRESS_ITEM = 30;

export const MAX_LENGTH_NAME = 40;
export const MAX_LENGTH_DESCRIPTION = 500;
export const MAX_LENGTH_EMAIL = 320;
export const MAX_LENGTH_PHONE = 16;
export const MAX_LENGTH_QUESTION = 200;
export const MAX_LENGTH_SHORT_ANSWER = 100;
export const MAX_LENGTH_LONG_ANSWER = 200;
export const MAX_LENGTH_ABOUT_150_WORDS = 1000;
export const MAX_LENGTH_ABOUT_300_WORDS = 2000;

export const MIN_ITEMS_FOR_SEARCH = 6;

export const FILE_IMAGE_TYPES = 'image/jpeg, image/gif, image/png, image/svg+xml';
export const MAX_FILE_SIZE_BYTES = 1024 * 1024 * 5;

export const TOAST_SUCCESS_COLOR = 'success';
export const TOAST_ERROR_COLOR = 'error';
export const TOAST_SUCCESS_DURATION = 3000;
export const TOAST_SUCCESS_LONG_DURATION = 6000;
export const TOAST_ERROR_DURATION = 6000;
export const TOAST_10_SEC_DURATION = 10000;

export const AVERAGE_VARIABLE_LENGTH = 8;
export const MAX_LENGTH_EMAIL_SUBJECT = 70;
export const MAX_LENGTH_EMAIL_BODY = 4000;
export const MAX_LENGTH_SMS_BODY = 150;

export const DATA_VALIDITY_PERIOD_MILLISECONDS = 15000;
export const GUIDE_DELAY_MILLISECONDS = 200;
export const DELAY_MILLISECONDS = 4000;

export const DURATION_CHANGE_THE_LOADING_TEXT = 3000;

export const SKELETON_LENGTH = 2;

export const LIMIT_ENTITIES_FOR_TRIAL = Number(process.env.REACT_APP_LIMIT_ENTITIES_FOR_TRIAL);

export const excludeUrls = [urlConstants.SUMO1_APP_OLD_PROD_URL, urlConstants.SUMO1_APP_PROD_URL];

export enum SERVER_ERROR_CODES {
  Forbidden = '403',
  NotFound = '404',
  LicenseExpired = '423',
  UnprocessableEntity = '422',
  Deactivated = '446',
  RoleDeactivated = '460',
  UserDeactivate = '461',
  TrialLimit = '462',
  DeleteRole = '463',
  SwitchAccount = '464', // email already in use
  BookingPageExpired = '465',
  ServerError = '500',
  NoUsersAvailable = '466',
  NoUserWhenLogin = '467',
  ImportLocationLimit = '468',
}

export const CLONE_NAME_POSTFIX = t('Common:CLONE_NAME_POSTFIX');
export const FILE_COPY_POSTFIX = '_copy';

export const US_CENTER_COORDINATES = { lat: 39.8283, lng: -98.5795 };

export const STAFF_ROLE_OPTIONS = [
  { value: StaffRoles.MAIN_ADMIN, label: t('OPSConsoleStaff:ADMIN_TITLE'), disabled: false },
  { value: StaffRoles.SUPPORT, label: t('OPSConsoleStaff:SUPPORT_TITLE'), disabled: false },
  { value: StaffRoles.OPERATIONS, label: t('OPSConsoleStaff:OPERATIONS_TITLE'), disabled: false },
  { value: StaffRoles.SALES, label: t('OPSConsoleStaff:SALES_TITLE'), disabled: false },
];

export const adminRoles = {
  sumoAdmin: 'Sumo Admin',
  superAdmin: 'Super Admin',
  workspaceAdmin: 'Workspace Admin',
  standardUser: 'Standard User',
};
